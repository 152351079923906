import { memo } from "react";
import "./skusManager.scss";
import Skus from "../../Components/Skus/Skus";

const SkusManager = () => {
	return (
		<div className="skus-wrapper-container">
			<Skus />
		</div>
	);
};

export default memo(SkusManager);
