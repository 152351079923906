import React, { useEffect, useState } from "react";
import "./loginScreen.scss";
import logo from "../../assets/images/logo/fx-logo.png";
import login_foreground_imge from "../../assets/images/login-image.jpeg";
import { useLocation, useNavigate } from "react-router-dom";
import Login from "../../Components/Access/Login";
import { Space, Typography } from "antd";
import RegisterForm from "../../Components/Access/RegisterForm/RegisterForm";
import PendingPage from "../PendingPage";
import { useSelector } from "react-redux";
import useSpinner from "../../hooks/useSpinner";
const { Title, Text } = Typography;

const LoginScreen = () => {
  const [activePage, setActivePage] = useState("/login");
  const [showSideImage, setShowSideImage] = useState(true);
  const location = useLocation();
  let activeLocation = location.pathname;
  const current_user = JSON.parse(localStorage?.getItem("current_user"));
  const navigate = useNavigate();
  const {
    userReducer: { token },
  } = useSelector((state) => state);
  const { disptachClearAll } = useSpinner();

  useEffect(() => {
    const showImage = location.pathname !== "/verified";
    setActivePage(location.pathname);
    setShowSideImage(showImage);
    if (!token) {
      disptachClearAll();
    }
  }, [location]);

  //component renderer for different forms and components
  const componentRenderer = () => {
    switch (activePage) {
      case "/login":
        return <Login />;
      case "/register":
        if (current_user) {
          return <RegisterForm />;
        } else {
          navigate("/");
          return;
        }
      case "/pending":
        return <PendingPage />;
      default:
        return <Login />;
    }
  };

  return (
    <div className="login-layout">
      <div
        className={`main-panels-div ${
          activeLocation.includes("register") ? "w-50 h-80" : ""
        }`}
      >
        {!["/pending", "/register"].includes(activeLocation) && (
          <div className="w-50 login_left_background_container">
            <div className="login_left_foreground_div">
              <img
                src={login_foreground_imge}
                className="login_foreground_image"
                alt="login_img"
              />
            </div>
          </div>
        )}
        <div
          className={`${
            activeLocation.includes("register") ? "w-80" : "w-50"
          } login_right_background_container`}
        >
          <div className="login_right_foreground_div">
            <Space direction="vertical" className="w-100 no-gap mb-2">
              <img src={logo} className="logo_img" alt="logo_img" />
              {!["/pending"].includes(activeLocation) && (
                <>
                  <Title level={1} className="title-mb">
                    FLIX-it
                  </Title>
                  <Text>
                    End to end digital image transformation for fashion retail
                  </Text>
                </>
              )}
            </Space>
            {componentRenderer()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
