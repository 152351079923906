import { memo } from "react";
import "./Profile.scss";
import Header from "../../commonComponents/BatchesHeader/header";
import SelfDescription from "./SelfDescription";

const Profile = () => {
	return (
		<div className="profile-wrapper">
			<Header title="Profile" />
			<SelfDescription />
		</div>
	);
};

export default memo(Profile);
