import { Breadcrumb, Space, Typography, message } from "antd";
import "./header.scss";
import { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BDCrumb from "../BatchesBreadcrumb/breadcrumb";
import FiltersRow from "../../Components/Batches/FiltersRow/FiltersRow";
import FilesFilterRow from "../../Components/Batch/FilesFilterRow/FilesFilterRow";
import { useDispatch, useSelector } from "react-redux";
import MultipleSearch from "../../Components/Skus/MultipleSearch";
import UsersFiltersRow from "../../Components/Users/UsersFiltersRow";
import ProfileFiltersRow from "../../Components/Self/ProfileFiltersRow";
import SelectSize from "../../Components/Skus/SelectSize";
import SkuFilterRow from "../../Components/Skus/SkuFilterRow";
import DefaultButton from "../buttons/button";
import {
  clearDownloadDetails,
  updateDownloadDetails,
} from "../../ReduxComponents/Reducers/DownloadReducer";
import { useDownloadImages } from "../../Api";
import { CloudDownloadOutlined } from "@ant-design/icons";

const Header = ({ title }) => {
  const location = useLocation();
  const {
    batchReducer: { batchName, batchList },
    skusReducer: { skusList },
    downloadReducer: { isDownloadOpen, toBeDownloaded },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const downloadAvailableList = [
    { name: "Batch", data: batchList },
    { name: "Skus", data: skusList },
  ];
  const showDownload = downloadAvailableList?.find(
    (el) => el?.name == title && el?.data?.length > 0
  );
  const [headerTitle, setHeaderTitle] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const msgKey = "downloader";

  const { mutate } = useDownloadImages(onSuccess, onFail);
  function onSuccess(res) {
    messageApi.destroy(msgKey);
    var aTag = document.createElement("a");
    aTag.setAttribute("href", res?.data?.url);
    aTag.setAttribute("target", "_self");
    aTag.click();
    // dispatch(clearDownloadDetails());
  }
  function onFail(err) {
    console.log(err);
    messageApi.destroy(msgKey);
    dispatch(clearDownloadDetails());
  }
  const getTitleName = () => {
    if (headerTitle === "Batch") {
      return batchName || "Batch";
    }
    return headerTitle;
  };

  useEffect(() => {
    setHeaderTitle(title);
  }, [location, title]);

  const getFilters = (title) => {
    switch (title) {
      case "Batches":
        return (
          <>
            <FiltersRow />
          </>
        );
        break;
      case "Batch":
        return (
          <>
            <FilesFilterRow />
          </>
        );
        break;
      case "Skus":
        return <SkuFilterRow />;
        break;
      case "Users":
        return (
          <>
            <UsersFiltersRow />
          </>
        );
        break;
      case "Profile":
        return (
          <>
            <ProfileFiltersRow />
          </>
        );
        break;
      default:
        return <></>;
    }
  };

  const handleDownloadBtn = () => {
    if (isDownloadOpen) {
      dispatch(clearDownloadDetails());
    } else {
      dispatch(updateDownloadDetails({ isDownloadOpen: true }));
    }
  };

  const selectAllHandler = () => {
    switch (title) {
      case "Batch":
        dispatch(
          updateDownloadDetails({
            toBeDownloaded: batchList?.map((el) => el?.latestImage?.imagePath),
          })
        );
        break;
      case "Skus":
        dispatch(
          updateDownloadDetails({
            toBeDownloaded: skusList?.map((el) => el?.latestImage?.imagePath),
          })
        );
        break;

      default:
        return <></>;
    }
  };

  const config = {
    key: msgKey,
    type: "info",
    content: "Your download will automatically begin shortly.",
    duration: 10,
    className: "custom-ant-message-container",
  };

  const handleDownload = async () => {
    messageApi.open(config);
    await mutate({ paths: toBeDownloaded, type: "flatDownload" });
    await dispatch(clearDownloadDetails());
  };
  return (
    <div className="header-wrapper">
      {contextHolder}
      <div className="main-div">
        <div className="left_container">
          <div className="breadcrumb-div ">
            <BDCrumb title={headerTitle} />
          </div>
          <div className="title-div">
            <Typography className="typo-title">
              {title && getTitleName()}
            </Typography>
          </div>
        </div>
        <div
          className={`right-container ${
            !!showDownload ? "header_btn_class" : ""
          }`}
        >
          {!!showDownload && (
            <Space>
              {isDownloadOpen && <span>{toBeDownloaded.length} selected</span>}
              <DefaultButton
                text={isDownloadOpen ? "Cancel" : "Download Images"}
                clickHandler={handleDownloadBtn}
                size={"small"}
                style={{ width: "max-content" }}
                type="primary"
              />
              {isDownloadOpen && (
                <>
                  <DefaultButton
                    text={"Select All"}
                    clickHandler={selectAllHandler}
                    size={"small"}
                    style={{ width: "max-content" }}
                  />
                  {/* <DefaultButton
                    text={"Download All"}
                    // clickHandler={selectAllHandler}
                    size={"small"}
                    style={{ width: "max-content" }}
                  /> */}
                </>
              )}
              {toBeDownloaded.length > 0 && (
                <DefaultButton
                  size="small"
                  type="ghost"
                  clickHandler={handleDownload}
                  className="downloadIcon"
                  text={
                    // <img
                    //   src="/images/download8.png"
                    //   alt="download"
                    //   style={{ width: "1.2rem", height: "auto" }}
                    // />
                    <CloudDownloadOutlined
                      style={{ color: "green", fontSize: "1.4rem" }}
                    />
                  }
                />
              )}
            </Space>
          )}
          {getFilters(title)}
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
