import { Button } from "antd";

export const GoogleBtn = (props) => (
	<Button
		onClick={() => props.clickHandler()}
		className="google_btn upperCase default-btn common_input_width"
		type="primary"
		size="large"
	>
		<div
			style={{ backgroundColor: "#5184c4" }}
			className="flex-center googleImg_container"
		>
			<img src="/images/googleIcon.png" />
		</div>
		{props.text}
	</Button>
);
