import { memo } from "react";
import "./LeftDrawer.scss";
import { Divider, Space, Tooltip } from "antd";

const LeftDrawer = ({ rawImagesList: data, handleActiveItem, activeItem }) => {
  const getBorder = (elm, index) => {
    if (activeItem?.posType === "rawImages" && index === activeItem?.index) {
      return "1px solid red";
    }

    if (elm?.imageToShow?.imageType?.toLowerCase() === "cc") {
      return "2.5px solid #88D78F";
    }
    return "none";
  };

  const thumbs = () => {
    return data.map((elm, index) => (
      <div>
        <hr
          style={{
            display: index === 0 ? "none" : "block",
            margin: "3px 0",
            padding: 0,
          }}
        />
        {/* <Tooltip trigger={"hover"} title={elm?.imageToShow?.name ?? ""}> */}
        <div
          className="left_thumb_item "
          onClick={() => handleActiveItem(elm, index, "rawImages")}
          style={{
            border: getBorder(elm, index),
            cursor: "pointer",
          }}
        >
          {elm?.imageToShow?.imageType ? (
            <Tooltip title={elm?.imageToShow?.imageType}>
              <img
                src={elm?.imageToShow?.thumbPath?.url}
                alt={"img" + index}
                className="left_carousel_img"
              />
            </Tooltip>
          ) : (
            <img
              src={elm?.imageToShow?.thumbPath?.url}
              alt={"img" + index}
              className="left_carousel_img"
            />
          )}
        </div>
        {/* </Tooltip> */}
      </div>
    ));
  };
  return (
    <div className="raw-images-wrapper">
      <Space direction="vertical" size={0} className="top-space">
        {thumbs()}
      </Space>
    </div>
  );
};

export default memo(LeftDrawer);
