/*
    Iw = width of image
    Ih = height of image
    Cw = width of Canvas
    Ch = height of canvas

    case 1: Iw < Cw and Ih < Ch
    case 2: Iw > Cw and Ih < Ch
    case 3: Iw > Cw and Ih > Ch
    case 4: Iw < Cw and Ih > Ch
*/

const case2 = (dims) => {
	// dims = dimenssions
	try {
		let newImageHeight = (dims.imgH / dims.imgW) * dims.canW;
		return {
			width: dims.canW,
			height: newImageHeight,
			reductionRateW: (dims.imgW - dims.canW) / dims.imgW,
			reductionRateH: (dims.imgH - newImageHeight) / dims.imgH,
		};
	} catch (err) {
		console.error(err);
		return {};
	}
};

const case4 = (dims) => {
	// dims = dimenssions
	try {
		let newImageWidth = (dims.imgW / dims.imgH) * dims.canH;
		return {
			width: newImageWidth,
			height: dims.canH,
			reductionRateW: (dims.imgW - newImageWidth) / dims.imgW,
			reductionRateH: (dims.imgH - dims.canH) / dims.imgH,
		};
	} catch (err) {
		console.error(err);
		return {};
	}
};

const case3 = (dims) => {
	try {
		if (dims.imgW > dims.imgH) {
			const imgDim = case2(dims);
			return imgDim;
		} else if (dims.imgW < dims.imgH) {
			const imgDim = case4(dims);
			return imgDim;
		} else if (dims.imgW === dims.imgH) {
			const imgDim = case4(dims);
			return imgDim;
		}
	} catch (err) {
		console.error(err);
		return {};
	}
};

export { case2, case3, case4 };
