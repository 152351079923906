import React, { useEffect, useState } from "react";
import {
  CameraOutlined,
  TeamOutlined,
  FolderOutlined,
  UserOutlined,
  FileImageOutlined,
  AppstoreOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import "./sidebar.scss";
import { Layout, Menu, Tooltip } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const { Sider } = Layout;

const Sidebar = (props) => {
  const [activeBar, setActiveBar] = useState("/dashboard");
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const getRoutePath = () => {
    return Object.values(params).reduce(
      (path, param) => path.replace("/" + param, ""),
      location.pathname
    );
  };
  const sideList = [
    {
      name: "Dashboard",
      path: "",
      key: "/dashboard",
      icon: AppstoreOutlined,
    },
    {
      name: "Batches",
      path: "",
      key: "/batches",
      icon: FolderOutlined,
    },
    {
      name: "Skus",
      path: "",
      key: "/skus",
      icon: FileImageOutlined,
    },
    {
      name: "Users",
      path: "",
      key: "/users",
      icon: UsergroupAddOutlined,
    },
  ];

  const sdieListElements = sideList.map((item, index) => {
    const key = String(index + 1);
    return {
      key: `${item.key}`,
      icon: React.createElement(item.icon),
      label: item.name,
      title: `${item.name}`,
    };
  });

  const sideBarHandler = (e) => {
    navigate(`${e.key}`);
    // navigate(`${e.key === "dashboard" ? "" : e.key}`);
  };

  useEffect(() => {
    if (location.pathname == "/") {
      setActiveBar("/batches");
    } else {
      let findKey = sideList.find((elm) =>
        location?.pathname?.includes(elm?.key)
      );
      setActiveBar(findKey?.key);
    }
  }, [document.getElementById("headerBar"), location]);
  return (
    <div className="sidebar-main">
      <Sider className="custom_Sider" width="100%">
        <Menu
          mode="inline"
          selectedKeys={[activeBar]}
          onClick={(e) => sideBarHandler(e)}
          style={{
            boxShadow: "1px 0px 4px -1px rgb(0 0 0 / 75%)",
            height: "calc(100vh - 60px)",
          }}
          items={sdieListElements}
          className="side_bar_menu"
        />
      </Sider>
    </div>
  );
};

export default Sidebar;
