import { message, Popover, Space, Tooltip, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import DefaultButton from "../../../commonComponents/buttons/button";
import {
  CheckOutlined,
  DislikeOutlined,
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import "./FileAction.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pushBatchDetails } from "../../../ReduxComponents/Reducers/BatchReducer";
import { useAcceptRejectCanvasFile, useDeleteMarking } from "../../../Api";
import queryString from "query-string";
import { updateFileDetails } from "../../../ReduxComponents/Reducers/FileReducer";
import { useUpdateBatchDetail } from "../../../hooks/useUpdateBatchDetails";
import { pushSkusDetails } from "../../../ReduxComponents/Reducers/SkusReducer";
import useFourNotOne from "../../../hooks/useFourNotOne";
import DynamicModal from "../../../commonComponents/DynamicModal/DynamicModal";
import ConfirmModal from "../ConfirmModal";
const FileAction = ({
  data,
  handleAcceptRejectButton,
  handleModalClose,
  handleClose,
  fetchNewbatch,
  confirmCloser,
  handleIgnore,
  arrowHandler,
}) => {
  const [waitForNewBatch, setWaitForNewBatch] = useState(false);
  const [skuNum, setSkuNum] = useState(0);
  const [confirmFlag, setConfirmFlag] = useState(false);
  const [btnOpenFlag, setBtnOpenFlag] = useState({
    accept: false,
    reject: false,
  });
  const [openAcceptAll, setOpenAcceptAll] = useState(false);
  const [openRejectAll, setOpenRejectAll] = useState(false);
  const [deadZoneFlag, setDeadZoneFlag] = useState({
    flag: false,
    type: "",
  });
  const deadZoneRef = useRef({
    flag: false,
    type: "",
  });
  const deleteRef = useRef("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    batchReducer: {
      activeBatchFileId,
      batchList,
      totalCount,
      currentPage,
      currentPageSize,
    },
    fileReducer: { isActionable, file, markingList, annotationToolsFlag },
    skusReducer: {
      skusList,
      totalCount: skuTotalCount,
      currentPage: skusCurrentPage,
      currentPageSize: skusCurrentPageSize,
    },
  } = useSelector((state) => state);
  const [oldMarkings, setOldMarkings] = useState(markingList);
  const { updateBatchDetails } = useUpdateBatchDetail();
  const { fourNotOne } = useFourNotOne();
  // const { mutate:deleteComments } = useDeleteMarking(commentDeleteSuccess, commentDeleteFail);

  const closeFetcher = () => setWaitForNewBatch(false);

  const openConfirm = () => setConfirmFlag(true);
  const closeConfirm = () => setConfirmFlag(false);

  const activeIndex = (
    location.pathname.includes("skus") ? skusList : batchList
  )?.findIndex((item) => item?._id == activeBatchFileId);

  let hasMoreData = location.pathname.includes("skus")
    ? skuTotalCount > skusCurrentPage * skusCurrentPageSize
    : totalCount > currentPage * currentPageSize;
  let isMarkingSame = markingList.every((elm) =>
    oldMarkings.some((item) => {
      const { toggleOn, ...restItem } = item;
      const { toggleOn: _, ...restElm } = elm;
      return JSON.stringify(restItem) === JSON.stringify(restElm);
    })
  );

  // const arrowHandler = async (type, newFile) => {

  //   if(!isMarkingSame && deleteRef.current == '' && !((markingList.length < oldMarkings.length) && file?.stage?.toLowerCase()?.includes("rejected"))){
  //     openConfirm()
  //     deleteRef.current = (type == -1) ? 'backArrow': 'nextArrow'
  //   }
  //   else if (deadZoneRef.current.flag) {
  //     deadZoneRef.current = { flag: false, type: "back" };
  //     handleAcceptRejectButton("reject");
  //     // setDeadZoneFlag({flag:false, type:'arrows'})
  //   }

  // else {
  //     dispatch(
  //       pushBatchDetails({
  //         activeBatchFileId: (location.pathname.includes("skus")
  //           ? skusList
  //           : batchList)[newFile ?? activeIndex + type]?._id,
  //       })
  //     );
  //     if (location.pathname.includes("skus")) {
  //       navigate(
  //         `${location?.pathname}?batch=${
  //           skusList[newFile ?? activeIndex + type]?.batch
  //         }&id=${skusList[newFile ?? activeIndex + type]?._id}`,
  //         { replace: true }
  //       );
  //     } else {
  //       navigate(
  //         `${location?.pathname}?id=${
  //           batchList[newFile ?? activeIndex + type]?._id
  //         }`,
  //         { replace: true }
  //       );
  //     }
  //     deadZoneRef.current = { flag: false, type: "" };
  //   }

  //   closeFetcher();
  // };

  //   function commentDeleteSuccess(){
  // 	if(deleteRef.current == 'close'){
  // 		handleModalClose()
  // 	}
  // 	else if(deleteRef.current.toLowerCase().includes('arrow')){
  //     deadZoneRef.current = {...deadZoneRef, flag:false}
  //     arrowHandler(deleteRef.current.toLowerCase().includes('back')?-1:+1)
  //     deleteRef.current = ''
  // 	}
  // 	closeConfirm()
  // }
  // function commentDeleteFail(err){
  // 	console.log(err)
  // }
  //   const onSuccessActionAcceptReject = async (data) => {
  // 	// if()  // comfirm close
  //     if (deadZoneRef.current.type !== "close" && deleteRef.current != 'close') {
  //       let file = data?.data?.file;
  //       dispatch(
  //         updateFileDetails({
  //           file,
  //           isActionable: false,
  //         })
  //       );
  //       updateBatchDetails(file);
  //       // await setDeadZoneFlag({flag:false, type:''})
  //         deleteRef.current = 'rejected'
  //       if (location.pathname.includes("skus")) {
  //         if (activeIndex < skusList?.length - 1) {
  //           arrowHandler(deadZoneRef.current.type == "back" ? -1 : +1);
  //         } else if (activeIndex == skusList?.length - 1 && hasMoreData) {
  //           fetchNewbatch();
  //         } else if (activeIndex == skusList?.length - 1 && !hasMoreData) {
  //           handleClose();
  //         }
  //       } else {
  //         if (activeIndex < batchList?.length - 1) {
  //           arrowHandler(deadZoneRef.current.type == "back" ? -1 : +1);
  //         } else if (activeIndex == batchList?.length - 1 && hasMoreData) {
  //           fetchNewbatch();
  //         } else if (activeIndex == batchList?.length - 1 && !hasMoreData) {
  //           handleClose();
  //         }
  //       }
  //     } else if (
  //       (deadZoneRef.current.flag &&
  //       deadZoneRef.current.type == "close") || (deleteRef.current == 'close')
  //     ) {
  //       let file = data?.data?.file;
  //       dispatch(
  //         updateFileDetails({
  //           file,
  //           isActionable: false,
  //         })
  //       );
  //       updateBatchDetails(file);
  //       handleModalClose();
  //     }
  //   };
  //   const onErrorActionAcceptReject = (err) => {
  //     console.log(err);
  //     fourNotOne(err);
  //     message.error(err?.response?.data?.message || "Oops, Please try again!");
  //   };

  //   const { mutate: acceptRejectCanvasFile } = useAcceptRejectCanvasFile(
  //     onSuccessActionAcceptReject,
  //     onErrorActionAcceptReject
  //   );

  // const handleAcceptRejectButton = (type) => {
  //   let fileId = queryString.parse(location.search)?.id;
  //   if(deadZoneRef.current.type  == 'back'){
  //     deadZoneRef.current = {...deadZoneRef, type:''}
  //   }
  //   if(deadZoneRef.current.flag && file?.stage?.toLowerCase()?.includes('accepted') && type == 'accept'){
  //     deadZoneRef.current = {...deadZoneRef, flag:false}
  //   }

  //   if (type == "reject" && markingList.length == 0) {
  //     message.error("Please add markings before rejecting.");
  //   } else if (fileId) {
  //     const obj = {
  //       type,
  //       fileId,
  //     };
  //     acceptRejectCanvasFile(obj);

  //     deleteRef.current = type
  //   }

  // };

  // const handleOpenAnnotations = () => {
  // 	dispatch(updateFileDetails({ annotationToolsFlag: true }));
  // };
  // const handleModalClose = () => {
  //   // dispatch(
  //   // 	pushBatchDetails({
  //   // 		isRefetchBatch: true,
  //   // 	})
  //   // );
  //   if (location.pathname.includes("skus")) {
  // 		dispatch(
  // 			pushSkusDetails({
  // 				scrollToIndex: activeIndex,
  // 			})
  // 		);
  // 	} else {
  // 		dispatch(pushBatchDetails({ scrollToIndex: activeIndex }));
  // 	}
  //   navigate(`${location?.pathname}`, {
  //     // state: {
  //     // 	batchReducer: {
  //     // 		...location?.state?.batchReducer,
  //     // 	},
  //     // },
  //   });
  //   // dispatch(updateFileDetails({ annotationToolsFlag: false }));
  // };

  // const handleClose = () => {
  //   if((markingList.length < oldMarkings.length) && file?.stage?.toLowerCase()?.includes("rejected")){
  //     handleModalClose();
  //   }
  //   else if ((oldMarkings.length !== markingList.length) && !isMarkingSame) {
  //     openConfirm();
  //   deleteRef.current = 'close'
  //   }
  //   else if (
  //     file?.stage?.toLowerCase()?.includes("accepted") &&
  //     markingList.length > 0
  //   ) {
  //     handleAcceptRejectButton("reject");
  //     // setDeadZoneFlag({ ...deadZoneFlag, type: "close" });
  //     deadZoneRef.current = { ...deadZoneRef.current, type: "close" };
  //   } else {
  //     handleModalClose();
  //   }
  // };

  // const fetchNewbatch = (type) => {
  //   if (location.pathname.includes("skus")) {
  //     dispatch(
  //       pushSkusDetails({
  //         currentPage: skusCurrentPage + (type ?? 1),
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       pushBatchDetails({
  //         currentPage: currentPage + (type ?? 1),
  //       })
  //     );
  //   }

  //   setWaitForNewBatch(true);
  //   if (location.pathname.includes("skus")) {
  //     setSkuNum(type ? skusList?.length - 1 : 0);
  //   } else {
  //     setSkuNum(type ? batchList?.length - 1 : 0);
  //   }
  // };

  function areObjectsEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  // const handleIgnore = () => {
  // let deleteObj = {
  // 	fileId: file?._id,
  // 	markingId: [],
  //   };
  // 	if(!isMarkingSame && file?.stage?.toLowerCase()?.includes('rejected')){
  // 		deleteObj.markingId = markingList.filter(obj2 => !oldMarkings.some(obj1 => areObjectsEqual(obj1, obj2))).map((elm)=> (elm?.id));// Find objects in array2 that are not in array1
  // 	}
  // 	else if(!isMarkingSame && (file?.stage?.toLowerCase()?.includes('unreviewed')) || file?.stage?.toLowerCase()?.includes('accepted')) {
  // 		deleteObj.markingId = markingList.map((item)=> (item.id))
  // 	}
  // 	deleteComments(deleteObj)

  // };
  // const confirmCloser = ()=>{
  //   closeConfirm()
  //   deleteRef.current = ''
  // }
  const handleAcceptAll = () => {
    setOpenAcceptAll(false);
    handleAcceptRejectButton("acceptAllSiblings");
  };
  const handleRejectAll = () => {
    setOpenRejectAll(false);
    handleAcceptRejectButton("rejectAllSiblings");
  };
  useEffect(() => {
    if (waitForNewBatch) {
      let arr = location.pathname.includes("skus") ? skusList : batchList;
      arrowHandler(0, skuNum > 0 ? arr.length - 1 : 0);
    }
  }, [batchList, skusList]);

  useEffect(() => {
    if (
      file?.stage?.toLowerCase()?.includes("accepted") &&
      markingList.length > 0
    ) {
      // setDeadZoneFlag({ flag: true, type:'' });
      deadZoneRef.current = { flag: true, type: "" };
    }
  }, [file, markingList]);

  return (
    <div className="fileAction-main flex-center">
      <Space align="center">
        <div
          className={`file_arrows flex-center ${
            activeIndex <= 0 &&
            (location.pathname.includes("skus")
              ? skusCurrentPage == 1
              : currentPage == 1)
              ? "disable_view"
              : ""
          }`}
          onClick={() =>
            activeIndex == 0 &&
            (location.pathname.includes("skus")
              ? skusCurrentPage > 1
              : currentPage > 1)
              ? fetchNewbatch(-1)
              : arrowHandler(-1)
          }
        >
          <LeftOutlined />
        </div>

        <Space.Compact block>
          <DefaultButton
            customClass={`action_btn m-auto ${
              file?.stage?.toLowerCase()?.includes("accepted")
                ? "accepted_btn_type"
                : ""
            }`}
            icon={<CheckOutlined />}
            text={
              file?.stage?.toLowerCase()?.includes("accepted")
                ? "ACCEPTED"
                : "ACCEPT"
            }
            disabled={
              !isActionable ||
              (markingList.length && !isActionable) ||
              file?.stage?.toLowerCase()?.includes("accept")
            }
            clickHandler={() => {
              handleAcceptRejectButton("accept");
            }}
          />
          {isActionable && (
            <Popover
              open={openAcceptAll}
              onOpenChange={setOpenAcceptAll}
              content={
                <DefaultButton
                  onClick={() => handleAcceptAll()}
                  text="Accept All Siblings"
                />
              }
              trigger="click"
            >
              <DefaultButton
                style={{ padding: "4px" }}
                className="flex-center"
                text={<MoreOutlined />}
              />
            </Popover>
          )}
        </Space.Compact>

        {file?.stage?.toLowerCase()?.includes("rejected") && !isActionable ? (
          <Tooltip
            title="SKU is rejected & under review"
            defaultOpen={file?.stage?.toLowerCase()?.includes("rejected")}
          >
            <DefaultButton
              customClass={`action_btn m-auto reject_btn ${
                file?.stage?.toLowerCase()?.includes("rejected")
                  ? "rejected_btn_type"
                  : ""
              }`}
              icon={<DislikeOutlined />}
              text={
                file?.stage?.toLowerCase()?.includes("reject")
                  ? "REJECTED"
                  : "REJECT"
              }
              disabled={
                !isActionable ||
                (file?.stage?.toLowerCase()?.includes("reject") &&
                  isMarkingSame)
              }
            />
          </Tooltip>
        ) : (
          <Space.Compact block>
            <DefaultButton
              customClass={`action_btn m-auto reject_btn 
              ${
                file?.stage?.toLowerCase()?.includes("rejected")
                  ? "rejected_btn_type"
                  : ""
              } ${
                (markingList?.length > 0 &&
                  !(markingList.length < oldMarkings.length) &&
                  !isMarkingSame) ||
                (file?.stage?.toLowerCase()?.includes("unreviewed") &&
                  !isMarkingSame &&
                  markingList.length != oldMarkings.length)
                  ? "glow-on"
                  : ""
              }`}
              icon={<DislikeOutlined />}
              text={
                markingList.length < oldMarkings.length &&
                file?.stage?.toLowerCase()?.includes("rejected")
                  ? "REJECTED"
                  : !isMarkingSame
                  ? "SUBMIT FEEDBACK"
                  : file?.stage?.toLowerCase()?.includes("reject")
                  ? "REJECTED"
                  : "REJECT"
              }
              disabled={
                !isActionable ||
                (file?.stage?.toLowerCase()?.includes("reject") &&
                  (isMarkingSame || markingList.length < oldMarkings.length))
              }
              clickHandler={() => {
                // !annotationToolsFlag
                // 	? handleOpenAnnotations():
                handleAcceptRejectButton("reject");
              }}
            />
            {isActionable && !isMarkingSame && (
              <Popover
                open={openRejectAll}
                onOpenChange={setOpenRejectAll}
                content={
                  <DefaultButton
                    onClick={() => handleRejectAll()}
                    text="Submit Feedback & Reject All Siblings"
                  />
                }
                trigger="click"
              >
                <DefaultButton
                  style={{ padding: "4px" }}
                  className="flex-center"
                  text={<MoreOutlined />}
                />
              </Popover>
            )}
          </Space.Compact>
        )}

        <DefaultButton
          customClass="action_btn m-auto"
          icon={<CloseOutlined />}
          text="CLOSE"
          clickHandler={() => handleClose()}
        />
        <div
          className={`file_arrows flex-center  ${
            (activeIndex ==
              (location.pathname.includes("skus") ? skusList : batchList)
                .length -
                1 ||
              activeIndex < 0) &&
            !hasMoreData
              ? "disable_view"
              : ""
          }`}
          onClick={() =>
            activeIndex ==
              (location.pathname.includes("skus") ? skusList : batchList)
                .length -
                1 && hasMoreData
              ? fetchNewbatch()
              : arrowHandler(+1)
          }
        >
          <RightOutlined />
        </div>
      </Space>
      {confirmFlag && (
        <DynamicModal
          open={confirmFlag}
          component={
            <ConfirmModal
              handleAcceptRejectButton={handleAcceptRejectButton}
              handleIgnore={handleIgnore}
            />
          }
          close={() => confirmCloser()}
          closable
        />
      )}
    </div>
  );
};

export default FileAction;
