import { createSlice, current } from "@reduxjs/toolkit";
import states from "../states";

const skusReducer = createSlice({
	name: "skusReducer",
	initialState: {
		...states.skusDetail,
	},
	reducers: {
		pushSkusDetails: (state, action) => {
			let tempState = { ...current(state) };
			let returnableState = {
				...tempState,
				...action?.payload,
			};
			return { ...returnableState };
		},
	},
});

export const { pushSkusDetails } = skusReducer.actions;
export default skusReducer;
