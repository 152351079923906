import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirect, useNavigate } from "react-router";
import { useGetOrderSummary } from "../../Api";
import { Table, Pagination, Button, Typography, Input, Divider } from "antd";
import useSpinner from "../../hooks/useSpinner";
import spinnerMessages from "../../utils/spinner-messages";

import "./dashboard.scss";
import useFourNotOne from "../../hooks/useFourNotOne";
import { debounce } from "lodash";
import NameFilter from "./NameFilter";
import StatusFilters from "./StatusFilters";
import { useStatusFetcherForBatches } from "../../Api/BatchesApi";

const Dashboard = () => {
	const navigate = useNavigate();

	const {
		userReducer: { selectedCompanies, userManagement, resetPasswordFlag },
	} = useSelector((state) => state);
	const [pageNum, setPageNum] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [searchValue, setSearchValue] = useState("");
	const [statusFiltersOptions, setStatusFiltersOptions] = useState([]);
	const [statusFilters, setStatusFilters] = useState([]);

	// statusFiltersOptions, statusFilters;

	const OnChangePaginator = (num, size) => {
		if (num === 0) {
			setPageNum(1);
		} else {
			setPageNum(num);
		}
		if (pageSize !== size) {
			setPageSize(size);
			setPageNum(1);
		}
	};

	const { data, isFetching, isFetched, error, refetch } = useGetOrderSummary(
		pageNum,
		pageSize,
		selectedCompanies?.company,
		searchValue,
		statusFiltersOptions
	);

	const { refetch: getDropDownStatus, isError } = useStatusFetcherForBatches(
		onStatusGet,
		onStatusFail
	);

	function onStatusGet(data) {
		setStatusFilters([...data?.filters]);
		// dispatch(pushBatchesDetails({ statusFilters: data?.filters }));
	}
	function onStatusFail(err) {
		console.log(err);
	}

	const batchesReducer = useSelector((state) => state.batchesReducer);
	const { dispatchPushItem } = useSpinner();
	const { fourNotOne } = useFourNotOne();
	const HandleOpenBatch = (batchId) => {
		dispatchPushItem({
			loadBatch: { ...spinnerMessages.loadBatch },
		});
		navigate(`/batches/${batchId}`, { state: { batchesReducer } });
	};

	const handleNameSearch = debounce((text) => {
		setSearchValue(text);
	}, 1000);

	useEffect(() => {
		getDropDownStatus();
	}, []);

	useEffect(() => {
		refetch();
	}, [searchValue]);

	useEffect(() => {
		if (error) {
			fourNotOne(error);
		}
	}, [error]);

	return (
		<div className="dashboard-wrapper-main">
			{/* <div className="dashboard-search-box">
        <Input.Search
          // value={searchValue}
          onChange={(e) => {
            handleNameSearch(e.target.value);
          }}
          className="name-filter"
          placeholder="Search name"
          allowClear
          autoFocus="off"
        />
      </div> */}
			<div className="filters_row">
				<div className="page_title">
					<Typography.Title level={3} style={{ marginBottom: "0" }}>
						Dashboard
					</Typography.Title>
				</div>
				<div className="filters_div">
					<StatusFilters
						statusFiltersOptions={statusFiltersOptions}
						setStatusFiltersOptions={setStatusFiltersOptions}
						statusFilters={statusFilters}
					/>
					<Divider type="vertical" style={{ height: "2rem" }} />
					<NameFilter
						searchValue={searchValue}
						setSearchValue={setSearchValue}
					/>
				</div>
			</div>
			<Divider style={{ margin: "5px 0", padding: 0 }} />
			<div className="dashboard-table fixed_height">
				<Table
					bordered
					dataSource={data?.data}
					columns={[
						{
							title: "BatchName",
							key: "batchname",
							render: (_, record) => {
								return (
									<>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											{record?.batchName}
											<Button
												type="primary"
												onClick={() =>
													HandleOpenBatch(
														record?.batch
													)
												}
											>
												Open
											</Button>
										</div>
									</>
								);
							},
						},
						{
							title: "Date(IST)",
							key: "createdAt",
							dataIndex: "createdAt",
						},
						{
							title: "Total",
							key: "total",
							dataIndex: "total",
						},
						{
							title: "Final",
							key: "final",
							children: [
								{
									title: "Accepted",
									key: "final accepted",
									dataIndex: "Final Accepted",
									render: (_, record) => {
										return {
											props: {
												style: {
													backgroundColor:
														"lightBlue",
												},
											},
											children: (
												<span>
													{record?.["Final Accepted"]}
												</span>
											),
										};
									},
								},
								{
									title: "Rejected",
									key: "final Rejected",
									render: (_, record) => {
										return {
											props: {
												style: {
													backgroundColor:
														"lightBlue",
												},
											},
											children: (
												<span>
													{record?.["Final Rejected"]}
												</span>
											),
										};
									},
									dataIndex: "Final Rejected",
								},
								{
									title: "Pending",
									key: "final Pending",
									render: (_, record) => {
										return {
											props: {
												style: {
													backgroundColor:
														"lightBlue",
												},
											},
											children: (
												<span>
													{record?.["Final Pending"]}
												</span>
											),
										};
									},
									dataIndex: "Final Pending",
								},
							],
						},
						{
							title: "Rework",
							key: "rework",
							children: [
								{
									title: "Accepted",
									key: "reworkAccepted",
									render: (_, record) => {
										return {
											props: {
												style: {
													backgroundColor:
														"lightPink",
												},
											},
											children: (
												<span>
													{
														record?.[
															"Rework Accepted"
														]
													}
												</span>
											),
										};
									},
									dataIndex: "Rework Accepted",
								},
								{
									title: "Rejected",
									key: "reworkRejected",
									render: (_, record) => {
										return {
											props: {
												style: {
													backgroundColor:
														"lightPink",
												},
											},
											children: (
												<span>
													{
														record?.[
															"Rework Rejected"
														]
													}
												</span>
											),
										};
									},
									dataIndex: "Rework Rejected",
								},
								{
									title: "Pending",
									key: "reworkPending",
									render: (_, record) => {
										return {
											props: {
												style: {
													backgroundColor:
														"lightPink",
												},
											},
											children: (
												<span>
													{record?.["Rework Pending"]}
												</span>
											),
										};
									},
									dataIndex: "Rework Pending",
								},
							],
						},
					]}
					pagination={false}
					style={{
						flexGrow: 1,
						height: "calc(100% - 40px)",
						marginLeft: "5px",
					}}
					loading={isFetching}
					size="small"
				/>
			</div>
			<div className="dashboard-pagination">
				<Pagination
					pageSizeOptions={[10, 20, 50]}
					total={data?.total}
					defaultPageSize={10}
					defaultCurrent={1}
					showSizeChanger={true}
					showTitle={true}
					onChange={OnChangePaginator}
					style={{
						borderTop: "1px solid #888888",
						padding: "2px",
						marginBottom: "3px",
					}}
					showTotal={(total, range) => {
						return (
							<>
								<div>Total : {total} items</div>
							</>
						);
					}}
					disabled={resetPasswordFlag}
				/>
			</div>
		</div>
	);
};

export default Dashboard;
