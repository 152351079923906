import Profile from "../Components/Self/Profile";
import BatchesManager from "../pages/batchesManager/batchesManager";
import BatchManager from "../pages/batchManager/batchManager";
import Dashboard from "../pages/dashboard/dashboard";
import PendingPage from "../pages/PendingPage";
import skusManager from "../pages/skusManager/skusManager";
import usersManager from "../pages/usersMananger/usersManager";

export const routesList = [
  {
    path: `/`,
    title: "",
    exact: true,
    protected: true,
    component: BatchesManager,
  },
  {
    path: `*`,
    title: "",
    exact: true,
    protected: true,
    component: BatchesManager,
  },
  {
    path: "/dashboard",
    title: "",
    exact: true,
    protected: true,
    component: Dashboard,
  },
  {
    path: "/batches",
    title: "",
    exact: true,
    protected: true,
    component: BatchesManager,
  },
  {
    path: "/batches/:batchId",
    title: "",
    exact: true,
    protected: true,
    component: BatchManager,
  },
  {
    path: "/skus",
    title: "",
    exact: true,
    protected: true,
    component: skusManager,
  },
  {
    path: "/users",
    title: "",
    exact: true,
    protected: true,
    component: usersManager,
  },
  {
    path: "/pending",
    title: "",
    exact: true,
    protected: true,
    component: PendingPage,
  },
  {
    path: "/profile",
    title: "",
    exact: true,
    protected: true,
    component: Profile,
  },
];
