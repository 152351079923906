import batchReducer from "./BatchReducer";
import batchesReducer from "./BatchesReducer";
import { companyReducer } from "./CompanyReducer";
import skusReducer from "./SkusReducer";
import fileReducer from "./FileReducer";
import spinnerReducer from "./SpinnerReducer.js";
import userReducer from "./UserReducer";
import downloadReducer from "./DownloadReducer.js";

const combinedReducers = Object.freeze({
  userReducer: userReducer?.reducer,
  companyReducer: companyReducer?.reducer,
  spinnerReducer: spinnerReducer?.reducer,
  batchesReducer: batchesReducer?.reducer,
  batchReducer: batchReducer?.reducer,
  skusReducer: skusReducer?.reducer,
  fileReducer: fileReducer?.reducer,
  downloadReducer: downloadReducer?.reducer,
});

export default combinedReducers;
