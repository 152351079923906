import { memo, useEffect, useRef, useState } from "react";
import "./Skus.scss";
import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { pushSkusDetails } from "../../ReduxComponents/Reducers/SkusReducer";
import _ from "lodash";

const MultipleSearch = (props) => {
  const [searchDetail, setSearchDetail] = useState("");
  const pasteRef = useRef(false);
  const inputRef = useRef("");
  const dispatch = useDispatch();
  const {
    skusReducer: { searchName },
  } = useSelector((state) => state);

  const updateFilter = (value) => {
    let tempObj = {
      searchName: [...value],
      currentPage: 1,
    };

    if (value.length === 0) {
      tempObj["skusList"] = [];
    }
    dispatch(pushSkusDetails(tempObj));
  };

  const handleChange = (e) => {
    updateFilter(e);
    setSearchDetail("");
    pasteRef.current = false;
  };

  const getSearchValue = (data, e) => {
    //Function to detect splits
    let newData = data || "";
    if (pasteRef.current) {
      inputRef.current.blur();
      let pasteList;
      if (data.includes(",")) {
        pasteList = data.split(",");
      } else if (data.includes(";")) {
        pasteList = data.split(";");
      } else if (data.includes("\n")) {
        pasteList = data.split("\n");
      } else if (data.split("\t")) {
        pasteList = data.split("\t");
      } else {
        pasteList = data.split(" ");
      }
      let tempArr = pasteList.map((el) => el.trim()).filter((item) => !!item);
      newData = tempArr.join(",");
      let newField = [...searchName, ...tempArr];
      setSearchDetail("");
      handleChange(newField);
    } else {
      setSearchDetail(newData);
    }
  };

  useEffect(() => {
    document.body.addEventListener(
      "keydown",
      function (ev) {
        // // function to check the detection
        // ev = ev || window.event; // Event object 'ev'
        // var key = ev.which || ev.keyCode; // Detecting keyCode

        // // Detecting Ctrl
        // var ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false;
        // var macCtrl = ev.ctrlKey
        //   ? ev.ctrlKey
        //   : ev.key == "Meta" || key === 91 || key == 93
        //   ? true
        //   : false;
        // console.log("first", { key, ctrl, macCtrl, ctrol: ev });

        // // If key pressed is V and if ctrl is true.
        // if (key == 86 && macCtrl) {
        //   pasteRef.current = true;
        // }
        // if (key == 86 && ctrl) {
        //   pasteRef.current = true;
        // }
        ev = ev || window.event;
        var key = ev.which || ev.keyCode;
        var ctrl = ev.ctrlKey || ev.key === "Control" || key === 17;
        var cmd = ev.metaKey || ev.key === "Meta";
        var keyCode = ev.keyCode;
        // if ((key == 13 || keyCode == "Enter") && pasteRef.current == true) {
        //   ev.preventDefault();
        //   return false;
        // }
        if ((key === 86 || keyCode == 86) && (ctrl || cmd)) {
          pasteRef.current = true;
        }
      },
      false
    );
  }, []);

  return (
    <div className="search-wrapper">
      <div className="state-main">
        <Select
          ref={inputRef}
          mode="tags"
          placeholder="Type to search multiple skus"
          options={[]}
          value={searchName.map((item) => ({
            label: item,
            value: item,
          }))}
          allowClear={true}
          autoClearSearchValue={true}
          defaultOpen={false}
          showSearch={true}
          suffixIcon={<SearchOutlined />}
          filterOption={false}
          onChange={handleChange}
          notFoundContent={null}
          className="skus-search-box"
          autoFocus={true}
          bordered={true}
          maxTagCount={4}
          maxTagTextLength={8}
          onSearch={getSearchValue}
          searchValue={searchDetail}
          disabled={pasteRef.current}
        />
      </div>
    </div>
  );
};

export default memo(MultipleSearch);
