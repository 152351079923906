import { memo } from "react";
import "./Profile.scss";
import { useSelector } from "react-redux";
import { Descriptions, Divider, Tag, Tooltip } from "antd";

const SelfDescription = () => {
  const {
    userReducer: { user, userManagement, selectedCompanies },
  } = useSelector((state) => state);

  return (
    <div className="self-desc-wrapper">
      <div className="user-data-div">
        <Descriptions title="User Info" layout="vertical">
          <Descriptions.Item label="Name">{user?.name}</Descriptions.Item>
          <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
          <Descriptions.Item label="Designation">
            {user.designation}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions title="Companies" layout="horizontal">
          <Descriptions.Item label="Current Selected Company" span={3}>
            <Tooltip title={selectedCompanies?.companyName}>
              <Tag>{selectedCompanies?.companyCode}</Tag>
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label="Other Companies">
            {userManagement?.companies?.filter(
              (item) => item?._id !== selectedCompanies?._id
            )?.length > 0 &&
              userManagement?.companies
                ?.filter((item) => item?._id !== selectedCompanies?._id)
                ?.map((item, index) => {
                  return (
                    <Tooltip title={item?.companyName}>
                      <Tag>{item?.companyCode}</Tag>
                    </Tooltip>
                  );
                })}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default memo(SelfDescription);
