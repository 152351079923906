import { Button, Modal } from "antd";
import React, { memo, useRef, useState } from "react";
import "./GridComponent.scss";
import Draggable from "react-draggable";

const DraggableModal = ({ open, onClose, title, children }) => {
	// const [open, setOpen] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [bounds, setBounds] = useState({
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
	});
	const draggleRef = useRef(null);

	const handleCancel = (e) => {
		onClose();
	};

	const onStart = (_event, uiData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggleRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	};

	return (
		<>
			<Modal
				title={
					<div
						style={{
							width: "100%",
							cursor: "move",
						}}
						onMouseOver={() => {
							if (disabled) {
								setDisabled(false);
							}
						}}
						onMouseOut={() => {
							setDisabled(true);
						}}
					>
						{title}
					</div>
				}
				closable={false}
				footer={[
					<div className="model-footer">
						<Button type="default" onClick={() => handleCancel()}>
							Close
						</Button>
					</div>,
				]}
				open={open}
				modalRender={(modal, index) => (
					<Draggable
						disabled={disabled}
						bounds={bounds}
						nodeRef={draggleRef}
						onStart={(event, uiData) => onStart(event, uiData)}
					>
						<div
							ref={draggleRef}
							className="draggable-div"
							key={index}
						>
							{modal}
						</div>
					</Draggable>
				)}
			>
				{children}
			</Modal>
		</>
	);
};
export default memo(DraggableModal);
