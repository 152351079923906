import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { pushBatchDetails } from "../ReduxComponents/Reducers/BatchReducer";

export const useUpdateBatchDetail = () => {
  const { batchList } = useSelector((state) => state.batchReducer);
  const dispatch = useDispatch();
  const updateBatchDetails = (file) => {
    let batchListCopy = [...batchList];

    const indexOfFile = batchListCopy.findIndex(
      (item) => item?._id === file?._id
    );

    if (indexOfFile > -1) {
      batchListCopy[indexOfFile] = file;
      dispatch(
        pushBatchDetails({
          batchList: [...batchListCopy],
        })
      );
    } else {
      // ?pending
    }
  };
  const updateSiblings = (list) => {
    let batchListCopy2 = [...batchList];

    // Iterate over the child array
    for (const elem of list) {
      const hasIndex = batchListCopy2.findIndex(
        (parentElement) => parentElement._id === elem._id
      );

      if (hasIndex !== -1) {
        batchListCopy2[hasIndex] = elem;
      } else {
      }
    }
    dispatch(
      pushBatchDetails({
        batchList: [...batchListCopy2],
      })
    );
  };

  return { updateBatchDetails, updateSiblings };
};
