import {
  Collection,
  Column,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import "./FilesList.scss";
import AutoSizer from "react-virtualized-auto-sizer";
import "react-virtualized/styles.css";
import { Empty } from "antd";
import { useNavigate } from "react-router";
import FileThumbnail from "./FileThumbnail";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDownloadDetails,
  updateDownloadDetails,
} from "../../../ReduxComponents/Reducers/DownloadReducer";
import BatchGroupView from "../BatchGroupView";

const ListView = ({
  batchListData,
  statusDict,
  scrollToIndex,
  selectedWidth,
  selectedHeight,
}) => {
  const navigate = useNavigate();
  const {
    downloadReducer: { isDownloadOpen, toBeDownloaded },
    batchReducer: { batchGroupedData, showHiresBatch, batchGroupBy },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [NumberofBatchCells, setNumberofBatchCells] = useState(
    Math.floor(window?.innerWidth / Number(selectedWidth)) - 1 !== 0
      ? Math.floor(window?.innerWidth / Number(selectedWidth)) - 1
      : 1
  );
  const selectedWidthRef = useRef(selectedWidth);
  const selectedHeightRef = useRef(selectedHeight);
  const [dynamicHW, setDynamicHW] = useState({
    height: selectedHeight,
    width: selectedWidth,
  });
  const batchRef = useRef();

  useEffect(() => {
    setNumberofBatchCells(
      Math.floor(window?.innerWidth / Number(selectedWidthRef?.current)) - 1 !==
        0
        ? Math.floor(window?.innerWidth / Number(selectedWidthRef?.current)) - 1
        : 1
    );
    function resizeArea() {
      setDynamicHW({
        height: Number(selectedHeightRef.current),
        width: Number(selectedWidthRef?.current),
      });
      setNumberofBatchCells(
        Math.floor(window?.innerWidth / Number(selectedWidthRef?.current)) -
          1 !==
          0
          ? Math.floor(window?.innerWidth / Number(selectedWidthRef?.current)) -
              1
          : 1
      );

      batchRef?.current?.recomputeCellSizesAndPositions();
    }
    window.addEventListener("resize", resizeArea);
    return () => {
      window.removeEventListener("resize", resizeArea);
      dispatch(clearDownloadDetails());
    };
  }, []);

  useEffect(() => {
    setDynamicHW({
      height: Number(selectedHeight),
      width: Number(selectedWidth),
    });
    setNumberofBatchCells(
      Math.floor(window?.innerWidth / Number(selectedWidth)) - 1 !== 0
        ? Math.floor(window?.innerWidth / Number(selectedWidth)) - 1
        : 1
    );
    selectedWidthRef.current = Number(selectedWidth);
    selectedHeightRef.current = Number(selectedHeight);
    batchRef?.current?.recomputeCellSizesAndPositions();
  }, [selectedHeight]);

  // useEffect(() => {
  //  if(!isDownloadOpen && toBeDownloaded.length>0){
  //   dispatch(updateDownloadDetails({ toBeDownloaded: [] }));
  //  }
  // }, [isDownloadOpen]);

  const handleDownloadList = (data) => {
    let tempList = [...toBeDownloaded];
    let isInsideIndex = tempList.findIndex(
      (item) => item?._id == data?.latestImage?.imagePath?._id
    );
    if (isInsideIndex > -1) {
      tempList = tempList.filter(
        (el) => el?._id != data?.latestImage?.imagePath?._id
      );
    } else {
      tempList.push(data?.latestImage?.imagePath);
    }

    dispatch(updateDownloadDetails({ toBeDownloaded: tempList }));
  };
  const cellRenderer = ({ index, style: style2, key }) => {
    let data = batchListData[index];

    return (
      <div
        className="single-thumbnail-folder"
        style={{
          width: dynamicHW?.width + "px",
          height: dynamicHW?.height + "px",
          ...style2,
        }}
        key={key + showHiresBatch}
      >
        <FileThumbnail
          data={data}
          key={key}
          style={style2}
          statusDict={statusDict}
          dynamicHW={dynamicHW}
          isDownloadOpen={isDownloadOpen}
          handleDownloadList={handleDownloadList}
          selectedForDownload={toBeDownloaded}
          showHires={showHiresBatch}
        />
      </div>
    );
  };

  const cellSizeAndPositionGetter = ({ index }) => {
    const columnCount = NumberofBatchCells; // Number of columns in the grid
    const rowCount = 4; // Number of rows in the grid
    const columnPadding = 2; // Padding between columns
    const rowPadding = 0; // Padding between rows

    const width = dynamicHW?.width;
    const height = dynamicHW?.height;

    const columnIndex = columnCount === 1 ? 0 : index % columnCount;
    const rowIndex = Math.floor(index / columnCount);

    const x = columnIndex * (width + columnPadding);
    const y = rowIndex * (height + rowPadding);
    return {
      height,
      width,
      x,
      y,
    };
  };

  return (
    <div className="collection-view-container">
      {!!batchGroupBy ? (
        <BatchGroupView
          showHires={showHiresBatch}
          dynamicHW={dynamicHW}
          groupedData={batchGroupedData}
          isDownloadOpen={isDownloadOpen}
          handleDownloadList={handleDownloadList}
          selectedForDownload={toBeDownloaded}
          statusDict={statusDict}
          scrollToIndex={scrollToIndex}
        />
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <Collection
              width={width}
              height={height}
              cellCount={batchListData.length}
              cellRenderer={cellRenderer}
              cellSizeAndPositionGetter={cellSizeAndPositionGetter}
              className={"collection-class"}
              verticalOverscanSize={25}
              ref={batchRef}
              noContentRenderer={() => (
                <Empty
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              )}
              scrollToCell={scrollToIndex}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};

export default ListView;
