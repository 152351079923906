const states = {
  userDetails: {
    user: {},
    userManagement: {
      companies: [],
      invitedUsers: [],
    },
    isLoggedIn: false,
    token: "",
    isLoading: false,
    isError: false,
    isSuccess: false,
    isInitiated: false,
    errorMessage: "",
    isVerified: false,
    responseStatus: null,
    firstTimeFlow: true,
    selectedCompanies: {},
    usersList: [],
    refetchUsersList: false,
    resetPasswordFlag: false,
    userAvatar: null,
  },
  companyDetails: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    isInitiated: false,
    errorMessage: "",
    companyFormDetails: {
      code: "",
      brands: [],
      addresses: [],
      companyId: "",
    },
    registrationCompanyDetails: {},
    addressListing: [],
  },

  spinnerStateDetails: {
    spinnerObject: {},
  },

  BatchesDetail: {
    totalCount: 0,
    currentPage: 1,
    currentPageSize: 50,
    selectedBatchName: "",
    selectedBatchId: "",
    batchSearchName: "",
    rejectedReason: "",
    subRejectedReason: "",
    statusFilter: "All",
    viewType: "list",
    batchesList: [],
    statusFiltersOptions: [],
    statusFilters: [],
  },
  BatchDetail: {
    totalCount: 0,
    totalStatsCount: 0,
    currentPage: 1,
    currentPageSize: 100,
    batchName: "",
    batchId: "",
    searchName: "",
    rejectedReason: "",
    subRejectedReason: "",
    statusFilter: "All",
    batchList: [],
    activeBatchFileId: "",
    statusDict: {},
    statusFiltersOptions: [],
    batchStats: [],
    filtersList: [],
    selectedFiltersList: {},
    isRefetchBatch: false,
    scrollToIndex: 0,
    selectedWidth: 217,
    selectedHeight: 342,
    showHiresBatch: false,
    batchGroupBy: null,
    batchGroupedData: [],
  },
  skusDetail: {
    totalCount: 0,
    currentPage: 1,
    currentPageSize: 100,
    // batchName: "",
    // batchId: "",
    searchName: [],
    rejectedReason: "",
    subRejectedReason: "",
    statusFilter: "All",
    skusList: [],
    statusDict: {},
    statusFiltersOptions: [],
    statusStats: [],
    selectedFiltersList: {},
    isRefetchSkus: false,
    scrollToIndex: 0,
    selectedWidth: 217,
    selectedHeight: 342,
    showHires: false,
    groupBy: null,
    groupedData: [],
  },
  fileDetails: {
    file: {},
    versions: [],
    activeFile: {},
    selectedVersion: "",
    commentList: [],
    markingData: {},
    markingList: [],
    isActionable: true,
    rejectedReasonList: [],
    annotationToolsFlag: false,
    extraImagesRawData: [],
    metaSwitch: false,
  },
  downloadDetails: {
    isDownloadOpen: false,
    toBeDownloaded: [],
  },
};

export default states;
