import axios from "axios";
import { request } from "../utils/axios-utils";

const AuthApi = {
	getCredsGoogleLogin: (creds) => {
		const client = axios.create({
			baseURL: `https://www.googleapis.com/oauth2`,
			headers: {
				authorization: `Bearer ${creds.access_token}`,
				accept: "application/json",
			},
		});
		return client.request({
			method: "GET",
			url: `/v1/userinfo?access_token=${creds.access_token}`,
		});
	},

	userLogin: (creds) => {
		return request({
			url: "/users/loginWithGoogle",
			method: "POST",
			data: creds,
		});
	},
};

export { AuthApi };
