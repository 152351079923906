import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  pushPullSpinnerArray,
  pushPullSpinnerArrayClearAll,
  pushPullSpinnerArrayRemoveItem,
} from "../ReduxComponents/Reducers/SpinnerReducer";

const useSpinner = () => {
  const dispatch = useDispatch();
  const {
    spinnerReducer: { spinnerObject },
  } = useSelector((state) => state);

  const dispatchPushItem = (item) => {
    // dispatch(
    // 	pushPullSpinnerArray({
    // 		spinnerObject: {
    // 			...spinnerObject,
    // 			...item,
    // 		},
    // 	})
    // );
    dispatch(pushPullSpinnerArray(item));
  };

  const dispatchPullItem = (key) => {
    // let orginalSpinnerObject = { ...spinnerObject };
    // delete orginalSpinnerObject[key];
    // dispatch(
    // 	pushPullSpinnerArray({
    // 		spinnerObject: { ...orginalSpinnerObject },
    // 	})
    // );
    dispatch(pushPullSpinnerArrayRemoveItem(key));
  };
  const disptachClearAll = () => {
    dispatch(pushPullSpinnerArrayClearAll());
  };

  return { dispatchPullItem, dispatchPushItem, disptachClearAll };
};

export default useSpinner;
