import { memo, useState } from "react";
import "./Users.scss";
import { Button, Tag, message } from "antd";
import DynamicModal from "../../commonComponents/DynamicModal/DynamicModal";
import AddUserByInvite from "./AddUserByInvite";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../ReduxComponents/Reducers/UserReducer";
import { useInviteNewUser } from "../../Api/BatchesApi";
import useFourNotOne from "../../hooks/useFourNotOne";

const UsersFiltersRow = () => {
	const {
		userReducer: { selectedCompanies, userManagement },
	} = useSelector((state) => state);
	const dispatch = useDispatch();
	const [openInviteUsers, setOpenInviteUsers] = useState(false);
	const [isAdding, setIsAdding] = useState(false);
	const { fourNotOne } = useFourNotOne();

	const onSuccessUserInvite = (data) => {
		if (data?.status === 200) {
			setIsAdding(false);
			message.success("User Invited!");
			dispatch(
				updateUser({
					refetchUsersList: true,
				})
			);
			setOpenInviteUsers(false);
		}
	};

	const onErrorUserInvite = (err) => {
		console.log(err);
		fourNotOne(err);
		message.error(err?.response?.data?.message);
		setIsAdding(false);
	};

	const { mutate: inviteNewUser } = useInviteNewUser(
		onSuccessUserInvite,
		onErrorUserInvite
	);

	const handleInviteUser = (name, email) => {
		let companyId =
			selectedCompanies?.company ||
			userManagement?.companies[0]?.company ||
			"";

		let userObj = {
			companyId,
			name,
			email,
		};

		if (!name || !email) {
			return;
		}
		inviteNewUser(userObj);
	};
	const cancelInvitingUser = () => {
		setIsAdding(false);
		setOpenInviteUsers(false);
	};

	return (
		<div className="filters-row-wrapper">
			<Button
				type="text"
				className="invite-button"
				onClick={() => setOpenInviteUsers(true)}
			>
				<Tag color="red" className="invite-button-tag">
					Invite User
				</Tag>
			</Button>
			{openInviteUsers && (
				<DynamicModal
					open={openInviteUsers}
					close={cancelInvitingUser}
					closable={true}
					component={
						<AddUserByInvite
							handleInviteUser={handleInviteUser}
							cancelInvitingUser={cancelInvitingUser}
							isAdding={isAdding}
						/>
					}
					title="Invite User"
					className=""
				/>
			)}
		</div>
	);
};

export default memo(UsersFiltersRow);
