import { memo } from "react";
import "./usersManager.scss";
import Users from "../../Components/Users/Users";

const UsersManager = () => {
	return (
		<div className="users-wrapper-container">
			<Users />
		</div>
	);
};

export default memo(UsersManager);
