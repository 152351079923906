export function getFullDate(date) {
  try {
    if (!date) return "NA";
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let newdate = new Date(date);
    return `${newdate.getDate()} ${months[newdate.getMonth()]}, ${newdate.getFullYear()}, ${newdate.toLocaleTimeString()}`
  }
  catch (err) {
    console.error(err);
    return "--/--/----"
  }
  /* let today = new Date();
  const modifiedHour = modifiedDate.getHours();
  const modifiedMinutes = modifiedDate.getMinutes();
  const modifiedDay = modifiedDate.getDate();
  const modifiedMonth = modifiedDate.getMonth();

  if (modifiedDay === today.getDate()) {
    return `Modified today at ${modifiedHour}:${modifiedMinutes}`
  } else {
    return `Modified on ${modifiedDay} ${months[modifiedMonth]}`
  } */
}
