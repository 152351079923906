import { useDispatch, useSelector } from "react-redux";
import { Divider, Input, Space, message, notification } from "antd";
import { KeyOutlined, UserOutlined } from "@ant-design/icons";
import { useGoogleLogin } from "@react-oauth/google";
import { useRef, useState } from "react";
import DefaultButton from "../../commonComponents/buttons/button";
import Title from "antd/es/typography/Title";
import { GoogleBtn } from "../../commonComponents/googleBtn";
import { AuthApi } from "../../Api/AuthApi";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { updateUser } from "../../ReduxComponents/Reducers/UserReducer";
import { useForgotPassword, useLoginUser } from "../../Api";
import DynamicModal from "../../commonComponents/DynamicModal/DynamicModal";
import ForgotPassword from "./forgotPassword/forgotPassword";
import useFourNotOne from "../../hooks/useFourNotOne";

const Login = (props) => {
	const navigate = useNavigate();
	const simpleValidator = useRef(new SimpleReactValidator());
	const [email, setEmail] = useState("");
	const [openForgetPassword, setOpenForgetPassword] = useState(false);
	const [forgotPasswordRequest, setForgotPasswordRequest] = useState(false);
	const [password, setPassword] = useState("");
	const [, forceUpdate] = useState();
	const dispatch = useDispatch();
	const { fourNotOne } = useFourNotOne();

	const handleInputChange = (value, handler) => {
		handler(value);
	};

	const onLoginSuccessUser = (resp) => {
		let userData = resp?.data?.user;
		localStorage.setItem("token", resp?.data?.token);
		const reducerObj = {
			token: resp?.data?.token,
			user: {
				name: userData?.name,
				designation: userData?.designation,
				email: userData?.email,
				isInternal: userData?.isInternal,
			},
			userManagement: {
				companies: userData?.ACL,
			},
			selectedCompanies: userData?.ACL[0],
		};
		localStorage.setItem("local_user", JSON.stringify(reducerObj));
		sessionStorage.setItem(
			"selectedCompanies",
			JSON.stringify(reducerObj?.selectedCompanies)
		);
		dispatch(updateUser(reducerObj));
		navigate("/");
	};

	const onLoginErrorUser = (err) => {
		console.log(err);
		fourNotOne(err);
		message.error(err?.response?.data?.error || "Please try again!");
	};

	const { mutate: loginUser } = useLoginUser(
		onLoginSuccessUser,
		onLoginErrorUser
	);

	const onForgotPasswordSuccessUser = (resp) => {
		if (resp?.data?.message === "success") {
			setOpenForgetPassword(false);
			message.success("Password sent successfully on your mail.");
		}
	};

	const onForgotPasswordErrorUser = (err) => {
		console.log(err);
		message.error(err?.response?.data?.error || "Please try again!");
		setForgotPasswordRequest(false);
	};

	const { mutate: forgotPassword } = useForgotPassword(
		onForgotPasswordSuccessUser,
		onForgotPasswordErrorUser
	);

	const handleSave = () => {
		const formValid = simpleValidator.current.allValid();
		if (!formValid) {
			simpleValidator.current.showMessages();
			return forceUpdate(1);
		}
		handleLoginUser();
	};

	const handleLoginUser = async () => {
		const creds = {
			email,
			password,
		};
		loginUser(creds);
	};

	const handleGoogleLogin = async (data) => {
		let resp = await AuthApi.userLogin({
			name: data.name,
			email: data.email,
			gmailUser: data,
		});

		if (resp?.payload?.status === 401) {
			message?.error(resp?.payload?.data?.message || "ERROR");
			fourNotOne(resp);
		}

		if (
			resp?.status === 200 &&
			resp?.data?.user?.registrationStatus.toLowerCase() == "unregistered"
		) {
			localStorage.setItem(
				"current_user",
				JSON.stringify(resp?.data?.user?.name)
			);
			localStorage.setItem("token", resp?.data?.token);
			navigate("/register");
		} else if (
			resp?.status === 200 &&
			resp?.data?.user?.registrationStatus.toLowerCase() == "approved"
		) {
			let userData = resp?.data?.user;
			localStorage.setItem("token", resp?.data?.token);
			const reducerObj = {
				token: resp?.data?.token,
				user: {
					name: userData?.name,
					designation: userData?.designation,
					email: userData?.email,
					isInternal: userData?.isInternal,
				},
				userManagement: {
					companies: userData?.ACL,
				},
				selectedCompanies: userData?.ACL[0],
				userAvatar: data?.picture,
			};
			localStorage.setItem("local_user", JSON.stringify(reducerObj));
			sessionStorage.setItem(
				"selectedCompanies",
				JSON.stringify(reducerObj?.selectedCompanies)
			);
			dispatch(updateUser(reducerObj));
			navigate("/");
		} else if (
			resp?.status === 200 &&
			resp?.data?.user?.registrationStatus.toLowerCase() == "pending"
		) {
			navigate("/pending");
		}
	};

	const googleLogin = useGoogleLogin({
		flow: "implicit",
		onSuccess: async (credentialResponse) => {
			const response = await AuthApi.getCredsGoogleLogin(
				credentialResponse
			);
			handleGoogleLogin(response?.data);
		},
	});

	const handleForgotPasswordSubmit = (email) => {
		setForgotPasswordRequest(true);
		forgotPassword({ email });
	};
	const handleForgotPasswordCancel = () => {
		setOpenForgetPassword(false);
	};

	return (
		<div>
			<Space
				direction="vertical"
				size="large"
				className="common_input_width"
			>
				{/* <Title level={3} className="title-mb">
					Login to Dashboard
				</Title> */}
				<div className="input_container text-left">
					<label className="title-mb">Username or Email</label>
					<Input
						addonBefore={<UserOutlined />}
						placeholder="Username or email"
						size="large"
						name="email"
						onChange={(e) =>
							handleInputChange(e.target.value, setEmail)
						}
						value={email}
						onKeyDown={(e) => {
							if (e?.keyCode === 13) {
								handleSave();
							}
						}}
						className="form-group"
					/>
					{simpleValidator.current.message(
						"email",
						email,
						"required|email",
						{
							className: "error_txt",
						}
					)}
				</div>

				<div className="input_container text-left">
					<label className="title-mb">Password</label>
					<Input.Password
						addonBefore={<KeyOutlined />}
						placeholder="Password"
						size="large"
						type="password"
						name="password"
						value={password}
						onChange={(e) =>
							handleInputChange(e.target.value, setPassword)
						}
						onKeyDown={(e) => {
							if (e?.keyCode === 13) {
								handleSave();
							}
						}}
					/>
					{simpleValidator.current.message(
						"password",
						password,
						"required|min:5",
						{
							className: "error_txt",
						}
					)}
				</div>

				<div className="forget-password-div">
					<a
						type="text"
						className="forget-passord-button"
						onClick={() => {
							setOpenForgetPassword(true);
						}}
					>
						Forgot Password ?
					</a>
				</div>
				<div>
					<DefaultButton
						customClass="uppercase common_input_width"
						type="primary"
						text="Login"
						size="large"
						clickHandler={handleSave}
					/>
					<Divider className="login_divider"> OR</Divider>
					<GoogleBtn
						clickHandler={() => googleLogin()}
						text="Login With Google"
					/>
				</div>
			</Space>
			{openForgetPassword && (
				<DynamicModal
					open={openForgetPassword}
					close={handleForgotPasswordCancel}
					closable={true}
					component={
						<ForgotPassword
							handleForgotPasswordSubmit={
								handleForgotPasswordSubmit
							}
							handleForgotPasswordCancel={
								handleForgotPasswordCancel
							}
							forgotPasswordRequest={forgotPasswordRequest}
						/>
					}
					title="Forgot Password"
					className=""
				/>
			)}
		</div>
	);
};

export default Login;
