import React, { useState } from "react";
import { Typography, Space, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import DefaultButton from "../../../commonComponents/buttons/button";

const { Text } = Typography;
const ForgotPassword = ({
	handleForgotPasswordSubmit,
	handleForgotPasswordCancel,
	forgotPasswordRequest,
}) => {
	const [email, setEmail] = useState("");
	const simpleValidator = useRef(new SimpleReactValidator());
	const [, forceUpdate] = useState();

	const handleResetBtn = async () => {
		const formValid = simpleValidator.current.allValid();
		if (!formValid) {
			simpleValidator.current.showMessages();
			return forceUpdate(1);
		}
		handleForgotPasswordSubmit(email);
	};

	return (
		<div className="forgot-password-modal">
			<Space
				direction="vertical"
				style={{ width: "100%", paddingBottom: "5px" }}
			>
				<Text className="forgot_subtext">
					Don't worry! We'll help you with it.
				</Text>
				<div className="input_container">
					<Input
						addonBefore={<UserOutlined />}
						placeholder="username or email"
						size="large"
						class="forgot_input mt-2 "
						onChange={(e) => setEmail(e?.target?.value)}
						value={email}
						onKeyDown={(e) => {
							if (e?.keyCode === 13) {
								handleResetBtn();
							}
						}}
					/>
					{simpleValidator.current.message(
						"email",
						email,
						"required|email",
						{
							className: "error_txt",
						}
					)}
				</div>
			</Space>
			<div
				className=""
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<DefaultButton
					text="Submit"
					type="primary"
					customClass="mt-3"
					clickHandler={() => handleResetBtn()}
					loading={forgotPasswordRequest}
					disabled={forgotPasswordRequest}
					style={{ flexGrow: "1" }}
				/>
			</div>
		</div>
	);
};

export default ForgotPassword;
