import {
	Button,
	Descriptions,
	Divider,
	Input,
	InputNumber,
	Select,
	Tag,
	Typography,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import "./GridForm.scss";
import { CloseCircleOutlined } from "@ant-design/icons";

const sidesInputFields = [
	{
		value: "top",
		label: "top",
	},
	{
		value: "right",
		label: "right",
	},
	{
		value: "bottom",
		label: "bottom",
	},
	{
		value: "left",
		label: "left",
	},
];

const GridForm = ({ formData, changeData }) => {
	const [newData, setNewData] = useState({});
	const [gridSide, setGridSide] = useState(undefined);
	const [px, setPx] = useState(0);

	useEffect(() => {
		setNewData(formData);
		return () => {
			setGridSide(undefined);
			setPx(0);
		};
	}, []);

	const selectHandleChange = (e) => {
		setGridSide(e);
	};

	const pxHandleChange = (e) => {
		setPx(Number(e.target.value));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const updateData = { ...newData };
		updateData[gridSide].push(px);
		setNewData(updateData);
		changeData(newData);
	};

	const handleDelete = (side, index) => {
		const lineData = { ...newData };
		lineData[side].splice(index, 1);
		setNewData(lineData);
		changeData(newData);
	};

	return (
		<div className="grid-form-container">
			<Divider className="typography-divider">
				<Typography.Title level={5}>Draw New Lines</Typography.Title>
			</Divider>

			<div className="grid-form">
				<div className="sides-input">
					<Select
						className="select-sides"
						placeholder="Select Side*"
						onChange={selectHandleChange}
						options={sidesInputFields}
						value={gridSide}
						name="gridSide"
					/>
				</div>
				<div className="sides-pixels-input">
					<Input
						onChange={pxHandleChange}
						placeholder="Line at _ px"
						disabled={!gridSide}
						autoFocus={!gridSide}
						className="select-pixels"
						value={Number(px)}
						type="Number"
					/>
				</div>
				<div className="form-submit-button">
					<Button
						type="default"
						onClick={handleSubmit}
						className="draw-grid-lines-button"
					>
						Draw Grid Lines
					</Button>
				</div>
			</div>

			<Divider className="typography-divider">
				<Typography.Title level={5}>Lines Drawn</Typography.Title>
			</Divider>

			<div className="drawn-values">
				{Object.keys(newData).map((side, i) => {
					return (
						<Descriptions
							className="description-container"
							bordered
							key={i}
							size="small"
						>
							<Descriptions.Item
								label={side.toUpperCase()}
								className="key-container"
							>
								{newData[side].length > 0 &&
									newData[side].map((line, index) => {
										return (
											<Tag
												closeIcon={
													<CloseCircleOutlined />
												}
												onClose={() =>
													handleDelete(side, index)
												}
												key={index}
												className="values-container"
												style={{
													userSelect: "none",
												}}
											>
												{`${line}px`}
											</Tag>
										);
									})}
							</Descriptions.Item>
						</Descriptions>
					);
				})}
			</div>
		</div>
	);
};

export default memo(GridForm);
