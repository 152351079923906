import "./FilesList.scss";
import { Card, Skeleton, Tooltip, Typography } from "antd";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { colorScheme } from "../../../utils/extra-utils";
import {
  ExclamationOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { pushBatchDetails } from "../../../ReduxComponents/Reducers/BatchReducer";
import { updateFileDetails } from "../../../ReduxComponents/Reducers/FileReducer";

const FileThumbnail = ({
  data,
  key,
  style,
  statusDict,
  fileIndex,
  dynamicHW,
  isDownloadOpen,
  handleDownloadList,
  selectedForDownload,
  showHires,
}) => {
  const batchReducer = useSelector((state) => state.batchReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoaded, SetIsLoaded] = useState(false);

  const handleCardClick = (data) => {
    if (isDownloadOpen) {
      handleDownloadList(data);
    } else {
      dispatch(
        pushBatchDetails({
          scrollToIndex: fileIndex,
        })
      );
      navigate(`${location?.pathname}?id=${data?._id}`, {
        state: {
          batchReducer,
        },
      });
      dispatch(updateFileDetails({ metaSwitch: false }));
    }
  };

  const getSignFromSwitch = (sign) => {
    switch (sign) {
      case "exclamation":
        return (
          <ExclamationOutlined
            style={{
              // width: "100%",
              // height: "100%",
              margin: 0,
              color: "white",
              fontWeight: "bolder",
              verticalAlign: "center",
            }}
            className="flex-center"
          />
        );
      case "check":
        return (
          <CheckOutlined
            style={{
              // width: "100%",
              // height: "100%",
              margin: 0,
              color: "white",
              fontWeight: "bolder",
              verticalAlign: "center",
            }}
            className="flex-center"
          />
        );
      case "cross":
        return (
          <CloseOutlined
            style={{
              // width: "100%",
              // height: "100%",
              margin: 0,
              color: "white",
              fontWeight: "bolder",
              verticalAlign: "center",
            }}
            className="flex-center"
          />
        );
      default:
        return <></>;
    }
  };

  const getSign = () => {
    const dataStatus = data?.status;
    const { color, sign } = colorScheme[dataStatus];

    return (
      <span
        style={{
          width: "90%",
          height: "90%",
          // backgroundColor: color,
          // display: "block",
          // borderRadius: "50%",
        }}
        className="flex-center"
      >
        <Tooltip title={data?.stage} placement="topLeft">
          {getSignFromSwitch(sign)}
        </Tooltip>
      </span>
    );
  };

  return (
    <div className="file-view-wrapper" key={key}>
      <Card
        hoverable
        style={{
          width: dynamicHW?.width - 15 + "px",
          height: dynamicHW?.height - 20 + "px",
          padding: 0,
        }}
        onClick={() => {
          handleCardClick(data);
        }}
        className={`file-card-main ${
          !!selectedForDownload?.find(
            (el) => el?._id === data?.latestImage?.imagePath?._id
          )
            ? "selectedItem"
            : ""
        }`}
      >
        <div
          style={{
            width: "max-content",
            height: "max-content",
          }}
        >
          {!isLoaded && (
            <Skeleton.Image
              style={{
                height: dynamicHW?.height - 42 + "px",
                width: dynamicHW?.width - 17 + "px",
                objectFit: "contain",
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="loader-class"
              active={true}
            />
          )}
          <img
            alt="example"
            src={
              showHires
                ? data?.latestImage?.imagePath?.url
                : data?.latestImage?.thumbPath?.url
            }
            style={{
              height: dynamicHW?.height - 42 + "px",
              width: dynamicHW?.width - 17 + "px",
              objectFit: "contain",
              margin: 0,
              display: isLoaded ? "block" : "none",
            }}
            onLoad={(e) => {
              SetIsLoaded(true);
            }}
          />
          {isLoaded && (
            <span
              style={{
                height: "22px",
                width: "22px",
                position: "absolute",
                top: "5px",
                left: "5px",
                backgroundColor:
                  colorScheme[data?.status] && colorScheme[data.status].color,
                //   display: "block",
                borderRadius: "50%",
              }}
              className="flex-center"
            >
              {getSign()}
            </span>
          )}
        </div>
        <div
          style={{
            width: "100%",
            height: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title={data?.filename} placement="topLeft">
            <Typography.Text className="filename-typo">
              {data?.filename}
            </Typography.Text>
          </Tooltip>
        </div>
      </Card>
    </div>
  );
};

export default FileThumbnail;
