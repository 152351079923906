import { Table } from "antd";
import React from "react";

const CommonTable = (props) => {
  return (
    <Table
      columns={props.columns}
      dataSource={props.data}
      pagination={props.pagination ?? false}
      loading={props.loading}
      bordered={props.bordered}
      size="middle"
      rowClassName={props.rowClassName}
      className={`common-table ${props.customClass ?? ""}`}
      style={{
        width: "100%",
      }}
      {...props}
    />
  );
};

export default CommonTable;
