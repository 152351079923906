import React from "react";
import { Button, Col, Row } from "antd";

const DefaultButton = (props) => {
	return (
		<Button
			className={
				props.customClass
					? `default-btn-${props.type ?? "default"} ${
							props.customClass
					  }`
					: `default-btn-${props.type ?? "default"}`
			}
			type={props.type ? props.type : "default"}
			onClick={() => (props.clickHandler ? props.clickHandler() : null)}
			shape={props.shape ? props.shape : "default"}
			size={props.size ? props.size : "middle"}
			disabled={props.disabled}
			style={props.style ? props.style : {}}
			htmlType={props.htmlType ? props.htmlType : "button"}
			loading={props.loading}
			{...props}
		>
			<span>
				{props.iconSide && props.iconSide.toLowerCase() == "left" && (
					 <img
						alt="left_icon"
						src={props.icon}
						className="btn-leftIcon"
					/>
				)}
				{props.text}
				{props.iconSide && props.iconSide.toLowerCase() == "right" && (
					<img
						alt="right-icon"
						src={props.icon}
						className="btn-rigthIcon"
					/>
				)}
			</span>
		</Button>
	);
};

export default DefaultButton;

export const FormButtons = (props) => {
	return (
		<Row className="mt-2 form-btn-row">
			<Col
				span={props.colSpan ?? 24}
				style={{
					textAlign: `${props.align ?? "right"}`,
				}}
			>
				<Button
					style={{
						margin: "0 8px",
					}}
					size={props.size ?? "middle"}
					onClick={() => props.onCancel()}
				>
					Cancel
				</Button>
				<Button
					type="primary"
					size={props.size ?? "middle"}
					onClick={() => props.onConfirm()}
					htmlType={props.htmlType ?? null}
				>
					Ok
				</Button>
			</Col>
		</Row>
	);
};
