import { useNavigate } from "react-router-dom";
import { QueryCache } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { updateUser } from "../ReduxComponents/Reducers/UserReducer";

const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryCache = new QueryCache();

  const logout = async () => {
    await queryCache.removeQueries;
    dispatch(updateUser({ token: "", isLoggedIn: false }));
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("local_user");
    localStorage.removeItem("current_user");
    sessionStorage.removeItem("selectedCompanies");
    localStorage.removeItem("metaFlag");
    localStorage.removeItem("showHires");
    localStorage.removeItem("groupBy");
    navigate("/");
  };

  return { logout };
};

export default useLogout;
