import { createSlice, current } from "@reduxjs/toolkit";
import states from "../states";

const batchReducer = createSlice({
	name: "batchReducer",
	initialState: {
		...states.BatchDetail,
	},
	reducers: {
		pushBatchDetails: (state, action) => {
			let tempState = { ...current(state) };
			let returnableState = {
				...tempState,
				...action?.payload,
			};
			return { ...returnableState };
		},
	},
});

export const { pushBatchDetails } = batchReducer.actions;
export default batchReducer;
