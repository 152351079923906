export function copyToClipboard(text, cb) {
  try {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        function () {
          cb();
        },
        function (err) {
          console.log(err);
        }
      );
    } else {
      let textarea = document.createElement("textarea");
      if (textarea) {
        textarea.style.display = "hidden";

        document.body.appendChild(textarea);
        textarea.value = text.toString();
        textarea.focus();
        textarea.select();
        let success = document.execCommand("COPY");
        if (success) {
          cb();
        }
        document.body.removeChild(textarea);
      }
    }
  } catch (err) {
    console.log(err);
  }
}
