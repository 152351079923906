import { memo, useEffect, useState } from "react";
import "./BatchesList.scss";
import {
	Divider,
	Pagination,
	message,
	Row,
	Col,
	Descriptions,
	Table,
	Switch,
	Tooltip,
	Typography,
} from "antd";
import TabelView from "./TableView";
import ListView from "./ListView";
import { useDispatch, useSelector } from "react-redux";
import { pushBatchesDetails } from "../../../ReduxComponents/Reducers/BatchesReducer";
import {
	useGetBatchInfo,
	useToggleBatchFeedback,
} from "../../../Api/BatchesApi";
const BatchesList = () => {
	const {
		batchesReducer: {
			viewType,
			batchesList,
			totalCount,
			currentPage,
			currentPageSize,
			batchSearchName
		},
    userReducer:{
      resetPasswordFlag
    }
	} = useSelector((state) => state);
	const dispatch = useDispatch();
	const [isLoadingToggle, setIsLoadingToggle] = useState(false);
	const showTotal = (total) => `Total batches:  ${total} `;
	const onShowSizeChange = (current, pageSize) => {
		if (pageSize !== currentPageSize) {
			dispatch(
				pushBatchesDetails({
					currentPage: 1,
					currentPageSize: Number.parseInt(pageSize),
				})
			);
		} else if (current !== currentPage && current >= 1) {
			dispatch(
				pushBatchesDetails({
					currentPage: Number.parseInt(current),
				})
			);
		}
	};

	const [selectedBatch, setSelectedBatch] = useState(null);

	const {
		data: batchInfo,
		isFetching,
		isFetched,
		refetch: refetchBatchInfo,
	} = useGetBatchInfo(selectedBatch);

	useEffect(() => {
		console.log("selected Batch changed", { selectedBatch });
	}, [selectedBatch]);

	useEffect(() => {
		setSelectedBatch(null);
	}, [currentPage, currentPageSize, batchSearchName,viewType]);

	const onSuccessHandleToggle = (data, context) => {
		message.success(
			`Feedback has been ${
				context.param.indexOf("enable") > -1 ? "Enabled" : "Disabled"
			}`
		);
		setIsLoadingToggle(false);
		refetchBatchInfo();
	};

	const onErrorHandleToggle = (err) => {
		console.log(err);
		setIsLoadingToggle(false);
		message.error(err?.response?.data?.message || "Oops try again");
	};

	const { mutate: setFeedBackToogle } = useToggleBatchFeedback(
		onSuccessHandleToggle,
		onErrorHandleToggle
	);

	const GetDataForBatchDetails = (batchInfo) => {
		const stats = batchInfo?.stats?.map((item) => {
			return {
				key: item?.stringStatus,
				value: item?.count,
			};
		});
		stats?.push({
			key: "Total",
			value: batchInfo?.totalFiles,
		});
		return stats;
	};

	const handleToggleFeedback = (data) => {
		let obj = { batchId: selectedBatch };

		if (data === true) {
			obj = { ...obj, param: "enableFeedbackPropagation" };
		} else {
			obj = { ...obj, param: "disableFeedbackPropagation" };
		}

		setIsLoadingToggle(true);
		setFeedBackToogle(obj);
	};

	return (
		<>
			<Row className="batch-list-wrapper">
				<Col span={18}>
					<div className="list-tabular-data-div">
						{viewType === "list" ? (
							<ListView
								batchesListData={batchesList}
								selectedBatch={selectedBatch}
								setSelectedBatch={setSelectedBatch}
							/>
						) : (
							<TabelView
								batchesListData={batchesList}
								selectedBatch={selectedBatch}
								setSelectedBatch={setSelectedBatch}
								pageNum={currentPage}
								pageSize={currentPageSize}
							/>
						)}
					</div>
					<Divider style={{ margin: 0, padding: 0 }} />
					<div className="pagination-div">
						<Pagination
							size="small"
							total={totalCount}
							showSizeChanger
							showTotal={showTotal}
							defaultCurrent={1}
							onChange={onShowSizeChange}
							responsive={true}
							current={currentPage}
							pageSize={currentPageSize}
							pageSizeOptions={[50, 100, 150, 200]}
						/>
					</div>
				</Col>
				<Col span={6} className="batch-list-status">
					{!!selectedBatch && (
						<div>
							<h3 className="batchesName">
								{batchInfo?.batchName}
							</h3>
							<Table
								loading={isFetching || isLoadingToggle}
								bordered
								pagination={false}
								size="small"
								dataSource={GetDataForBatchDetails(batchInfo)}
								columns={[
									{
										title: "key",
										dataIndex: "key",
										key: "key",
									},
									{
										title: "value",
										dataIndex: "value",
										key: "value",
									},
								]}
							/>
							<div
								style={{
									display: "flex",
									width: "100%",
									justifyContent: "space-evenly",
									alignItems: "center",
									marginTop: "10px",
									padding: "10px",
									// border: "1px solid #777",
									boxShadow: "1px 1px 3px #777",
								}}
							>
								<Typography.Text
									style={{
										width: "200px",
									}}
								>
									Enable/Disable Feedback
								</Typography.Text>
								<Tooltip>
									<Switch
										checked={batchInfo?.feedbackPropagation}
										onClick={(e) => {
											handleToggleFeedback(e);
										}}
										checkedChildren="Enabled"
										unCheckedChildren="Disabled"
										disabled={isFetching || isLoadingToggle}
									></Switch>
								</Tooltip>
							</div>
						</div>
					)}
				</Col>
			</Row>
			<div></div>
		</>
	);
};

export default memo(BatchesList);
