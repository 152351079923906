import { memo, useEffect } from "react";
import "./Users.scss";
import Header from "../../commonComponents/BatchesHeader/header";
import UsersList from "./UsersList";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../ReduxComponents/Reducers/UserReducer";
import { message } from "antd";
import { useFetchUsersInfo } from "../../Api/BatchesApi";
import useFourNotOne from "../../hooks/useFourNotOne";

const Users = () => {
	const {
		userReducer: {
			selectedCompanies,
			userManagement,
			usersList,
			refetchUsersList,
		},
	} = useSelector((state) => state);
	const dispatch = useDispatch();
	const { fourNotOne } = useFourNotOne();

	const onSuccessUsersInfoApi = (data) => {
		const users = data?.data?.users;
		dispatch(
			updateUser({
				usersList: users,
				refetchUsersList: false,
			})
		);
	};

	const onErrorUsersInfoApi = (err) => {
		console.log(err);
		fourNotOne(err);
		message.error(
			err?.response?.data?.message || "Opps, Please try again!"
		);
	};

	const { refetch: refetchUsers } = useFetchUsersInfo(
		onSuccessUsersInfoApi,
		onErrorUsersInfoApi,
		selectedCompanies?.company ||
			userManagement?.companies[0]?.company ||
			""
	);

	useEffect(() => {
		if (
			selectedCompanies?.company ||
			userManagement?.companies[0]?.company
		) {
			refetchUsers();
		}
	}, []);

	useEffect(() => {
		if (refetchUsersList) {
			refetchUsers();
		}
	}, [refetchUsersList]);

	return (
		<div className="users-wrapper">
			<Header title="Users" />
			<UsersList usersList={usersList} />
		</div>
	);
};

export default memo(Users);
