import { memo, useEffect } from "react";
import "./FilesFilterRow.scss";
import { useSelector } from "react-redux";
import { Space } from "antd";
import MultiSelect from "../../../commonComponents/MultiSelect/MultiSelect";

const DynamicFilters = ({ localSelectedFilters, setLocalSelectedFilters }) => {
	const {
		batchReducer: { filtersList, selectedFiltersList },
	} = useSelector((state) => state);

	const getFiltersOptions = (id) => {
		let itemIndex = filtersList?.findIndex((data) => data?._id === id);
		let optionsItem = filtersList[itemIndex];

		let returnalbeItem =
			(optionsItem?.values?.length &&
				optionsItem?.values.map((options) => ({
					value: options,
					label: options,
				}))) ||
			[];
		return returnalbeItem;
	};

	useEffect(() => {
		setLocalSelectedFilters(selectedFiltersList);
	}, []);

	const handleOnChange = (e, item, id, key) => {
		let localSelectedFiltersCopy = { ...localSelectedFilters };
		let localFilterData = localSelectedFiltersCopy[key];
		if (!localFilterData) {
			localSelectedFiltersCopy[key] = [];
		}
		localSelectedFiltersCopy[key] = [...e];
		if (localSelectedFiltersCopy[key]?.length === 0) {
			delete localSelectedFiltersCopy[key];
		}
		setLocalSelectedFilters(localSelectedFiltersCopy);
	};

	return (
		<div className="dynamic-filters-wrapper">
			<Space
				className="spaces"
				direction="vertical"
				style={{ width: "100%" }}
			>
				{filtersList?.map((item, index) => {
					return (
						<MultiSelect
							placeholder={item?.label}
							className=""
							onChange={(e) =>
								handleOnChange(e, item, item?._id, item?.key)
							}
							key={index}
							item={item}
							filteroptions={getFiltersOptions(item?._id)}
							value={localSelectedFilters[item?.key]}
							name={item?.key}
							style={{ width: "100%" }}
						/>
					);
				})}
			</Space>
		</div>
	);
};

export default memo(DynamicFilters);
