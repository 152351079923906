import { Breadcrumb, Tooltip } from "antd";
import "./breadcrumb.scss";
import { Link } from "react-router-dom";
import {
	AppstoreOutlined,
	FolderOutlined,
	PictureOutlined,
	HomeOutlined,
} from "@ant-design/icons";

const itemsList = [
	{
		title: (
			<Tooltip title="Home">
				<Link to="/batches">
					<HomeOutlined />
				</Link>
			</Tooltip>
		),
		key: "Dashboard",
	},
	{
		title: (
			<Tooltip title="Batches">
				<Link to="/batches">
					<FolderOutlined /> {"Batches"}
				</Link>
			</Tooltip>
		),
		key: "Batches",
	},
	{
		title: (
			<Tooltip title="Batch">
				<Link to="/batches/batch">
					<PictureOutlined /> {"Batch"}
				</Link>
			</Tooltip>
		),
		key: "Batch",
	},
	{
		title: (
			<Tooltip title="Preview">
				<Link to="/batches/batch">
					<PictureOutlined /> {"fileId"}
				</Link>
			</Tooltip>
		),
		key: "Preview",
	},
];

const BDCrumb = ({ title }) => {
	const getBCfinalList = () => {
		let returnableCrumb = [];

		let titleIndex = itemsList.findIndex((item) => item.key === title);
		if (titleIndex > -1) {
			returnableCrumb = [...itemsList?.slice(0, titleIndex)];
		} else {
			returnableCrumb = [itemsList[0]];
		}

		returnableCrumb.push({
			title: "",
		});

		return returnableCrumb;
	};

	return (
		<div className="breadcrumb-wrapper">
			<Breadcrumb items={getBCfinalList()} separator="/" />
		</div>
	);
};

export default BDCrumb;
