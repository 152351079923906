import React, { useState } from "react";
import { Typography, Space, Input, Select } from "antd";
import { UserOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import DefaultButton from "../buttons/button";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../ReduxComponents/Reducers/UserReducer";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const SearchCompanyByName = ({ handleCloseModal }) => {
	const [companyNameSearched, setCompanyNameSearched] = useState("");
	const { userManagement } = useSelector((state) => state.userReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleResetBtn = async () => {
		// handleForgotPasswordSubmit(email);
		let companies = userManagement?.companies;
		let selectedCompanyNew = companies.filter(
			(item) => item?.company === companyNameSearched
		);

		sessionStorage.setItem(
			"selectedCompanies",
			JSON.stringify(selectedCompanyNew[0])
		);
		dispatch(
			updateUser({
				selectedCompanies: selectedCompanyNew[0],
			})
		);
		navigate("/");
		handleCloseModal();
	};

	const getOptions = () => {
		let companies = userManagement?.companies;
		let optionsList = [];
		if (companies?.length) {
			companies?.forEach((element) => {
				let item = {
					label: `${element?.companyName} - ${element?.companyCode}`,
					value: element?.company,
				};

				optionsList.push(item);
			});
		}
		return optionsList;
	};

	const getDisabled = () => {
		let companies = userManagement?.companies;
		return (
			companies.findIndex(
				(item) => item?.company === companyNameSearched
			) > -1
		);
	};

	return (
		<div className="forgot-password-modal">
			<Space
				direction="vertical"
				style={{ width: "100%", paddingBottom: "5px" }}
			>
				<Text className="forgot_subtext w-100">
					Hurray! let's find the company together.
				</Text>
				<div className="input_container" style={{ width: "100%" }}>
					<Select
						showSearch
						placeholder="Select Company Name"
						// optionFilterProp="children"
						size="large"
						style={{ width: "100%" }}
						className="forgot_input mt-2 "
						// addonBefore={<UserOutlined />}
						onChange={(e) => setCompanyNameSearched(e)}
						onSearch={(e) => setCompanyNameSearched(e)}
						filterOption={(input, option) =>
							(option?.label ?? "")
								.toLowerCase()
								.includes(input.toLowerCase())
						}
						value={companyNameSearched}
						options={getOptions()}
					/>
				</div>
			</Space>
			<div
				className=""
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<DefaultButton
					text="Select"
					type="primary"
					customClass="mt-3"
					clickHandler={() => handleResetBtn()}
					style={{ flexGrow: "1" }}
					disabled={!getDisabled()}
				/>
			</div>
		</div>
	);
};

export default SearchCompanyByName;
