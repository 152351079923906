import React from "react";
import SelectSize from "../Batch/FilesFilterRow/SelectSize";
import { Divider, Switch } from "antd";
import MultipleSearch from "./MultipleSearch";
import StatusFilters from "../Batch/FilesFilterRow/StatusFilters";
import GroupByFilter from "../../commonComponents/GroupByFilter";
import ResolutionSwitch from "../../commonComponents/ResolutionSwitch";

const SkuFilterRow = () => {
  return (
    // <div className="sku-filter-row">
    //   <SelectSize />
    //   <Divider type="vertical" style={{ height: "2rem" }} />
    //   <StatusFilters />
    //   <Divider type="vertical" style={{ height: "2rem" }} />
    //   <MultipleSearch pageName="skus" />
    // </div>
    <div className="skus_row">
      <div className="skus_row_element">
        <ResolutionSwitch type={"sku"} />
        <Divider type="vertical" style={{ height: "2rem" }} />
        <SelectSize />
        <Divider type="vertical" style={{ height: "2rem" }} />
        <GroupByFilter type={"sku"} />
        <Divider type="vertical" style={{ height: "2rem" }} />
        <StatusFilters />
        <Divider type="vertical" style={{ height: "2rem" }} />
        <MultipleSearch pageName="skus" />
      </div>
    </div>
  );
};

export default SkuFilterRow;
