import { memo, useState } from "react";
import Header from "../../commonComponents/BatchesHeader/header";
import "./batches.scss";
import BatchesList from "./BatchesList/BatchesList";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useSpinner from "../../hooks/useSpinner";
import { pushBatchesDetails } from "../../ReduxComponents/Reducers/BatchesReducer";
import { message } from "antd";
import { useEffect } from "react";
import spinnerMessages from "../../utils/spinner-messages";
import {
  useFetchBatchesList,
  useStatusFetcherForBatches,
} from "../../Api/BatchesApi";
import states from "../../ReduxComponents/states";
import useFourNotOne from "../../hooks/useFourNotOne";

const Batches = () => {
  const {
    batchesReducer: {
      currentPage,
      currentPageSize,
      batchSearchName,
      statusFiltersOptions,
    },
    userReducer: { selectedCompanies, userManagement },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const { dispatchPushItem, dispatchPullItem } = useSpinner();
  const { fourNotOne } = useFourNotOne();

  const onSuccessBatchList = (data, context, list) => {
    dispatch(
      pushBatchesDetails({
        batchesList: [...data?.data?.batches],
        totalCount: data?.data?.total,
      })
    );
    dispatchPullItem("loadingBatchesList");
  };

  const onErrorBatchList = (err, context, list) => {
    console.log(err);
    fourNotOne(err);
    message.error(err?.response?.data?.message || "Oops! Please try again");
    dispatchPullItem("loadingBatchesList");
  };

  console.log(statusFiltersOptions);

  const { refetch: fetchBatches } = useFetchBatchesList(
    onSuccessBatchList,
    onErrorBatchList,
    [
      currentPage,
      currentPageSize,
      selectedCompanies,
      userManagement,
      batchSearchName,
      statusFiltersOptions,
    ]
  );

  useEffect(() => {
    dispatchPushItem({
      loadingBatchesList: { ...spinnerMessages.loadingBatchesList },
    });

    fetchBatches();
  }, [
    currentPage,
    currentPageSize,
    batchSearchName,
    selectedCompanies,
    statusFiltersOptions,
  ]);

  const { refetch: getDropDownStatus, isError } = useStatusFetcherForBatches(
    onStatusGet,
    onStatusFail
  );

  function onStatusGet(data) {
    dispatch(pushBatchesDetails({ statusFilters: data?.filters }));
  }
  function onStatusFail(err) {
    console.log(err);
  }

  useEffect(() => {
    getDropDownStatus();
    return () => {
      dispatch(
        pushBatchesDetails({
          ...states?.BatchesDetail,
        })
      );
    };
  }, []);

  return (
    <div className="batches-wrapper">
      <Header title="Batches" />
      <BatchesList />
    </div>
  );
};

export default memo(Batches);
