import { memo, useCallback, useEffect, useState } from "react";
import "./dashboard.scss";
import { Typography, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

const NameFilter = ({ searchValue, setSearchValue }) => {
	const [searchText, setSearchText] = useState("");

	const updateFilter = useCallback(
		debounce(() => {
			setSearchValue(searchText);
		}, 500),
		[searchText]
	);

	useEffect(() => {
		setSearchText(searchValue);
	}, [searchValue]);

	const handleNameChange = (e) => {
		setSearchText(e);
	};

	useEffect(() => {
		if (searchValue !== searchText) {
			updateFilter();
		}

		return () => updateFilter.cancel();
	}, [updateFilter, searchText]);

	return (
		<>
			<Typography.Text className="file-name-search">
				<Input.Search
					value={searchText}
					onChange={(e) => {
						handleNameChange(e.target.value);
					}}
					className="name-filter"
					placeholder="Batch name"
					allowClear
					autoFocus="off"
				/>
			</Typography.Text>
		</>
	);
};

export default memo(NameFilter);
