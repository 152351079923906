import { memo, useEffect, useState } from "react";
import "../FiltersRow/FiltersRow.scss";
import { useDispatch, useSelector } from "react-redux";
import { Select, Typography } from "antd";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import { pushBatchesDetails } from "../../../ReduxComponents/Reducers/BatchesReducer";

const StatusFilters = () => {
  const location = useLocation();
  const {
    batchesReducer: { statusFiltersOptions, statusFilters },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [filtersOptions, setFiltersOptions] = useState([
    // { label: "Pending", value: 600 },
  ]);

  useEffect(() => {
    if (statusFilters?.length > 0) {
      let newArr = statusFilters?.map((elm) => {
        return { label: elm?.key, value: elm?.value };
      });

      setFiltersOptions(newArr);
    }
  }, [statusFilters]);

  const updateFilter = debounce((value) => {
    dispatch(
      pushBatchesDetails({
        statusFiltersOptions: [...value],
      })
    );
  }, 300);

  const handleChange = (e) => {
    updateFilter(e);
  };

  return (
    <Typography.Text className="status-filters-wrapper">
      <Select
        mode="multiple"
        className="status-filters-input"
        placeholder="Status"
        onChange={handleChange}
        options={filtersOptions}
        placement="bottomRight"
        allowClear={false}
        autoClearSearchValue={true}
        // maxTagCount={1}
        // maxTagTextLength={10}
        maxTagCount="responsive"
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        value={statusFiltersOptions}
      />
    </Typography.Text>
  );
};

export default memo(StatusFilters);
