import { memo, useState } from "react";
import "./FiltersRow.scss";
import NameFilter from "../Filters/NameFilter";
import { OrderedListOutlined, TableOutlined } from "@ant-design/icons";
import { Button, Divider, Tooltip, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { pushBatchesDetails } from "../../../ReduxComponents/Reducers/BatchesReducer";
import StatusFilters from "../Filters/StatusFilters";
import { useUpdateCSM } from "../../../Api/BatchesApi";
import useLogout from "../../../hooks/useLogout";

const FiltersRow = () => {
  const {
    batchesReducer: { viewType },
    userReducer: { selectedCompanies },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { logout } = useLogout();
  const [loading, setLoading] = useState(false);
  const { mutate: updateCsmFunction } = useUpdateCSM(onSuccess, onError);
  function onSuccess(data) {
    setLoading(false);
    notification.success({
      message: "SUCCESS",
      description: "FlixStock team has been informed",
    });
  }
  function onError(err) {
    setLoading(false);
    console.log(err);
    if (err?.response?.status == 401) {
      logout();
    }
  }
  const handleViewType = (type) => {
    dispatch(
      pushBatchesDetails({
        viewType: type,
      })
    );
  };

  return (
    <div className="filters-row-wrapper">
      <Button
        type="default"
        loading={loading}
        onClick={() => {
            setLoading(true);
            updateCsmFunction({ companyCode: selectedCompanies?.companyCode })
          }
        }
      >
        I Have Reviewed the Images
      </Button>
      <Divider type="vertical" style={{ height: "2rem" }} />
      <StatusFilters />
      <Divider type="vertical" style={{ height: "2rem" }} />
      <NameFilter />
      <Divider type="vertical" style={{ height: "2rem" }} />
      <div className="grid-list">
        {viewType === "list" ? (
          <Tooltip title="List View" placement="topLeft">
            <Button
              type="text"
              className="grid-list-button"
              onClick={() => handleViewType("grid")}
            >
              <OrderedListOutlined />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Grid View" placement="topLeft">
            <Button
              type="text"
              className="grid-list-button"
              onClick={() => handleViewType("list")}
            >
              <TableOutlined />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default memo(FiltersRow);
