import React from "react";
import { AutoSizer, List } from "react-virtualized";
import SkusThumbnail from "./SkusThumbnail";
import { Empty } from "antd";
import "react-virtualized/styles.css";

const SkuGroupView = ({
  groupedData,
  imageFlag,
  batchRef,
  dynamicHW,
  isDownloadOpen,
  handleDownloadList,
  toBeDownloaded,
  showHires,
  scrollToIndex,
  NumberofBatchCells,
}) => {
  const rowRenderer = ({ index, key, style }) => {
    return (
      <div
        key={key}
        style={{
          ...style,
          paddingLeft: "5px",
          // padding: "8px auto",
          borderBottom: "1px solid #afafaf",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            marginTop: "5px",
          }}
          key={showHires}
        >
          {groupedData[index]?.data.map((objData, i) => (
            // <div
            //   className="single-thumbnail-folder"
            //   style={{
            //     width: dynamicHW?.width + "px",
            //     height: dynamicHW?.height + "px",
            //   }}
            //   key={key}
            // >
            <SkusThumbnail
              data={objData}
              key={key}
              style={style}
              fileIndex={index}
              dynamicHW={dynamicHW}
              isDownloadOpen={isDownloadOpen}
              handleDownloadList={handleDownloadList}
              selectedForDownload={toBeDownloaded}
              showHires={showHires}
            />
            // </div>
            // <img
            //   key={i}
            //   src={imageUrl}
            //   alt={`Image ${i + 1}`}
            //   style={{
            //     margin: "0 5px",
            //     ...imageCellSizeAndPositionGetter({ index, imageIndex: i }),
            //   }}
            // />
          ))}
        </div>
      </div>
    );
  };

  const imageCellSizeAndPositionGetter = ({ index, imageIndex }) => {
    const columnCount = 7;
    const rowPadding = 0;

    const imageWidth = dynamicHW?.width;
    const imageHeight = dynamicHW?.height - 12;

    const columnIndex = columnCount === 1 ? 0 : imageIndex % columnCount;
    const rowIndex = Math.floor(imageIndex / columnCount);

    const x = columnIndex * imageWidth;
    const y = rowIndex * (imageHeight + rowPadding);

    return {
      height: imageHeight,
      width: imageWidth,
      transform: `translate(${x}px, ${y}px)`,
    };
  };

  const calculateRowHeight = (rowData, containerWidth) => {
    const { index } = rowData;
    const numImages = groupedData[index]?.data.length;
    const imageHeight = dynamicHW?.height || 50;
    const rowPadding = 10;
    const imagesPerRow = Math.floor(containerWidth / dynamicHW?.width);

    const numRows = Math.ceil(numImages / imagesPerRow);
    return numRows * imageHeight;
  };

  return (
    <AutoSizer key={JSON.stringify(dynamicHW)}>
      {({ height, width }) => (
        <List
          // key={showHires}
          width={width}
          height={height}
          rowCount={groupedData?.length}
          rowHeight={(d) => calculateRowHeight(d, width)}
          rowRenderer={rowRenderer}
          cellSizeAndPositionGetter={imageCellSizeAndPositionGetter}
          noContentRenderer={() => (
            <Empty
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "Center",
                alignItems: "center",
              }}
            />
          )}
          scrollToIndex={scrollToIndex}
        />
      )}
    </AutoSizer>
  );
};

export default SkuGroupView;
