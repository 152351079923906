import { useMutation, useQuery } from "@tanstack/react-query";
import { openRequest, request, requestFlix } from "../utils/axios-utils";
import { method } from "lodash";

//----------REGISTER FORM----------
const getClients = () => {
  return request({
    url: "/company/getAllCompanies?pageNum=1&pageSize=10",
    method: "GET",
  });
};

const getAllClients = () => {
  return request({
    url: "/company/getAllCompaniesForSelection",
    method: "GET",
  });
};

const getOrdersSummary = async (
  pageNum,
  pageSize,
  companyId,
  searchValue,
  statusFiltersOptions
) => {
  const { data } = await request({
    url: `/company/getOrdersSummary`,
    method: "GET",
    params: {
      pageNum,
      pageSize,
      companyId,
      searchName: searchValue,
      statusFilter: JSON.stringify([...statusFiltersOptions]),
    },
  });
  return { data: data?.data, total: data?.total };
};

export const useGetOrderSummary = (
  pageNum,
  pageSize,
  companyId,
  searchValue,
  statusFiltersOptions
) =>
  useQuery(
    [
      "OrdersSummary",
      pageNum,
      pageSize,
      companyId,
      searchValue,
      statusFiltersOptions,
    ],
    () =>
      getOrdersSummary(
        pageNum,
        pageSize,
        companyId,
        searchValue,
        statusFiltersOptions
      ),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

export const useGetAllClient = (onSuccess) => {
  return useQuery(["allClients"], getAllClients, {
    onSuccess: onSuccess ? onSuccess : null,
    // onError: onError,
    select: (data) => data.data,
  });
};

export const useGetClients = (onSuccess) => {
  return useQuery(["clients"], getClients, {
    onSuccess: onSuccess ? onSuccess : null,
    // onError: onError,
    select: (data) => data.data,
  });
};

const registerUser = (data) => {
  return request({ url: `/users/register`, method: "PUT", data });
};

export const useRegisterUser = (onSuccess, onError) => {
  return useMutation((data) => registerUser(data), {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError ? onError : null,
  });
};

// ----------LOGIN----------

const loginUser = (data) => {
  return request({ url: `/users/loginWithEmail`, method: "POST", data });
};

export const useLoginUser = (onSuccess, onError) => {
  return useMutation((data) => loginUser(data), {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError ? onError : null,
  });
};

const forgotPasswordRequest = (data) => {
  return request({ url: `/users/forgetPassword`, method: "PUT", data });
};

export const useForgotPassword = (onSuccess, onError) => {
  return useMutation((data) => forgotPasswordRequest(data), {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError ? onError : null,
  });
};

const resetPasswordRequest = (data) => {
  return request({ url: `/users/resetPassword`, method: "PUT", data });
};

export const useResetPassword = (onSuccess, onError) => {
  return useMutation((data) => resetPasswordRequest(data), {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError ? onError : null,
  });
};

//--------------------------------------------------- BATCHFILE(CANVAS) API ----------------------------------------------------//

const getBatchFile = ({ queryKey }) => {
  console.log("querky", queryKey);
  return request({
    url: `/files/getFileForCanvas?fileId=${queryKey[0]}`,
    method: "GET",
  });
};

export const useGetBatchFile = (onSuccess, id) => {
  return useQuery(id, getBatchFile, {
    onSuccess: onSuccess ? onSuccess : null,
    // onError: onError,
    enabled: false,
    refetchOnWindowFocus: false,
    select: (data) => data.data,
  });
};

const updateComment = (data) => {
  return request({
    url: "files/editMarking",
    method: "PUT",
    data: data,
  });
};
export const useUpdateComment = (onSuccess, onError) => {
  return useMutation((data) => updateComment(data), {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError ? onError : null,
  });
};

//-------------------------------------------- BATCH FILE ACTIONS APIS -----------------------------------------//

const rejectFile = (data) => {
  return request({ url: "/files/reject", method: "POST", data });
};

export const useRejectFile = (onSuccess, onError) => {
  return useMutation((data) => rejectFile(data), {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError ? onError : null,
  });
};

const deleteMarking = (data) => {
  return request({ url: "/files/deleteMarking", method: "PUT", data });
};

export const useDeleteMarking = (onSuccess, onError) => {
  return useMutation((data) => deleteMarking(data), {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError ? onError : null,
  });
};

const acceptRejectCanvasFile = (data) => {
  return request({
    url: `/files/${data?.type}`,
    method: "PUT",
    data: { fileId: data?.fileId },
  });
};

export const useAcceptRejectCanvasFile = (onSuccess, onError) => {
  return useMutation((data) => acceptRejectCanvasFile(data), {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError ? onError : null,
  });
};

//------------------------------------ BATCH PAGE LISTING --------------------------------------------------------------//

const getStatusList = () => {
  return openRequest({
    url: "/filters/getFileStatusFilters",
    method: "GET",
  });
};
export const useStatusFetcher = (onSuccess, onError) => {
  return useQuery(["status"], getStatusList, {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError,
    enabled: false,
    refetchOnWindowFocus: false,
    retry: (count, err) => {
      if (count == 1 && err.response.status == 401) {
        return false;
      }
    },
    select: (data) => data.data,
  });
};

const makeAcceptAll = (data) => {
  return request({
    url: `/files/acceptAll`,
    method: "PUT",
    data: data,
  });
};
export const useAcceptAll = (onSuccess, onError) => {
  return useMutation((data) => makeAcceptAll(data), {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError ? onError : null,
  });
};

//------------------------------------ DOWNLOAD IMAGE API ---------------------------------------------------//

const downloadImages = (data) => {
  return requestFlix({
    url: `/fxstudioPiiZip`,
    method: "POST",
    data: data,
  });
};
export const useDownloadImages = (onSuccess, onError) => {
  return useMutation((data) => downloadImages(data), {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError ? onError : null,
  });
};
