import React from "react";
import { Modal } from "antd";
import "./modal.scss";

const DynamicModal = (props) => {
  return (
    <Modal
      open={props.open}
      closable={props.closable ? props.closable : false}
      onCancel={props.close}
      footer={null}
      width={props.width}
      title={props.title}
      bodyStyle={{ height: props.height, overflow: props.overflow ?? "scroll" }}
      className={props.customClass ?? "dynamicModal"}
      wrapClassName={props.wrapClassName ?? ""}
    >
      {props.component && props.component}
    </Modal>
  );
};

export default DynamicModal;
