import { createSlice, current } from "@reduxjs/toolkit";
import states from "../states";

const batchesReducer = createSlice({
	name: "batchesReducer",
	initialState: {
		...states.BatchesDetail,
	},
	reducers: {
		pushBatchesDetails: (state, action) => {
			let tempState = { ...current(state) };
			let returnableState = {
				...tempState,
				...action?.payload,
			};
			return { ...returnableState };
		},
	},
});

export const { pushBatchesDetails } = batchesReducer.actions;
export default batchesReducer;
