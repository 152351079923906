import { createSlice, current } from "@reduxjs/toolkit";
import states from "../states";

const spinnerReducer = createSlice({
  name: "spinnerReducer",
  initialState: {
    ...states.spinnerStateDetails,
  },
  reducers: {
    pushPullSpinnerArray: (state, action) => {
      let tempState = { ...current(state) };
      let returnableState = {
        ...tempState,
        spinnerObject: {
          ...tempState.spinnerObject,
          ...action.payload,
        },
      };
      return { ...returnableState };
    },

    pushPullSpinnerArrayRemoveItem: (state, action) => {
      let tempState = { ...current(state) };
      let currentSpinnerObject = { ...tempState?.spinnerObject };
      delete currentSpinnerObject[action?.payload];
      let returnableState = {
        ...tempState,
        spinnerObject: {
          ...currentSpinnerObject,
        },
      };
      return { ...returnableState };
    },
    pushPullSpinnerArrayClearAll: (state, action) => {
      let tempState = { ...current(state) };
      let currentSpinnerObject = { ...tempState?.spinnerObject };
      delete currentSpinnerObject[action?.payload];
      let returnableState = {
        ...tempState,
        spinnerObject: {},
      };
      return { ...returnableState };
    },
  },
});

export const {
  pushPullSpinnerArray,
  pushPullSpinnerArrayRemoveItem,
  pushPullSpinnerArrayClearAll,
} = spinnerReducer.actions;
export default spinnerReducer;
