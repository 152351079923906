import React from "react";
import { Routes, Route } from "react-router-dom";
import { routesList } from "./pageRoutes";

const Router = () => {
	return (
		<Routes>
			{routesList.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						exact={route.exact}
						element={
							route.provider ? (
								<route.provider>
									<route.component />
								</route.provider>
							) : (
								<route.component />
							)
						}
					></Route>
				);
			})}
		</Routes>
	);
};

export default Router;
