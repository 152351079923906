const getFormattedData = (at) => {
	let dt = new Date(at);
	return `${dt.toLocaleDateString("en-US", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	})}`;
};

const getDataAndTime = (at) => {
	let dt = new Date(at);
	return `${dt.toLocaleDateString()} - ${dt.toLocaleTimeString()}`;
};

const colorScheme = {
	600: {
		color: "#8CABFF",
		sign: "exclamation",
	},
	606: {
		color: "#8EAC50",
		sign: "check",
	},
	608: {
		color: "#E76161",
		sign: "cross",
	},
	700: {
		color: "orange",
		sign: "exclamation",
	},
	706: {
		color: "green",
		sign: "check",
	},
	708: {
		color: "maroon",
		sign: "cross",
	},
};

export { getFormattedData, getDataAndTime, colorScheme };
