import { memo, useEffect } from "react";
import Header from "../../commonComponents/BatchesHeader/header";
import "./Batch.scss";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import FilesList from "./FilesList/FilesList";
import FilePreview from "./FilePreview/FilePreview";
import { Modal, message } from "antd";
import DynamicModal from "../../commonComponents/DynamicModal/DynamicModal";
import Stats from "./Stats/Stats";
import { useDispatch, useSelector } from "react-redux";
import useSpinner from "../../hooks/useSpinner";
import { pushBatchDetails } from "../../ReduxComponents/Reducers/BatchReducer";
import spinnerMessages from "../../utils/spinner-messages";
import states from "../../ReduxComponents/states";
import { useFetchBatchInfo, useFetchBatchList } from "../../Api/BatchesApi";
import { useStatusFetcher } from "../../Api";
import useFourNotOne from "../../hooks/useFourNotOne";
import useLogout from "../../hooks/useLogout";

const Batch = () => {
  const {
    totalCount,
    currentPage,
    currentPageSize,
    batchId,
    searchName,
    batchList,
    statusFiltersOptions,
    selectedFiltersList,
    isRefetchBatch,
  } = useSelector((state) => state.batchReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const { dispatchPullItem, dispatchPushItem, disptachClearAll } = useSpinner();
  const { fourNotOne } = useFourNotOne();
  const { logout } = useLogout();
  const onSuccessBatchApi = async (data) => {
    const groupData = await groupHandler(data?.data?.files, "identity");
    let finalData = [];
    if (!!groupData) {
      finalData = Object.keys(groupData).map((n, index) => {
        return {
          groupNo: `Group ${index + 1}`,
          data: groupData[n],
        };
      });
    }
    dispatch(
      pushBatchDetails({
        totalCount: data?.data?.total,
        batchList: [...data?.data?.files],
        isRefetchBatch: false,
        batchGroupedData: [...finalData],
      })
    );
    dispatchPullItem("loadingFilesList");
    fetchBatchInfo();
  };

  const onErrorBatchApi = (error) => {
    console.log(error);
    fourNotOne(error);
    message.error(error?.response?.data?.message || "Oops! Please try again");
    dispatchPullItem("loadingFilesList");
  };

  const onSuccessBatchInfoApi = (data) => {
    let statusDict = data?.data?.statusDict;
    // let reverseDict = {};
    // for (let key in statusDict) {
    // 	reverseDict = { ...reverseDict, [statusDict[key]]: key };
    // }
    dispatch(
      pushBatchDetails({
        // statusDict: reverseDict,
        batchName: data?.data?.batchInfo?.batchName || "",
        batchStats: data?.data?.batchInfo?.stats || [],
        totalStatsCount: data?.data?.batchInfo?.totalFiles,
      })
    );
  };

  const onErrorBatchInfoApi = (error) => {
    console.log(error);
    fourNotOne(error);
    message.error(error?.response?.data?.message || "Oops! Please try again");
  };

  const { refetch: fetchBatch, isLoading: isFetchingBatchList } =
    useFetchBatchList(onSuccessBatchApi, onErrorBatchApi, [
      currentPageSize,
      currentPage,
      batchId,
      statusFiltersOptions,
      searchName,
      selectedFiltersList,
    ]);

  const { refetch: fetchBatchInfo } = useFetchBatchInfo(
    onSuccessBatchInfoApi,
    onErrorBatchInfoApi,
    batchId
  );
  const { refetch: getDropDownStatus, isError } = useStatusFetcher(
    onStatusGet,
    onStatusFail
  );

  function onStatusGet(data) {
    dispatch(pushBatchDetails({ statusFilters: data?.filters }));
  }
  function onStatusFail(err) {
    console.log(err);
  }

  useEffect(() => {
    if (!location?.search) {
      // if (batchId && !!location?.state?.batchReducer) {
      // 	dispatch(
      // 		pushBatchDetails({
      // 			...location?.state?.batchReducer,
      // 		})
      // 	);
      // } else {
      if (!batchId) {
        let batchId = location.pathname.split("/")[2];
        if (batchId) {
          dispatch(
            pushBatchDetails({
              batchId,
            })
          );
        }
      }

      // }
    }
  }, [location]);

  useEffect(() => {
    dispatchPullItem("loadBatch");
    dispatchPushItem({
      loadingFilesList: { ...spinnerMessages?.loadingFilesList },
    });
    if (batchId) {
      fetchBatch();
    }
  }, [
    currentPageSize,
    currentPage,
    batchId,
    statusFiltersOptions?.length,
    searchName,
    selectedFiltersList,
  ]);

  useEffect(() => {
    if (batchId) {
      fetchBatchInfo();
    }
  }, [batchId, location]);

  useEffect(() => {
    if (isRefetchBatch) {
      fetchBatch();
    }
  }, [isRefetchBatch]);

  useEffect(() => {
    getDropDownStatus();
    try {
      let resolutionFlag = localStorage.getItem("showHires");
      let resolutionObj = JSON.parse(resolutionFlag);
      let groupOption = JSON.parse(localStorage.getItem("groupBy"));

      if (
        resolutionFlag &&
        resolutionObj?.batch &&
        !!groupOption &&
        groupOption?.batch &&
        groupOption?.batch != "null"
      ) {
        dispatch(
          pushBatchDetails({
            showHiresBatch: resolutionObj.batch,
            batchGroupBy: groupOption?.batch,
          })
        );
      } else if (resolutionFlag && !!resolutionObj?.batch) {
        dispatch(pushBatchDetails({ showHiresBatch: resolutionObj?.batch }));
      } else if (
        !!groupOption &&
        groupOption?.batch &&
        groupOption?.batch != "null"
      ) {
        dispatch(pushBatchDetails({ batchGroupBy: groupOption?.batch }));
      }
    } catch (err) {
      logout();
      disptachClearAll();
    }

    return () => {
      const initialState = states?.BatchDetail;
      dispatch(
        pushBatchDetails({
          ...initialState,
        })
      );
    };
  }, []);
  function groupHandler(array, key) {
    function sortObjectKeys(obj) {
      if (typeof obj !== "object" || obj === null) {
        return obj;
      }

      if (Array.isArray(obj)) {
        return obj.map(sortObjectKeys);
      }

      return Object.keys(obj)
        .sort()
        .reduce((sortedObj, k) => {
          sortedObj[k] = sortObjectKeys(obj[k]);
          return sortedObj;
        }, {});
    }

    return array.reduce((result, obj) => {
      const sortedObj = sortObjectKeys(obj[key]);
      const keyValue = JSON.stringify(sortedObj);
      (result[keyValue] = result[keyValue] || []).push(obj);
      return result;
    }, {});
  }

  const componentsRenderer = () => {
    const queryParams = queryString.parse(location.search);

    if (!!queryParams?.id) {
      return (
        <DynamicModal
          open={!!queryParams?.id}
          width={"99.4vw"}
          component={<FilePreview />}
          height={"100vh"}
          customClass={"previewModal"}
          wrapClassName="previewModal_wrap"
        />
      );
    } else {
      return (
        <>
          <Header title="Batch" />
          <Stats />
          <FilesList />
        </>
      );
    }
  };

  return <div className="batch-wrapper">{componentsRenderer()}</div>;
};

export default memo(Batch);
