import { memo, useEffect, useState } from "react";
import "./dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import { Select, Typography } from "antd";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";

const StatusFilters = ({
	statusFiltersOptions,
	setStatusFiltersOptions,
	statusFilters,
}) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [filtersOptions, setFiltersOptions] = useState([]);

	useEffect(() => {
		if (statusFilters?.length > 0) {
			let newArr = statusFilters?.map((elm) => {
				return { label: elm?.key, value: elm?.value };
			});

			setFiltersOptions(newArr);
		}
	}, [statusFilters]);

	const updateFilter = debounce((value) => {
		setStatusFiltersOptions([...value]);
	}, 300);

	const handleChange = (e) => {
		updateFilter(e);
	};

	return (
		<Typography.Text className="status-filters-wrapper">
			<Select
				mode="multiple"
				className="status-filters-input"
				placeholder="Status"
				onChange={handleChange}
				options={filtersOptions}
				placement="bottomRight"
				allowClear={false}
				autoClearSearchValue={true}
				// maxTagCount={1}
				// maxTagTextLength={10}
				maxTagCount="responsive"
				filterOption={(input, option) =>
					(option?.label ?? "")
						.toLowerCase()
						.includes(input.toLowerCase())
				}
				value={statusFiltersOptions}
			/>
		</Typography.Text>
	);
};

export default memo(StatusFilters);
