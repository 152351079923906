import { Button, Form, Input, InputNumber, Select, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import "./registerStyle.scss";
import DefaultButton from "../../../commonComponents/buttons/button";
import { useNavigate } from "react-router-dom";
import { useGetClients, useRegisterUser, useGetAllClient } from "../../../Api";
import useFourNotOne from "../../../hooks/useFourNotOne";
import useLogout from "../../../hooks/useLogout";

const RegisterForm = () => {
	const [selectedClients, setSelectedClients] = useState([]);
	const [contact, setContact] = useState({
		countryCode: "+1",
		phoneNumber: "",
	});
	const navigate = useNavigate();
	const { fourNotOne } = useFourNotOne();
	const { logout } = useLogout();
	const user = JSON.parse(localStorage.getItem("current_user"));

	const { data: clients, isLoading, isError, error } = useGetAllClient();
	const { mutate: userRegister } = useRegisterUser(
		onRegisterDone,
		onRegisterFail
	);

	function onRegisterDone(data) {
		console.log("sucess", data);
		navigate("/pending");
	}
	function onRegisterFail(err) {
		fourNotOne(err);
		message.error(err?.response?.data?.message);
		console.log("error", err);
	}

	const handleCancel = () => {
		localStorage.removeItem("current_user");
		localStorage.removeItem("token");
		// navigate("/login");
		// logout();
		window.location.href = "/";
	};

	const contactHandler = (val, name) => {
		let newVal = val;
		if (name == "countryCode") {
			newVal = newVal?.replace(/[^0-9+]/g, "");
		} else {
			newVal = val?.replace(/^\d{11}$/, "");
		}
		setContact({ ...contact, [name]: newVal });
	};

	const handleChange = (list, data) => {
		let newData = data?.map((elm) => {
			return {
				company: elm?.value,
				companyName: elm?.label?.split("-")[0],
				companyCode: elm?.label?.split("-")[1],
			};
		});
		setSelectedClients(newData);
	};
	const selectAllClients = () => {
		let allData = clients?.companies?.map((el) => {
			return {
				company: el?._id,
				companyName: el?.name,
				companyCode: el?.code,
			};
		});
		setSelectedClients(allData);
	};

	const handleFinish = (data) => {
		let finalData = {
			contactNumber: { ...contact },
			name: data?.name,
			designation: data?.designation,
			ACL: selectedClients,
		};
		userRegister(finalData);
	};

	return (
		<div className="registerForm w-100">
			<Form
				name="register"
				layout="vertical"
				autoComplete="off"
				onFinish={(data) => handleFinish(data)}
				initialValues={{ name: user }}
			>
				<Form.Item name="name" label="Name">
					<Input disabled />
				</Form.Item>
				<Form.Item
					name="contact number"
					label="Contact Number"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Space.Compact className="w-100">
						<Input
							style={{
								width: "20%",
							}}
							name="countryCode"
							defaultValue="+1"
							value={contact.countryCode}
							onChange={(e) =>
								contactHandler(e.target.value, e.target.name)
							}
						/>
						<Input
							style={{
								width: "80%",
							}}
							name="phoneNumber"
							value={contact.phoneNumber}
							onChange={(e) =>
								contactHandler(e.target.value, "phoneNumber")
							}
						/>
					</Space.Compact>
				</Form.Item>
				<Form.Item
					name="designation"
					label="Designation"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item name="client" label="Clients">
					<Space className="w-100 clients_selector">
						<Select
							mode="multiple"
							allowClear
							style={{
								width: "100%",
							}}
							value={selectedClients.map((item) => item.company)}
							onChange={handleChange}
							options={clients?.companies?.map((elm) => {
								return {
									label: `${elm?.name}-${elm?.code}`,
									value: elm?._id,
								};
							})}
							optionFilterProp="label"
						/>
						<DefaultButton
							text="Select All"
							clickHandler={selectAllClients}
						/>
					</Space>
				</Form.Item>

				<Form.Item>
					<Space>
						<DefaultButton
							text="Submit"
							htmlType="submit"
							type="primary"
						/>
						<DefaultButton
							text={"Cancel"}
							clickHandler={handleCancel}
						/>
					</Space>
				</Form.Item>
			</Form>
		</div>
	);
};

export default RegisterForm;
