import { memo, useEffect, useRef, useState } from "react";
import "./FilePreview.scss";
import BDCrumb from "../../../commonComponents/BatchesBreadcrumb/breadcrumb";
import CanvasInstance from "../CanvasInstance/CanvasInstance";
import AnnotationTools from "../AnnotationTools/AnnotationTools";
import {
  Button,
  Modal,
  Skeleton,
  Slider,
  Space,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import InstanceLegacy from "../CanvasInstance/InstanceLegacy";
import BatchCarousel from "../BatchCarousel/BatchCarousel";
import FileAction from "../FileActions/FileAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAcceptRejectCanvasFile,
  useDeleteMarking,
  useGetBatchFile,
} from "../../../Api";
import { useDispatch, useSelector } from "react-redux";
import { updateFileDetails } from "../../../ReduxComponents/Reducers/FileReducer";
import { pushBatchDetails } from "../../../ReduxComponents/Reducers/BatchReducer";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import DynamicDrawer from "../../../commonComponents/DynamicDrawer/DynamicDrawer";
import LeftDrawer from "../LeftDrawer/LeftDrawer";
import queryString from "query-string";
import useFourNotOne from "../../../hooks/useFourNotOne";
import { useUpdateBatchDetail } from "../../../hooks/useUpdateBatchDetails";
import { pushSkusDetails } from "../../../ReduxComponents/Reducers/SkusReducer";
import { getFullDate } from "../../../utils/date.util";
import DynamicModal from "../../../commonComponents/DynamicModal/DynamicModal";
import ConfirmModal from "../ConfirmModal";

const FilePreview = (props) => {
  const [shouldShowHorizontalLine, setShouldShowHorizontalLine] =
    useState(false);
  const [currentMarkingsCount, setCurrentMarkingsCount] = useState(0);
  const [addingQuote, setAddingQuote] = useState(false);
  const [showAnnotations, setShowAnnotations] = useState(true);
  const [restrictComments, setRestrictComments] = useState(false);
  const [currentPath, setCurrentPath] = useState(
    "HDM_Jewellery_Batch_7_client_qc_27072023_185436/final/1/hk2007-7-11_4.jpg"
  );
  const [markColor, setMarkColor] = useState("#FF007F");
  const [rangeValue, setRangeValue] = useState(50);
  const [activeItem, setActiveItem] = useState({ index: 0 });
  const [loadedFlag, setLoadedFlag] = useState(false);
  const [fileData, setFileData] = useState({});
  const [carouselData, setCarouselData] = useState([]);
  const [leftCarouselData, setLeftCarouselData] = useState([]);
  const [isLeftDrawerOpen, setLeftDrawerOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const commentRef = useRef(null);
  const deadZoneRef2 = useRef({
    flag: false,
    type: "",
  });
  const deleteRef2 = useRef("");
  const markingSameRef = useRef(true);
  const markArrRef = useRef([]);
  let params = new URLSearchParams(location.search);
  let batchId = params.getAll("id");
  //----
  const [confirmFlag, setConfirmFlag] = useState(false);
  const openConfirm = () => setConfirmFlag(true);
  const closeConfirm = () => setConfirmFlag(false);
  const [skuNum, setSkuNum] = useState(0);
  const [waitForNewBatch, setWaitForNewBatch] = useState(false);
  const closeFetcher = () => setWaitForNewBatch(false);

  const dispatch = useDispatch();
  const {
    fileReducer: {
      activeFile,
      file,
      versions,
      commentList,
      markingData,
      markingList,
      extraImagesRawData,
      isActionable,
    },
    skusReducer: {
      skusList,
      totalCount: skuTotalCount,
      currentPage: skusCurrentPage,
      currentPageSize: skusCurrentPageSize,
    },
    batchReducer: {
      activeBatchFileId,
      batchList,
      totalCount,
      currentPage,
      currentPageSize,
    },
  } = useSelector((state) => state);
  const [oldMarkings, setOldMarkings] = useState(markingList);
  const { fourNotOne } = useFourNotOne();
  const { updateBatchDetails, updateSiblings } = useUpdateBatchDetail();
  const { mutate: deleteComments } = useDeleteMarking(
    commentDeleteSuccess,
    commentDeleteFail
  );

  const activeIndex = (
    location.pathname.includes("skus") ? skusList : batchList
  )?.findIndex((item) => item?._id == activeBatchFileId);

  let hasMoreData = location.pathname.includes("skus")
    ? skuTotalCount > skusCurrentPage * skusCurrentPageSize
    : totalCount > currentPage * currentPageSize;

  // let isMarkingSame = markingList.every((elm) =>
  //   oldMarkings.find((item) => item == elm)
  // );
  let isMarkingSame = markingList.every((elm) =>
    oldMarkings.some((item) => {
      const { toggleOn, ...restItem } = item;
      const { toggleOn: _, ...restElm } = elm;
      return JSON.stringify(restItem) === JSON.stringify(restElm);
    })
  );
  const { refetch, isError, error, isFetching } = useGetBatchFile(
    fetchedBatchFile,
    batchId
  );

  const onSuccessActionAcceptReject = async (data) => {
    // if()  // comfirm close
    if (
      deadZoneRef2.current.type !== "close" &&
      deleteRef2.current != "close"
    ) {
      let file = data?.data?.file;
      dispatch(
        updateFileDetails({
          file,
          isActionable: false,
        })
      );

      if (data.data.siblings && data.data.siblings.length) {
        let allList = [...data.data.siblings];
        allList.push(file);
        await updateSiblings(allList);
      } else {
        await updateBatchDetails(file);
      }

      // await setDeadZoneFlag({flag:false, type:''})
      deleteRef2.current = "rejected";
      if (location.pathname.includes("skus")) {
        if (activeIndex < skusList?.length - 1) {
          arrowHandler(
            deadZoneRef2.current.type == "back" ? -1 : +1,
            undefined,
            "sucapp sku"
          );
        } else if (activeIndex == skusList?.length - 1 && hasMoreData) {
          fetchNewbatch();
        } else if (activeIndex == skusList?.length - 1 && !hasMoreData) {
          handleClose();
        }
      } else {
        if (activeIndex < batchList?.length - 1) {
          arrowHandler(
            deadZoneRef2.current.type == "back" ? -1 : +1,
            undefined,
            "succapp"
          );
        } else if (activeIndex == batchList?.length - 1 && hasMoreData) {
          fetchNewbatch();
        } else if (activeIndex == batchList?.length - 1 && !hasMoreData) {
          handleClose();
        }
      }
    } else if (
      (deadZoneRef2.current.flag && deadZoneRef2.current.type == "close") ||
      deleteRef2.current == "close"
    ) {
      let file = data?.data?.file;
      dispatch(
        updateFileDetails({
          file,
          isActionable: false,
        })
      );
      updateBatchDetails(file);
      handleModalClose();
    }
  };
  const onErrorActionAcceptReject = (err) => {
    console.log(err);
    fourNotOne(err);
    message.error(err?.response?.data?.message || "Oops, Please try again!");
  };
  const { mutate: acceptRejectCanvasFile } = useAcceptRejectCanvasFile(
    onSuccessActionAcceptReject,
    onErrorActionAcceptReject
  );

  function fetchedBatchFile(data) {
    let findActiveVersion = data?.versions?.find((item) => item?.defaultView);
    let finalVersion = { ...findActiveVersion };
    finalVersion["imageToShow"] = findActiveVersion?.versionImage;
    finalVersion["isOriginal"] = true;

    let newVersions = data?.versions?.map((el) => {
      return { ...el, imageToShow: el?.versionImage };
    });
    let newFile = { ...data?.file };
    newFile["markings"] = data?.file?.latestMarkings;
    setFileData(data);
    setOldMarkings(data?.file?.latestMarkings);
    dispatch(
      updateFileDetails({
        file: newFile,
        isActionable: data?.isCanvasActionable,
        versions: newVersions,
        siblings: data?.siblings,
        activeFile: { ...finalVersion, index: 0, posType: "siblings" },
        selectedVersion: finalVersion?._id,
        markingList: data?.file?.latestMarkings.map((e) => {
          return { ...e, toggleOn: true };
        }),
        rejectedReasonList: data?.rejectionReasons,
        // annotationToolsFlag:data?.file?.latestMarkings.length>0
      })
    );
    setActiveItem({ ...finalVersion, index: 0, posType: "siblings" });
    // let newObj = JSON.stringify(findActiveVersion)
    // let finalObj = JSON.parse(newObj)
    // finalObj.versionImage.imagePath.url = "https://d3vbtuhy8rknzz.cloudfront.net/flixit%2FTSX%2FBST%287%29_21-July-2023_ClientQC%2F2000150088536_detail_4_final_bi0ll0v3wti.jpg"
    let newList = data?.siblings?.map((e) => {
      return {
        ...e,
        imageToShow: e?.latestImage,
      };
    });
    newList.unshift(finalVersion);
    setCarouselData(newList);

    let rawNewList = data?.rawImages?.map((e) => {
      return {
        imageToShow: e,
      };
    });

    if (data?.supportingImages?.length > 0) {
      let supportList = data?.supportingImages?.map((e) => {
        return {
          imageToShow: { ...e?.image, imageType: e?.imageType },
        };
      });
      // rawNewList?.concat(supportList);
      rawNewList = [...rawNewList, ...supportList];
      // console.log(rawNewList, "hii ");
    }
    setLeftCarouselData(rawNewList);
    setLoadedFlag(true);
    initialize();
  }
  const addHorizontalLine = (e) => {
    if (e == 4) {
      setShouldShowHorizontalLine(true);
      setRangeValue(50);
    } else {
      setShouldShowHorizontalLine(false);
    }
  };

  const initialize = () => {};

  const addEvents = () => {
    window.addEventListener("keydown", globalEvents);
  };

  useEffect(() => {
    initialize();
    addEvents();
    return () => {
      window.removeEventListener("keydown", globalEvents, false);
      dispatch(
        updateFileDetails({
          commentList: [],
          markingData: {},
        })
      );
    };
  }, [props?.selectedFile, activeFile]);

  useEffect(() => {
    refetch();
    dispatch(pushBatchDetails({ activeBatchFileId: batchId[0] }));
    setLoadedFlag(false);
  }, [location]);
  useEffect(() => {
    if (waitForNewBatch) {
      let arr = location.pathname.includes("skus") ? skusList : batchList;
      arrowHandler(0, skuNum > 0 ? arr.length - 1 : 0, "useBact");
    }
  }, [batchList, skusList]);

  useEffect(() => {
    if (
      file?.stage?.toLowerCase()?.includes("accepted") &&
      markingList.length > 0
    ) {
      // setDeadZoneFlag({ flag: true, type:'' });
      deadZoneRef2.current = { flag: true, type: "" };
    }
    markArrRef.current = markingList;
  }, [file, markingList]);

  useEffect(() => {
    markingSameRef.current = isMarkingSame;
  }, [isMarkingSame]);
  const setSimilarImages = () => {};

  const handleSnacks = (toast) => {
    // const { enqueueSnackbar } = this.props;
    // const props = {
    // 	variant: toast.variant,
    // 	anchorOrigin: {
    // 		horizontal: "right",
    // 		vertical: "top",
    // 		autoHideDuration: 2000,
    // 		transitionDuration: 700,
    // 	},
    // };
    // enqueueSnackbar(toast.message, props);
  };

  const updateCommentLength = (comment, number) => {
    // console.log('check updateCommentLength', comment, "line", number, "length", comment.length)
    // await this.setState({ currentMarkingsCount: comment.length });

    setCurrentMarkingsCount(comment?.length);
    dispatch(updateFileDetails({ markingList: comment, type: number }));
    // console.log('prorr 22222', comment)
  };

  const removeEvents = (value) => {
    setAddingQuote(value);
    window.addEventListener("keydown", globalEvents);
  };

  const navRight = () => {};

  const navLeft = () => {};

  const setFileStatus = (value) => {
    if (
      !isActionable ||
      (file?.stage?.toLowerCase()?.includes("reject") &&
        (isMarkingSame || markingList.length < oldMarkings.length))
    ) {
      message.error("This is already in rejected state");
    } else if (
      !isActionable ||
      (markingList.length && !isActionable) ||
      file?.stage?.toLowerCase()?.includes("accept")
    ) {
      message.error("This is already in accepted state");
    } else {
      handleAcceptRejectButton(value);
    }
  };
  const arrowHandler = async (type, newFile, call) => {
    if (
      !markingSameRef.current &&
      deleteRef2.current == "" &&
      !(
        markArrRef.current.length < oldMarkings.length &&
        (file?.stage?.toLowerCase()?.includes("rejected") ||
          file?.stage?.toLowerCase()?.includes("unreviewed"))
      )
    ) {
      openConfirm();
      deleteRef2.current = type == -1 ? "backArrow" : "nextArrow";
    } else if (deadZoneRef2.current.flag) {
      deadZoneRef2.current = { flag: false, type: "back" };
      handleAcceptRejectButton("reject");
      // setDeadZoneFlag({flag:false, type:'arrows'})
    } else {
      dispatch(
        pushBatchDetails({
          activeBatchFileId: (location.pathname.includes("skus")
            ? skusList
            : batchList)[newFile ?? activeIndex + type]?._id,
        })
      );
      if (location.pathname.includes("skus")) {
        navigate(
          `${location?.pathname}?batch=${
            skusList[newFile ?? activeIndex + type]?.batch
          }&id=${skusList[newFile ?? activeIndex + type]?._id}`,
          { replace: true }
        );
      } else {
        navigate(
          `${location?.pathname}?id=${
            batchList[newFile ?? activeIndex + type]?._id
          }`,
          { replace: true }
        );
      }
      deadZoneRef2.current = { flag: false, type: "" };
    }

    closeFetcher();
  };
  const handleAcceptRejectButton = (type) => {
    let fileId = queryString.parse(location.search)?.id;
    if (deadZoneRef2.current.type == "back") {
      deadZoneRef2.current = { ...deadZoneRef2, type: "" };
    }
    if (
      deadZoneRef2.current.flag &&
      file?.stage?.toLowerCase()?.includes("accepted") &&
      type == "accept"
    ) {
      deadZoneRef2.current = { ...deadZoneRef2, flag: false };
    }

    if (type == "reject" && markArrRef.current.length == 0) {
      message.error("Please add markings before rejecting.");
    } else if (fileId) {
      const obj = {
        type,
        fileId,
      };
      acceptRejectCanvasFile(obj);

      deleteRef2.current = type;
    }
  };
  const handleModalClose = () => {
    if (location.pathname.includes("skus")) {
      dispatch(
        pushSkusDetails({
          scrollToIndex: activeIndex,
        })
      );
    } else {
      dispatch(pushBatchDetails({ scrollToIndex: activeIndex }));
    }
    navigate(`${location?.pathname}`, {});
  };
  const handleClose = () => {
    if (
      markingList.length < oldMarkings.length &&
      file?.stage?.toLowerCase()?.includes("rejected")
    ) {
      handleModalClose();
    } else if (!isMarkingSame) {
      openConfirm();
      deleteRef2.current = "close";
    } else if (
      file?.stage?.toLowerCase()?.includes("accepted") &&
      markingList.length > 0
    ) {
      handleAcceptRejectButton("reject");
      // setDeadZoneFlag({ ...deadZoneFlag, type: "close" });
      deadZoneRef2.current = { ...deadZoneRef2.current, type: "close" };
    } else {
      handleModalClose();
    }
  };
  const fetchNewbatch = (type) => {
    if (location.pathname.includes("skus")) {
      dispatch(
        pushSkusDetails({
          currentPage: skusCurrentPage + (type ?? 1),
        })
      );
    } else {
      dispatch(
        pushBatchDetails({
          currentPage: currentPage + (type ?? 1),
        })
      );
    }

    setWaitForNewBatch(true);
    if (location.pathname.includes("skus")) {
      setSkuNum(type ? skusList?.length - 1 : 0);
    } else {
      setSkuNum(type ? batchList?.length - 1 : 0);
    }
  };
  const confirmCloser = () => {
    closeConfirm();
    deleteRef2.current = "";
  };
  const handleIgnore = () => {
    let deleteObj = {
      fileId: file?._id,
      markingId: [],
    };
    if (!isMarkingSame && file?.stage?.toLowerCase()?.includes("rejected")) {
      deleteObj.markingId = markingList
        .filter(
          (obj2) => !oldMarkings.some((obj1) => areObjectsEqual(obj1, obj2))
        )
        .map((elm) => elm?.id); // Find objects in array2 that are not in array1
    } else if (
      (!isMarkingSame && file?.stage?.toLowerCase()?.includes("unreviewed")) ||
      file?.stage?.toLowerCase()?.includes("accepted")
    ) {
      deleteObj.markingId = markingList.map((item) => item.id);
    }
    deleteComments(deleteObj);
  };
  function areObjectsEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  function commentDeleteSuccess() {
    if (deleteRef2.current == "close") {
      handleModalClose();
    } else if (deleteRef2.current.toLowerCase().includes("arrow")) {
      deadZoneRef2.current = { ...deadZoneRef2, flag: false };
      arrowHandler(
        deleteRef2.current.toLowerCase().includes("back") ? -1 : +1,
        undefined,
        "commdel"
      );
      deleteRef2.current = "";
    }
    closeConfirm();
  }
  function commentDeleteFail(err) {
    console.log(err);
  }

  const globalEvents = (e) => {
    const key = e.key;

    if (addingQuote) return;
    if (
      document.activeElement ==
        commentRef?.current?.resizableTextArea?.textArea ||
      commentRef.current.hasOwnProperty("scrollTo")
    )
      return;
    switch (key) {
      case "ArrowRight":
        (activeIndex ==
          (location.pathname.includes("skus") ? skusList : batchList).length -
            1 ||
          activeIndex < 0) &&
        !hasMoreData
          ? navRight()
          : activeIndex ==
              (location.pathname.includes("skus") ? skusList : batchList)
                .length -
                1 && hasMoreData
          ? fetchNewbatch()
          : arrowHandler(+1);
        break;
      case "ArrowLeft":
        activeIndex <= 0 &&
        (location.pathname.includes("skus")
          ? skusCurrentPage == 1
          : currentPage == 1)
          ? navLeft()
          : (
              activeIndex == 0 && location.pathname.includes("skus")
                ? skusCurrentPage > 1
                : currentPage > 1
            )
          ? fetchNewbatch(-1)
          : arrowHandler(-1);
        break;
      case "y":
        setFileStatus("accept");
        break;
      case "n":
        setFileStatus("reject");
        break;
      case "Y":
        setFileStatus("accept");
        break;
      case "N":
        setFileStatus("reject");
        break;
      default:
        break;
    }
  };

  const handleSetShowAnnotations = (value = false) => {
    setShowAnnotations(value);
  };

  const changeMarkColor = (e) => {
    e.preventDefault();
    setMarkColor(e.target.value);
  };

  const updateSlider = (e) => {
    setRangeValue(e);
  };
  const handleActiveItem = (data, index, posType) => {
    setActiveItem({ ...data, index, posType });
    dispatch(updateFileDetails({ activeFile: { ...data, index, posType } }));
  };
  const getMarkState = (data) => {
    dispatch(updateFileDetails({ markingData: data }));
  };
  return (
    <div className="file-preview-container">
      {/* <div className="preview-header">
				<BDCrumb title="Preview" />
			</div> */}
      {loadedFlag ? (
        <div className="canvas-container-preview">
          <div className="canvas-left">
            <Tooltip title="Show Raw Images" placement="topRight">
              <Button
                type="text"
                style={{ padding: 0 }}
                onClick={() => {
                  setLeftDrawerOpen(true);
                }}
              >
                <CaretRightOutlined style={{ margin: 0 }} />
              </Button>
            </Tooltip>
          </div>
          <div className="canvas-top">
            <div className="preview-images-block">
              <div
                className="preview-instances-images"
                // key={JSON.stringify(activeFile) + currentMarkingsCount}
              >
                <InstanceLegacy
                  key="64c2860340846900196e1448"
                  id="64c2860340846900196e1448"
                  left={{
                    _id: activeFile?._id ?? "",
                    name: "hk2007-7-11_1.jpg",
                    awsKey: activeFile?.versionImage?.imagePath?.key,
                    awsKeyThumb: activeFile?.versionImage?.thumbPath?.key,
                    path: " ",
                    createdAt: "2023-07-27T14:58:08.086Z",
                    fileStatus: "notDeclared",
                    fileType: "original",
                    skuId: "64c2703c1081a3091417a4e9",
                    projectLots: {
                      lots: [],
                    },
                    awsRetrivePath: activeFile?.imageToShow?.imagePath?.url,
                    loading: false,
                    fileIndex: activeFile.index,
                  }}
                  right={{
                    _id: file?._id,
                    name: "hk2007-7-11_1.jpg",
                    awsKey: file?.latestImage?.imagePath?.key,
                    awsKeyThumb: file?.latestImage?.thumbPath?.key,
                    path: "",
                    createdAt: file?.createdAt,
                    fileStatus: "notDecalred" ?? file?.status, //status
                    fileType: "final",
                    skuId: file?.skuId, //identity
                    projectLots: {
                      lots: [],
                    },
                    awsRetrivePath: file?.latestImage?.imagePath?.url,
                    loading: false,
                  }}
                  projectId={batchId[0]}
                  markColor={markColor}
                  showAnnotations={showAnnotations}
                  restrictComments={restrictComments}
                  currentPath={currentPath}
                  changeMarkColor={changeMarkColor}
                  setShowAnnotations={handleSetShowAnnotations}
                  addEvents={addEvents}
                  removeEvents={removeEvents}
                  setSimilarImages={setSimilarImages}
                  handleSnacks={handleSnacks}
                  addHorizontalLine={addHorizontalLine}
                  updateCommentLength={updateCommentLength}
                  loadedFlag={loadedFlag}
                  getMarkState={getMarkState}
                  markingList={markingList}
                  rightData={file}
                  leftData={activeFile}
                  currentMarkingsCount={currentMarkingsCount}
                  commentRef={commentRef}
                />
                {shouldShowHorizontalLine ? (
                  <div className="slidecontainer">
                    <Slider
                      vertical
                      min={1}
                      max={100}
                      value={rangeValue}
                      id="myRange"
                      onChange={updateSlider}
                      className="slider"
                      reverse
                      trackStyle={{
                        backgroundColor: "transparent",
                      }}
                      railStyle={{
                        backgroundColor: "transparent",
                        width: "100%",
                      }}
                      tooltip={{
                        formatter: null,
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="canvas-bottom">
            <div className="rejection-comments-container">
              <BatchCarousel
                data={carouselData}
                handleActiveItem={handleActiveItem}
                activeItem={activeFile}
              />
            </div>
            <div className="similar-images-container">
              <FileAction
                arrowHandler={arrowHandler}
                handleAcceptRejectButton={handleAcceptRejectButton}
                handleModalClose={handleModalClose}
                handleClose={handleClose}
                fetchNewbatch={fetchNewbatch}
                confirmCloser={confirmCloser}
                handleIgnore={handleIgnore}
              />
            </div>
            <div></div>
            {file?.hasOwnProperty("userActionInfo") ? (
              <Space
                direction="vertical"
                className="file_details_info"
                align="end"
              >
                <Typography.Text strong style={{ fontSize: "0.7rem" }}>
                  Last Updated
                </Typography.Text>
                <Tooltip title={file?.userActionInfo?.userEmail}>
                  <Typography.Text italic>
                    {file?.userActionInfo?.userName}
                  </Typography.Text>
                </Tooltip>
                {/* <Typography.Text italic>{file?.userActionInfo?.userEmail}</Typography.Text> */}
                <Typography.Text italic>
                  {getFullDate(file?.userActionInfo?.at)}
                </Typography.Text>
              </Space>
            ) : (
              <div style={{ width: "175px" }}></div>
            )}
          </div>
        </div>
      ) : (
        <Skeleton active size="large" className="mt-4 px-4" />
      )}

      {isLeftDrawerOpen && (
        <DynamicDrawer
          open={isLeftDrawerOpen}
          // closable={true}
          close={() => setLeftDrawerOpen(false)}
          width={80}
          className=""
          maskClosable={true}
          placement={"left"}
          style={{ padding: 0 }}
          extra={null}
        >
          <div
            style={{
              position: "absolute",
              left: 65,
              top: "40%",
              background: "rgba(221, 221, 221, 0.475)",
              cursor: "pointer",
              zIndex: 1,
            }}
            onClick={() => {
              setLeftDrawerOpen(false);
            }}
          >
            <Button
              type="text"
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setLeftDrawerOpen(false);
              }}
            >
              <CaretLeftOutlined style={{ margin: 0 }} />
            </Button>
          </div>
          <LeftDrawer
            rawImagesList={leftCarouselData}
            handleActiveItem={handleActiveItem}
            activeItem={activeFile}
          />
        </DynamicDrawer>
      )}
      {confirmFlag && (
        <DynamicModal
          open={confirmFlag}
          component={
            <ConfirmModal
              handleAcceptRejectButton={handleAcceptRejectButton}
              handleIgnore={handleIgnore}
            />
          }
          close={() => confirmCloser()}
          closable
        />
      )}
    </div>
  );
};

export default memo(FilePreview);
