import "./BatchesList.scss";
import { Button, Table } from "antd";
import { redirect, useNavigate } from "react-router";
import CommonTable from "../../../commonComponents/Table/table";
import { getFormattedData } from "../../../utils/extra-utils";
import { useSelector } from "react-redux";
import useSpinner from "../../../hooks/useSpinner";
import spinnerMessages from "../../../utils/spinner-messages";
import folderSvg from "../../../assets/images/folder.png";

const TabelView = ({
	batchesListData,
	selectedBatch,
	setSelectedBatch,
	pageNum,
	pageSize,
}) => {
	const navigate = useNavigate();
	const batchesReducer = useSelector((state) => state.batchesReducer);
	const { dispatchPushItem } = useSpinner();

	const HandleActionClick = (data) => {
		dispatchPushItem({
			loadBatch: { ...spinnerMessages.loadBatch },
		});
		navigate(`/batches/${data}`, { state: { batchesReducer } });
	};

	const columns = [
		{
			title: "Sr No",
			dataIndex: "name",
			key: "name",
			render: (text, _, index) => (
				<a>{(pageNum - 1) * pageSize + index + 1}</a>
			),
		},
		{
			title: "",
			key: "image",
			render: () => (
				<img
					alt="example"
					src={folderSvg}
					style={{
						height: "60px",
						width: "100%",
						objectFit: "contain",
					}}
				/>
			),
		},
		{
			title: "Batch Name",
			dataIndex: "batchName",
			key: "batchName",
			align: "center",
		},
		{
			title: "Created At",
			dataIndex: "createdAt",
			key: "createdAt",
			align: "center",
			render: (_) => getFormattedData(_),
		},
		{
			title: "Status",
			dataIndex: "stage",
			key: "stage",
			align: "center",
			// render: (_) => getFormattedData(_),
		},
		{
			title: "Action",
			key: "action",
			render: (_, record, index) => {
				return (
					<Button
						type="dashed"
						onClick={() => HandleActionClick(_?._id)}
					>
						View Files
					</Button>
				);
			},
			align: "center",
		},
	];

	return (
		<div
			style={{
				width: "100%",
			}}
		>
			<Table
				columns={columns}
				dataSource={batchesListData}
				pagination={false}
				rowKey="_id"
				onRow={(record, rowIndex) => {
					return {
						onClick: () => {
							console.log({ record });
							setSelectedBatch(record?._id);
						},
					};
				}}
				rowSelection={{
					selectedRowKeys: [selectedBatch],
					type: "radio",
					hideSelectAll: true,
					onChange: (selectedRowKeys) => {
						console.log("selection", selectedRowKeys);
						setSelectedBatch([...selectedRowKeys]);
					},
					getCheckboxProps: (record) => ({
						style: { display: "none" },
					}),
				}}
			/>
		</div>
	);
};

export default TabelView;
