// import GridForm from "./GridForm";

import { TableOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { memo, useState } from "react";
import DraggableModal from "./DraggableModal";
import GridForm from "../GridForm/GridForm";
import "./GridComponent.scss";

const GridComponent = ({ gridData, updateGrid }) => {
	const [open, setOpen] = useState(false);
	return (
		<React.Fragment>
			<Button
				type="text"
				aria-label="gridline on/off"
				onClick={() => setOpen(true)}
				title="Grid lines"
			>
				<TableOutlined />
			</Button>
			<DraggableModal
				open={open}
				onClose={() => setOpen(false)}
				title={"Custom Grid"}
			>
				<GridForm formData={gridData} changeData={updateGrid} />
			</DraggableModal>
		</React.Fragment>
	);
};

export default memo(GridComponent);
