import React from "react";
import AliceCarousel from "react-alice-carousel";
import "./carousel.scss";
import "react-alice-carousel/lib/alice-carousel.css";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationOutlined,
  LeftOutlined,
  RightOutlined,
  StarTwoTone,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import { colorScheme } from "../../../utils/extra-utils";
import { fill } from "lodash";

const BatchCarousel = ({ data, handleActiveItem, activeItem }) => {
  const responsive = {
    0: { items: 1 },
    568: { items: 4 },
    1024: { items: 4 },
    1300: { items: 4 },
  };

  const getSignFromSwitch = (sign) => {
    switch (sign) {
      case "exclamation":
        return (
          <ExclamationOutlined
            style={{
              width: "70%",
              height: "70%",
              margin: 0,
              color: "white",
              fontWeight: "bolder",
              verticalAlign: "center",
            }}
            className="flex-center"
          />
        );
      case "check":
        return (
          <CheckOutlined
            style={{
              width: "70%",
              height: "70%",
              margin: 0,
              color: "white",
              fontWeight: "bolder",
              verticalAlign: "center",
            }}
            className="flex-center"
          />
        );
      case "cross":
        return (
          <CloseOutlined
            style={{
              width: "70%",
              height: "70%",
              margin: 0,
              color: "white",
              fontWeight: "bolder",
              verticalAlign: "center",
            }}
            className="flex-center"
          />
        );
      default:
        return <></>;
    }
  };

  const getSign = (data) => {
    const dataStatus = data?.status;
    const { color, sign } = colorScheme[dataStatus];

    return (
      <div className="starIcon_siblings">
        <span
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: color,
            display: "flex",
            justifyContent: "center",
            borderRadius: "50%",
            alignItems: "center",
          }}
        >
          <Tooltip title={data?.stage} placement="topLeft">
            {getSignFromSwitch(sign)}
          </Tooltip>
        </span>
      </div>
    );
  };

  const thumbs = () => {
    return data.map((elm, index) => (
      <Tooltip trigger={"hover"} title={elm?.filename ?? ""}>
        <div
          className={`thumb_item item ${
            activeItem?.posType === "siblings" && activeItem?.index == index
              ? "active_cItem"
              : ""
          }`}
          onClick={() => handleActiveItem(elm, index, "siblings")}
        >
          {elm.isOriginal ? (
            <StarTwoTone className="starIcon" twoToneColor="#FFC816" />
          ) : (
            getSign(elm)
          )}
          <img
            src={elm?.imageToShow?.thumbPath?.url}
            alt={"img" + index}
            className="carousel_img"
          />
        </div>
      </Tooltip>
    ));
  };
  const handleArrows = (type) => {
    let index = activeItem.index;
    if (type == "next" && index < data.length - 1) {
      index += 1;
    } else if (type == "prev" && index > 0) {
      index -= 1;
    }
    handleActiveItem({ ...data[index] }, index, "siblings");
  };
  return (
    <div
      className={`batch-carousel w-100 flex-center ml-4 ${
        activeItem?.posType !== "siblings" ? "activeSibling" : ""
      }`}
    >
      {/* <div
        className={`flex-center custom_arrows ${
          activeItem.index == 0 ? "disable_arrow" : ""
        }`}
        onClick={() => handleArrows("prev")}
      >
        <LeftOutlined />
      </div>
      <AliceCarousel
        // className='w-50'
        items={thumbs()}
        activeIndex={
          (activeItem?.posType === "siblings" && activeItem?.index) || null
        }
        responsive={responsive}
        disableDotsControls
        disableButtonsControls
        key={activeItem}
        // paddingRight={"50px"}
        // autoWidth
      />
      <div
        className={`flex-center custom_arrows ${
          activeItem.index == data.length - 1 ? "disable_arrow" : ""
        }`}
        onClick={() => handleArrows("next")}
      >
        <RightOutlined />
      </div> */}
      <div className="sibligCarousel flexAlign-center">{thumbs()}</div>
    </div>
  );
};

export default BatchCarousel;
