const spinnerMessages = {
	loadingBatchesList: {
		message: "Please wait, We are fetching the details!",
	},
	loadBatch: {
		message: "Loading...",
	},
	loadingFilesList: {
		message: "Please wait, We are fetching the details!",
	},
	loadingskusList: {
		message: "Please wait, We are fetching the details!",
	},
};

export default spinnerMessages;
