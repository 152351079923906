import { Switch, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pushSkusDetails } from "../ReduxComponents/Reducers/SkusReducer";
import { pushBatchDetails } from "../ReduxComponents/Reducers/BatchReducer";

const ResolutionSwitch = ({ type }) => {
  const {
    skusReducer: { showHires },
    batchReducer: { showHiresBatch },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleSwitch = (e) => {
    let tempObj = JSON.parse(localStorage.getItem("showHires")) ?? {};
    switch (type) {
      case "sku":
        {
          localStorage.setItem(
            "showHires",
            JSON.stringify({
              ...tempObj,
              sku: e,
            })
          );
          dispatch(pushSkusDetails({ showHires: e }));
        }
        break;
      case "batch": {
        localStorage.setItem(
          "showHires",
          JSON.stringify({ ...tempObj, batch: e })
        );
        dispatch(pushBatchDetails({ showHiresBatch: e }));
      }
      default:
        return false;
    }
  };

  return (
    <>
      <Tooltip title="Turn ON toggle to view high resolution thumbnails">
        <label>Hi-Res:</label>
        <Switch
          className="ml-1"
          checkedChildren="on"
          unCheckedChildren="off"
          checked={type == "sku" ? showHires : showHiresBatch}
          onClick={(e) => handleSwitch(e)}
        />
      </Tooltip>
    </>
  );
};

export default ResolutionSwitch;
