import { memo } from "react";
import "./batchManager.scss";
import Batch from "../../Components/Batch/Batch";

const BatchManager = () => {
	return (
		<div className="batch-wrapper-container">
			<Batch />
		</div>
	);
};

export default memo(BatchManager);
