import "./App.scss";
import "./assets/scss/style.scss";
import "./assets/scss/customStyles.scss";
import LoginScreen from "./pages/login/loginScreen";
import GlobalSpinner from "./commonComponents/GlobalSpinner";
// import Batchfiles from "./pages/batchesManager/batchesManager";
import Layout from "./commonComponents/Layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateUser } from "./ReduxComponents/Reducers/UserReducer";
import RegisterForm from "./Components/Access/RegisterForm/RegisterForm";

const token = localStorage.getItem("token");
function App() {
	const dispatch = useDispatch();
	const {
		userReducer: { isError, errorMessage, isLoggedIn, token: reducerToken },
	} = useSelector((state) => state);

	useEffect(() => {
		if (token) {
			const local_user = JSON.parse(localStorage.getItem("local_user"));
			if (!!local_user) {
				const selectedCompanies = JSON.parse(
					sessionStorage.getItem("selectedCompanies")
				);
				if (selectedCompanies) {
					local_user["selectedCompanies"] = { ...selectedCompanies };
				}
				dispatch(updateUser(local_user));
			}
		}
	}, []);
	return (
		<div className="App">
			<GlobalSpinner>
				{reducerToken ? <Layout /> : <LoginScreen />}
				{/* <RegisterForm /> */}
			</GlobalSpinner>
		</div>
	);
}

export default App;
