import { memo, useCallback, useEffect, useRef, useState } from "react";
import { rejectionsReasonsList } from "../../../Constants";
import { useLocation } from "react-router-dom";
import pencil from "../../../assets/images/icons/pencil.png";
import rectangle from "../../../assets/images/icons/rectangle.png";
import oval from "../../../assets/images/icons/oval.png";
import {
  AimOutlined,
  BorderVerticleOutlined,
  InfoCircleTwoTone,
  MoreOutlined,
} from "@ant-design/icons";
import {
  Affix,
  Button,
  Descriptions,
  Drawer,
  Empty,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
  message,
  notification,
} from "antd";
import "./AnnotationTools.scss";
import TextArea from "antd/es/input/TextArea";
import DefaultButton from "../../../commonComponents/buttons/button";
import { useDispatch, useSelector } from "react-redux";
import { updateFileDetails } from "../../../ReduxComponents/Reducers/FileReducer";
import CommentCard from "../Comments/CommentCard";
import { useDeleteMarking, useUpdateComment } from "../../../Api";
import DynamicModal from "../../../commonComponents/DynamicModal/DynamicModal";
import { type } from "@testing-library/user-event/dist/type";
import { copyToClipboard } from "../../../utils/files-utils";
import KeyboardModal from "../KeyboardModal/KeyboardModal";
import { useUpdateBatchDetail } from "../../../hooks/useUpdateBatchDetails";
import { SketchPicker } from "react-color";
import useEyeDropper from "use-eye-dropper";
import _ from "lodash";

const apis = {};
const AnnotationTools = (props) => {
  const location = useLocation();
  const { open, close, isSupported } = useEyeDropper();
  const [markColor, setMarkColor] = useState("#FF007F");
  const [metaOn, setMetaOn] = useState(false);
  const [metaModalOn, setMetaModalOn] = useState(false);
  const [openKeyModal, setOpenKeyModal] = useState(false);
  const [editFile, setEditFile] = useState({ flag: false });
  const [runEyeDropper, setRunEyeDropper] = useState(false);
  const [color, setColor] = useState("#fff");
  const [error, setError] = useState();
  const [colorPicked, setColorPicked] = useState({
    hex: "",
    rgb: "",
  });
  const [activeColorType, setActiveColorType] = useState("hex");
  const [commentDetails, setCommentDetails] = useState({
    rejectionReasonMain: null,
    specificReason: null,
    comment: "",
  });

  const {
    fileReducer: {
      commentList,
      markingData,
      markingList,
      file,
      isActionable,
      rejectedReasonList,
      annotationToolsFlag,
      metaSwitch,
    },
  } = useSelector((state) => state);
  const { mutate } = useDeleteMarking(onDeletedSuccess, onDeleteFail);
  const { mutate: updateNewComment } = useUpdateComment(
    onSuccessUpdate,
    onErrorUpdate
  );
  const dispatch = useDispatch();
  const { updateBatchDetails } = useUpdateBatchDetail();
  function onDeleteFail(err) {
    console.log(err);
  }
  async function onDeletedSuccess(data) {
    let newFile = { ...data?.data?.file };

    newFile["markings"] = data?.data?.file?.latestMarkings;
    props.updateMark(data?.data?.file?.latestMarkings);

    await dispatch(
      updateFileDetails({
        file: newFile,
        markingList: data?.data?.file?.latestMarkings,
      })
    );
    updateBatchDetails(newFile);
  }

  async function onSuccessUpdate(data) {
    let newFile = { ...data?.data?.file };

    newFile["markings"] = data?.data?.file?.latestMarkings;
    props.updateMark(data?.data?.file?.latestMarkings);

    await dispatch(
      updateFileDetails({
        file: newFile,
        markingList: data?.data?.file?.latestMarkings,
      })
    );
    updateBatchDetails(newFile);
    props.updateCommentLength(data?.data?.file?.latestMarkings, "666");
    setEditFile({ flag: false });
    setCommentDetails({
      rejectionReasonMain: null,
      specificReason: null,
      comment: "",
    });
  }
  function onErrorUpdate(err) {
    console.log(err);
  }
  const inputHandler = (value, name) => {
    setCommentDetails({ ...commentDetails, [name]: value });
  };
  const handleAddComment = () => {
    if (
      commentDetails.specificReason == null ||
      commentDetails.rejectionReasonMain == null ||
      commentDetails.specificReason == "" ||
      commentDetails.rejectionReasonMain == ""
    ) {
      message.warning("Please add rejection reasons");
    } else if (editFile.flag) {
      let updateObj = {
        markingId: editFile.markId,
        errorComment:
          commentDetails.rejectionReasonMain +
          "," +
          commentDetails.specificReason +
          "," +
          commentDetails.comment,
        fileId: props.fileId,
      };
      updateNewComment(updateObj);
    } else {
      let commentObj = {
        ...commentDetails,
        id: `${markingData.imgId}-${commentList.length}`,
        markings: markingData,
      };
      dispatch(
        updateFileDetails({ commentList: [...commentList, commentObj] })
      );
      setCommentDetails({
        rejectionReasonMain: null,
        specificReason: null,
        comment: "",
      });
      props.commentSubmitHandler({
        mainReason: commentDetails.rejectionReasonMain,
        subReason: commentDetails.specificReason,
        error:
          commentDetails.rejectionReasonMain +
          "," +
          commentDetails.specificReason +
          "," +
          commentDetails.comment,
      });
    }
  };
  const handleCancel = () => {
    if (editFile.flag) {
      setEditFile({ flag: false });
      setCommentDetails({
        rejectionReasonMain: null,
        specificReason: null,
        comment: "",
      });
    } else {
      props.toggleComment(false);
      setCommentDetails({
        rejectionReasonMain: null,
        specificReason: null,
        comment: "",
      });
      dispatch(updateFileDetails({ commentList: [] }));
    }
  };

  const commentDeleteHandler = (id) => {
    let tempList = commentList.filter((item) => item.id !== id);
    //  dispatch(updateFileDetails({commentList:tempList}))
    let deleteObj = {
      fileId: file?._id,
      markingId: id,
    };
    mutate(deleteObj);
  };

  const getRejectionsMainReason = () => {
    let optionsList = [];
    if (rejectedReasonList?.length) {
      optionsList = rejectedReasonList.map((item) => {
        return {
          label: item?.reason,
          value: item?.reason,
        };
      });
    }

    return optionsList;
  };

  const getRejectionsSpecificReason = () => {
    let optionsList = [];
    if (rejectedReasonList?.length && !!commentDetails?.rejectionReasonMain) {
      let specificReasonList =
        rejectedReasonList.filter(
          (item) => item?.reason === commentDetails?.rejectionReasonMain
        )[0]?.subReasons || [];

      optionsList = specificReasonList?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    }
    optionsList = _.uniqBy(optionsList, "value");
    return optionsList;
  };
  const renderMetaDetails = (type) => {
    let data = file?.metadata;

    if (data && Object?.keys(data)?.length > 0) {
      return (
        <Descriptions
          column={type == "modal" ? 3 : 1}
          size={type != "modal" ? "small" : "default"}
          className="py-1"
        >
          {Object?.keys(data)?.map((elm) => {
            if (typeof data[elm] == "string") {
              return (
                <Descriptions.Item
                  label={elm}
                  contentStyle={{ fontSize: "0.8rem" }}
                >
                  {data[elm]}
                </Descriptions.Item>
              );
            }
            // else if(typeof  data[elm] == 'object') {
            // 	Object.keys(data[elm]).map((iem))
            // }
          })}
        </Descriptions>
      );
    } else {
      return <Empty className={type == "modal" ? "itemCenter" : ""} />;
    }
  };

  const handleOpenMetaModal = (e) => {
    e.stopPropagation();
    setMetaModalOn(true);
  };
  const handleToggle = (data) => {
    props.updateMark({
      markId: data,
      hide: true,
    });
  };

  const handleMetaSwitch = (val) => {
    dispatch(updateFileDetails({ metaSwitch: val }));
    localStorage.setItem("metaFlag", val);
    setMetaOn(val);
  };
  const handleEdit = (data) => {
    let commentArr = data.error.split(",");
    setCommentDetails({
      rejectionReasonMain: data.rejectionReasonMain,
      specificReason: data.specificReason,
      comment: commentArr[commentArr.length - 1],
    });
    setEditFile({
      flag: true,
      markId: data.id,
      _id: data._id,
    });
    const container = document.getElementById("underMetaBox"); // Replace 'container' with your container's id
    const scrollPosition =
      props.commentRef.current.offsetTop - container.offsetTop;

    // Scroll to the textarea
    container.scrollTop = scrollPosition;

    // Focus on the textarea
    document.getElementById("commentText").focus();
  };

  useEffect(() => {
    let metaFlag = localStorage.getItem("metaFlag");
    if (metaFlag) {
      setMetaOn(JSON.parse(metaFlag));
    }
  }, []);

  const handlePickColor = () => {
    // if (colorPickerRef.current) {
    //   colorPickerRef.current.click();
    // }
    setRunEyeDropper(true);
  };

  const handleColorChange = (event) => {
    const pickedColor = event.target.value;

    const rgbColor = hexToRgb(pickedColor);
    setColorPicked({
      hex: pickedColor,
      rgb: rgbColor,
    });
  };

  // Function to convert HEX color to RGB
  const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, "");

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgb(${r}, ${g}, ${b})`;
  };
  const pickColor = useCallback(() => {
    // Using async/await (can be used as a promise as-well)
    const openPicker = async () => {
      try {
        const color = await open();
        const rgb = await hexToRgb(color.sRGBHex);
        setColorPicked({ hex: color.sRGBHex, rgb });
      } catch (e) {
        console.log(e);
        // Ensures component is still mounted
        // before calling setState
        if (!e.canceled) setError(e);
      }
    };
    openPicker();
  }, [open]);
  return (
    <div className="tool-container-main">
      <div className="tool-bar">
        <ul className={`tool-bar-ul ${!isActionable ? "disable_view" : ""}`}>
          <li className="tool-bar-item">
            <input
              className="color-picker tool_icons"
              type="color"
              value={props.markColor}
              onChange={props.changeMarkColor}
              // style={{ height: "25px", width: "25px" }}
            />
          </li>
          <li className="tool-bar-item">
            <Button
              type="text"
              onClick={() => props.setMarkTool(3)}
              id={props.markTool === 3 ? "toolbar-button-active" : undefined}
              className="annotation_btns"
            >
              <img
                src={pencil}
                alt="pencil"
                // style={{ height: "25px", width: "25px" }}
                className="tool_icons"
              />
            </Button>
          </li>
          <li className="tool-bar-item">
            <Button
              type="text"
              onClick={() => props.setMarkTool(1)}
              id={props.markTool === 1 ? "toolbar-button-active" : undefined}
              className="annotation_btns"
            >
              <img
                src={rectangle}
                alt="rectangle"
                // style={{ height: "25px", width: "25px" }}
                className="tool_icons"
              />
            </Button>
          </li>
          <li className="tool-bar-item">
            <Button
              type="text"
              onClick={() => props.setMarkTool(2)}
              id={props.markTool === 2 ? "toolbar-button-active" : undefined}
              className="annotation_btns"
            >
              <img
                src={oval}
                alt="oval"
                // style={{ height: "25px", width: "25px" }}
                className="tool_icons"
              />
            </Button>
          </li>
          <li className="tool-bar-item">
            <Button
              type="text"
              onClick={() => props.setMarkTool(0)}
              id={props.markTool === 0 ? "toolbar-button-active" : undefined}
              style={{ color: "#4384c9" }}
              className="annotation_btns"
            >
              <AimOutlined
                style={{ fontSize: "1.4rem" }}
                className="tool_icons"
              />
            </Button>
          </li>
          <li className="tool-bar-item">
            <Button
              type="text"
              onClick={() => props.setMarkTool(4)}
              id={props.markTool === 4 ? "toolbar-button-active" : undefined}
              style={{ color: "#4384c9" }}
              className="annotation_btns"
            >
              <BorderVerticleOutlined style={{ fontSize: "1.4rem" }} />
            </Button>
          </li>
        </ul>
      </div>

      <div className="tool-comment-bar">
        <div
          style={{
            textAlign: "end",
            height: "4vh",
            gap: "5px",
            justifyContent: "space-between",
          }}
          className="flexAlign-center"
        >
          <div>
            <img
              src="/images/dropperOutline.png"
              className="keyboardIcon"
              style={{ height: "3vh" }}
              onClick={pickColor}
            />
            {/* <input
              ref={colorPickerRef}
              type="color"
              style={{ display: "none" }}
              onChange={handleColorChange}
              id="pickerInput"
            /> */}
          </div>
          {colorPicked.hex !== "" && (
            <Space>
              <div
                style={{
                  height: "15px",
                  width: "15px",
                  backgroundColor: `${colorPicked.hex}`,
                  borderRadius: "2px",
                }}
              ></div>
              <Typography.Text
                strong
                style={{ cursor: "pointer" }}
                onClick={() =>
                  setActiveColorType(activeColorType == "hex" ? "rgb" : "hex")
                }
              >
                {colorPicked[activeColorType]}
              </Typography.Text>
            </Space>
          )}
          <img
            src="/images/keyboard3.png"
            className="keyboardIcon"
            onClick={() => setOpenKeyModal(true)}
          />
        </div>
        <hr />
        <div style={{ marginBottom: "5px" }}>
          <Space
            className="w-100 crumb"
            direction="vertical"
            style={{ gap: "2px" }}
          >
            <div className="flex-space_between">
              <Tooltip
                trigger={"hover"}
                placement="bottomLeft"
                title={file?.batchName ?? "NA"}
              >
                <span
                  className="cursor_pointer text-overflow"
                  style={{
                    maxWidth: "95%",
                    display: "inline-block",
                    fontSize: "0.7rem",
                  }}
                  onClick={() =>
                    copyToClipboard(file?.batchName ?? "NA", () => {
                      message.info("Copied!");
                    })
                  }
                >
                  {file?.batchName ?? "NA"}
                </span>
              </Tooltip>
              <Tooltip title="Details">
                <Button
                  size="small"
                  type="ghost"
                  onClick={(e) => handleOpenMetaModal(e)}
                  style={{ marginLeft: "auto", height: "20px" }}
                >
                  <InfoCircleTwoTone />
                </Button>
              </Tooltip>
            </div>

            <div className="flex-space_between">
              <Tooltip
                trigger={"hover"}
                placement="topLeft"
                title={file?.filename}
              >
                <span
                  style={{
                    maxWidth: "95%",
                    display: "inline-block",
                    fontSize: "0.7rem",
                  }}
                  className="text-overflow cursor_pointer"
                  onClick={() =>
                    copyToClipboard(file?.filename ?? "NA", () => {
                      message.info("Copied!");
                    })
                  }
                >
                  {file?.filename}
                </span>
              </Tooltip>

              <Tooltip
                title={
                  !(file?.metadata && Object?.keys(file?.metadata)?.length > 0)
                    ? "No Details Available"
                    : "Details"
                }
              >
                <Switch
                  className="ml-1"
                  size="small"
                  onChange={(val) => handleMetaSwitch(val)}
                  checked={metaOn}
                  disabled={
                    !(
                      file?.metadata && Object?.keys(file?.metadata)?.length > 0
                    )
                  }
                />
              </Tooltip>
            </div>
          </Space>
        </div>

        {metaOn && (
          <div className="metaInfo_box ">
            <Typography.Text strong>File Details</Typography.Text>
            <div style={{ height: "85%", overflowY: "auto" }}>
              {renderMetaDetails("inner")}
            </div>
          </div>
        )}

        <div
          id="underMetaBox"
          className={metaOn ? "underMetaBox" : "underMetaOff"}
        >
          <div className="comment-inputs-cont">
            <div className="flex-space_between">
              <Typography.Text strong>Add Comment</Typography.Text>
            </div>

            <Space
              direction="vertical"
              className="w-100 comment-editor-box "
              align="center"
            >
              <div className="card-data-spacing w-100" style={{ gap: 2 }}>
                <label>Main Reason</label>
                <Select
                  className="w-100"
                  // defaultValue="lucy"
                  placeholder="Select reason"
                  ref={props.commentRef}
                  value={commentDetails.rejectionReasonMain}
                  name="rejectionReasonMain"
                  onChange={(e) => inputHandler(e, "rejectionReasonMain")}
                  options={getRejectionsMainReason()}
                  disabled={!props.commentOn}
                  style={{ maxWidth: "13vw" }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </div>
              <div className="card-data-spacing w-100" style={{ gap: 2 }}>
                <label>SpecificReason</label>
                <Select
                  className="w-100"
                  name="specificReason"
                  ref={props.commentRef}
                  placeholder="Select Specific Reason"
                  value={commentDetails.specificReason}
                  onChange={(e) => inputHandler(e, "specificReason")}
                  options={getRejectionsSpecificReason()}
                  disabled={!props.commentOn}
                  style={{ maxWidth: "13vw" }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </div>
              <TextArea
                ref={props.commentRef}
                id="commentText"
                // showCount
                // maxLength={100}
                name="comment"
                //   style={{ height: 120, marginBottom: 24, resize: 'none',}}
                onChange={(e) => inputHandler(e.target.value, e.target.name)}
                autoSize={{ minRows: 2, maxRows: 4 }}
                placeholder="add comment"
                value={commentDetails.comment}
                disabled={!props.commentOn && !editFile.flag}
                style={{ maxWidth: "13vw" }}
              />
              <Space>
                <DefaultButton
                  text={editFile.flag ? "UPDATE" : "ADD"}
                  clickHandler={handleAddComment}
                  disabled={!props.commentOn && !editFile.flag}
                />
                <DefaultButton
                  text="CANCEL"
                  disabled={!props.commentOn && !editFile.flag}
                  clickHandler={handleCancel}
                />
              </Space>
            </Space>
          </div>
          <div
            className={`comments_container mt-1 card-data-spacing ${
              markingList && markingList.length == 0 ? "noHeightComment" : ""
            } `}
          >
            {markingList &&
              markingList.length > 0 &&
              markingList?.map((elm, index) => (
                <CommentCard
                  data={elm}
                  index={index}
                  deleteComment={commentDeleteHandler}
                  isActionable={isActionable}
                  handleToggle={handleToggle}
                  handleEdit={handleEdit}
                />
              ))}
          </div>
        </div>
      </div>
      {metaModalOn && (
        <DynamicModal
          open={metaModalOn}
          close={() => setMetaModalOn(false)}
          title="File Details"
          width="80%"
          height={file.metadata ? "auto" : "400px"}
          customClass="metaModal"
          component={renderMetaDetails("modal")}
        />
      )}
      {openKeyModal && (
        <DynamicModal
          open={openKeyModal}
          close={() => setOpenKeyModal(false)}
          title="Keyboard Shortcuts"
          width="40%"
          height={"500px"}
          customClass="keyBoardModal"
          bordered={true}
          closable
          component={<KeyboardModal />}
        />
      )}
    </div>
  );
};

export default memo(AnnotationTools);
