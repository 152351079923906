import axios from "axios";
import { baseUrl, flixUrl, pegagusBaseUrl, tempUrl } from "../Config";

export const request = async (options) => {
  const client = axios.create({
    baseURL: tempUrl,
    headers: {
      authorization: options.token || `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return client.request(options);
};

export const requestPegasus = async (options) => {
  const client = axios.create({
    baseURL: pegagusBaseUrl,
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return client.request(options);
};

export const openRequest = async (options) => {
  const client = axios.create({
    baseURL: tempUrl,
  });
  return client.request(options);
};

export const requestFlix = async (options) => {
  const client = axios.create({
    baseURL: flixUrl,
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return client.request(options);
};
