module.exports = {
  googleClientId:
    "282817292369-51qu03akc3kti0f7hvpli5gfs9gplf1q.apps.googleusercontent.com",
  calendlyLink: "https://calendly.com/d/z9h-mq7-wnw/flixstudio-demo",
  baseUrl: "https://api-pegasus.flixstudio.io/product/api/v1",
  pegagusBaseUrl: "https://api-pegasus.flixstudio.io/api/v1",
  // tempUrl: "http://localhost:7982/api/v1",
  tempUrl: "https://api-flixit.flixstock.com/api/v1",
  flixUrl: "https://api.flixstock.com",
};
