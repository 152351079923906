import { createAction, createSlice } from "@reduxjs/toolkit";
import states from "../states";

export const UserReducer = createSlice({
	name: "userReducer",
	initialState: { ...states?.userDetails },
	reducers: {
		updateUser(state, action) {
			return {
				...state,
				isLoggedIn: true,
				isLoading: false,
				isError: false,
				isSuccess: true,
				isInitiated: false,
				...action.payload,
			};
		},
		updateSelectedCompanies: (state, action) => {
			return { ...state, selectedCompanies: action.payload };
		},
	},
});

export const { updateUser, updateSelectedCompanies } = UserReducer.actions;
export default UserReducer;
