import { memo } from "react";
import "./batchesManager.scss";
import Batches from "../../Components/Batches/batches";

const BatchesManager = () => {
	return (
		<div className="batches-wrapper-container">
			<Batches />
		</div>
	);
};

export default memo(BatchesManager);
