import React, { useState } from "react";
import { KeyOutlined } from "@ant-design/icons";
import { Typography, Input, Space } from "antd";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import DefaultButton from "../../commonComponents/buttons/button";

const { Title, Text } = Typography;

const ResetPassword = ({
	handleSubmitResetPasswordRequest,
	handleCancelResetPasswordRequest,
	resetRequest,
}) => {
	const simpleValidator = useRef(
		new SimpleReactValidator({
			messages: {
				in: "Passwords need to match!",
			},
		})
	);
	const [, forceUpdate] = useState();
	const [resetPassword, setResetPasword] = useState({
		password: "",
		confirmPassword: "",
		oldPassword: "",
	});

	const handleResetBtn = async () => {
		const formValid = simpleValidator.current.allValid();
		if (!formValid) {
			simpleValidator.current.showMessages();
			return forceUpdate(1);
		}
		handleSubmitResetPasswordRequest(resetPassword);
	};

	return (
		<div className="reset-password-modal-wrapper">
			<Space direction="vertical" style={{ width: "100%" }}>
				<Text className="reset_subtext">
					Type and confirm a secure password for your account.
				</Text>

				<div className="input_container">
					<Input.Password
						addonBefore={<KeyOutlined className="key_icon" />}
						placeholder="old Password"
						size="large"
						className="reset_input mt-2"
						onChange={(e) =>
							setResetPasword({
								...resetPassword,
								oldPassword: e.target.value,
							})
						}
						onKeyDown={(e) => {
							if (e?.keyCode === 13) {
								handleResetBtn();
							}
						}}
					/>
					{simpleValidator.current.message(
						"old password",
						resetPassword.oldPassword,
						"required|min:5",
						{
							className: "error_txt",
						}
					)}
				</div>
				<div className="input_container">
					<Input.Password
						addonBefore={<KeyOutlined className="key_icon" />}
						placeholder="New Password"
						size="large"
						className="reset_input mt-2"
						onChange={(e) =>
							setResetPasword({
								...resetPassword,
								password: e.target.value,
							})
						}
						onKeyDown={(e) => {
							if (e?.keyCode === 13) {
								handleResetBtn();
							}
						}}
					/>
					{simpleValidator.current.message(
						"new password",
						resetPassword.password,
						"required|min:5",
						{
							className: "error_txt",
						}
					)}
				</div>
				<div className="input_container">
					<Input.Password
						addonBefore={<KeyOutlined className="key_icon" />}
						placeholder="Confirm Password"
						size="large"
						className="reset_input mt-1"
						onChange={(e) =>
							setResetPasword({
								...resetPassword,
								confirmPassword: e.target.value,
							})
						}
						onKeyDown={(e) => {
							if (e?.keyCode === 13) {
								handleResetBtn();
							}
						}}
					/>
					{simpleValidator.current.message(
						"Confirm password",
						resetPassword.confirmPassword,
						`required|min:5|in:${resetPassword.password}`,
						{
							className: "error_txt",
						}
					)}
				</div>
				<div
					className=""
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<DefaultButton
						text="Reset Password"
						type="primary"
						customClass="mt-3 "
						clickHandler={() => handleResetBtn()}
						style={{ flexGrow: "1" }}
						loading={resetRequest}
						disabled={resetRequest}
					/>
				</div>
			</Space>
		</div>
	);
};

export default ResetPassword;
