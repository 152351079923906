import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../utils/axios-utils";

// ----------Batches calls----------

const fetchBatchesList = (obj) => {
  const [
    _,
    currentPage,
    currentPageSize,
    selectedCompanies,
    userManagement,
    batchSearchName,
    statusFiltersOptions,
  ] = obj?.queryKey;

  const queryParams = {
    pageNum: currentPage,
    pageSize: currentPageSize,
    companyId:
      selectedCompanies?.company || userManagement?.companies[0]?.company,
    searchName: batchSearchName,
    statusFilter: JSON.stringify(statusFiltersOptions),
  };

  return request({
    method: "GET",
    url: `/batch/getAllForCompany`,
    params: {
      ...queryParams,
    },
  });
};

export const useFetchBatchesList = (onSuccess, onError, items) => {
  const result = useQuery(["fetchBatches", ...items], fetchBatchesList, {
    onSuccess: onSuccess,
    onError: onError,
    enabled: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: (count, err) => {
      if (count == 1 && err.response.status == 401) {
        return false;
      }
    },
  });

  return result;
};

const toggleBatchFeedback = (data) => {
  return request({
    method: "PUT",
    url: `/batch/${data?.param}`,
    data: {
      batchId: data?.batchId,
    },
  });
};

export const useToggleBatchFeedback = (onSuccess, onError, id) => {
  const result = useMutation({
    mutationFn: (obj) => toggleBatchFeedback(obj),
    onSuccess: onSuccess,
    onError: onError,
    cacheTime: 0,
  });

  return result;
};

const getStatusListForBatches = () => {
  return request({
    url: "/filters/getBatchStatusFilters",
    method: "GET",
  });
};
export const useStatusFetcherForBatches = (onSuccess, onError) => {
  return useQuery(["status"], getStatusListForBatches, {
    onSuccess: onSuccess ? onSuccess : null,
    onError: onError,
    enabled: false,
    refetchOnWindowFocus: false,
    retry: (count, err) => {
      if (count == 1 && err.response.status == 401) {
        return false;
      }
    },
    select: (data) => data.data,
  });
};

// ----------Batch calls---------

const fetchBatchList = (obj) => {
  const [
    _,
    currentPageSize,
    currentPage,
    batchId,
    statusFiltersOptions,
    searchName,
    selectedFiltersList,
  ] = obj?.queryKey;

  let queryParams = {
    batchId,
    searchName,
    pageNum: currentPage,
    pageSize: currentPageSize,
    statusFilter: JSON.stringify(statusFiltersOptions),
    filter: JSON.stringify(selectedFiltersList),
  };

  if (!searchName) {
    delete queryParams["searchName"];
  }

  if (statusFiltersOptions?.length === 0) {
    delete queryParams["statusFilter"];
  }

  if (Object.keys(selectedFiltersList)?.length === 0) {
    delete queryParams["filter"];
  }

  return request({
    method: "GET",
    url: `/files/getFilesForBatch`,
    params: {
      ...queryParams,
    },
  });
};

export const useFetchBatchList = (onSuccess, onError, items) => {
  console.log(items);
  const result = useQuery(["fetchBatchList", ...items], fetchBatchList, {
    onSuccess: onSuccess,
    onError: onError,
    enabled: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  return result;
};

const fetchStatsInfoForBatch = (data) => {
  return request({
    method: "GET",
    url: `/batch/batchInfo`,
    params: {
      batchId: data?.queryKey[1] || "",
    },
  });
};

const GetBatchStatsInfo = async (batchId) => {
  if (!batchId) {
    return null;
  }
  const { data } = await request({
    method: "GET",
    url: `/batch/batchInfo/?batchId=${batchId}`,
  });
  return data?.batchInfo;
};

export const useGetBatchInfo = (batchId) =>
  useQuery(["fetchStatsInfo", batchId], () => GetBatchStatsInfo(batchId), {
    refetchOnWindowFocus: true,
    keepPreviousData: false,
    cacheTime: 0,
    retry: (count, err) => {
      if (count == 1 && err.response.status == 401) {
        return false;
      }
    },
  });

export const useFetchBatchInfo = (onSuccess, onError, id) => {
  const result = useQuery(["fetchStatsInfo", id], fetchStatsInfoForBatch, {
    onSuccess: onSuccess,
    onError: onError,
    enabled: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  return result;
};

const fetchFiltersForBatch = (data) => {
  return request({
    method: "GET",
    url: `/filters/getFiltersForBatch`,
    params: {
      batchId: data?.queryKey[1] || "",
    },
  });
};

export const useFetchFilters = (onSuccess, onError, id) => {
  const result = useQuery(["fetchFilters", id], fetchFiltersForBatch, {
    onSuccess: onSuccess,
    onError: onError,
    enabled: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: (count, err) => {
      if (count == 1 && err.response.status == 401) {
        return false;
      }
    },
  });

  return result;
};

const enableFeedbackPropagation = (batchId, userEmail) => {};

// ----------Skus calls----------

const fetchSkusList = (obj) => {
  const [
    _,
    currentPageSize,
    currentPage,
    searchName,
    selectedCompanies,
    userManagement,
    statusFiltersOptions,
  ] = obj?.queryKey;
  let queryParams = {
    searchKeys: JSON.stringify(searchName),
    pageNum: currentPage,
    pageSize: currentPageSize,
    statusFilter: JSON.stringify(statusFiltersOptions),
    companyId:
      selectedCompanies?.company || userManagement?.companies[0]?.company,
  };
  // if (searchName.length > 0) {
  //   queryParams["searchKeys"] = JSON.stringify(searchName);
  // }
  if (statusFiltersOptions?.length === 0) {
    delete queryParams["statusFilter"];
  }

  return request({
    method: "GET",
    url: `/files/searchFiles`,
    params: {
      ...queryParams,
    },
  });
};

export const useFetchSkusList = (onSuccess, onError, items) => {
  const result = useQuery(["fetchSkusList", ...items], fetchSkusList, {
    onSuccess: onSuccess,
    onError: onError,
    enabled: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: (count, err) => {
      if (count == 1 && err.response.status == 401) {
        return false;
      }
    },
  });

  return result;
};

// ----------Users calls----------

const fetchUsersInfo = (data) => {
  return request({
    method: "GET",
    url: `/users/listForCompany`,
    params: {
      companyId: data?.queryKey[1] || "",
    },
  });
};

export const useFetchUsersInfo = (onSuccess, onError, id) => {
  const result = useQuery(["fetchUsersInfo", id], fetchUsersInfo, {
    onSuccess: onSuccess,
    onError: onError,
    enabled: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: (count, err) => {
      if (count == 1 && err.response.status == 401) {
        return false;
      }
    },
  });

  return result;
};

const inviteNewUser = (data) => {
  return request({
    method: "POST",
    url: `/users/inviteClientUser`,
    data,
  });
};

export const useInviteNewUser = (onSuccess, onError, id) => {
  const result = useMutation({
    mutationFn: (obj) => inviteNewUser(obj),
    onSuccess: onSuccess,
    onError: onError,
    cacheTime: 0,
  });

  return result;
};
const updateCSM = (data) => {
  return request({
    method: "POST",
    url: `/company/updateCSM`,
    data,
  });
};

export const useUpdateCSM = (onSuccess, onError, id) => {
  const result = useMutation({
    mutationFn: (obj) => updateCSM(obj),
    onSuccess: onSuccess,
    onError: onError,
    cacheTime: 0,
  });

  return result;
};
