import { memo, useCallback, useEffect, useState } from "react";
import "./FilesFilterRow.scss";
import { Input, Radio, Select, Slider, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { pushBatchDetails } from "../../../ReduxComponents/Reducers/BatchReducer";
import { debounce } from "lodash";
import { pushSkusDetails } from "../../../ReduxComponents/Reducers/SkusReducer";

const SelectSize = () => {
  const {
    batchReducer: { selectedWidth, selectedHeight },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("S");

  const selectOptions = ["S", "M", "L"];

  const selectReverseOptions = {
    small: "S",
    medium: "M",
    large: "L",
  };

  useEffect(() => {
    let selectedT = JSON.parse(localStorage?.getItem("selectedHW"));
    if (!!selectedT) {
      if (selectedT === "small") {
        dispatch(
          pushBatchDetails({
            selectedHeight: 342,
            selectedWidth: 217,
          })
        );
        dispatch(
          pushSkusDetails({
            selectedHeight: 342,
            selectedWidth: 217,
          })
        );
      }
      if (selectedT === "medium") {
        dispatch(
          pushBatchDetails({
            selectedHeight: 402,
            selectedWidth: 257,
          })
        );
        dispatch(
          pushSkusDetails({
            selectedHeight: 402,
            selectedWidth: 257,
          })
        );
      }
      if (selectedT === "large") {
        dispatch(
          pushBatchDetails({
            selectedHeight: 462,
            selectedWidth: 297,
          })
        );
        dispatch(
          pushSkusDetails({
            selectedHeight: 462,
            selectedWidth: 297,
          })
        );
      }
      setSelectedType(selectReverseOptions[selectedT]);
    } else {
      dispatch(
        pushBatchDetails({
          selectedHeight: 342,
          selectedWidth: 217,
        })
      );
      dispatch(
        pushSkusDetails({
          selectedHeight: 342,
          selectedWidth: 217,
        })
      );
      localStorage.setItem("selectedHW", JSON.stringify("small"));
    }
  }, []);

  const handleSizeChange = (e) => {
    let value = e?.target?.value;
    setSelectedType(value);

    if (value === "S") {
      dispatch(
        pushBatchDetails({
          selectedHeight: 342,
          selectedWidth: 217,
        })
      );
      dispatch(
        pushSkusDetails({
          selectedHeight: 342,
          selectedWidth: 217,
        })
      );
      localStorage.setItem("selectedHW", JSON.stringify("small"));
    }
    if (value === "M") {
      dispatch(
        pushBatchDetails({
          selectedHeight: 402,
          selectedWidth: 257,
        })
      );
      dispatch(
        pushSkusDetails({
          selectedHeight: 402,
          selectedWidth: 257,
        })
      );
      localStorage.setItem("selectedHW", JSON.stringify("medium"));
    }
    if (value === "L") {
      dispatch(
        pushBatchDetails({
          selectedHeight: 462,
          selectedWidth: 297,
        })
      );
      dispatch(
        pushSkusDetails({
          selectedHeight: 462,
          selectedWidth: 297,
        })
      );

      localStorage.setItem("selectedHW", JSON.stringify("large"));
    }
  };

  return (
    <>
      <Typography.Text className="file-size-select">
        <Radio.Group
          value={selectedType}
          onChange={(e) => {
            handleSizeChange(e);
          }}
          size="small"
          className="file-size-input"
          buttonStyle="solid"
        >
          {selectOptions?.map((item) => (
            <Radio.Button value={item}>{item}</Radio.Button>
          ))}
        </Radio.Group>
      </Typography.Text>
    </>
  );
};

export default memo(SelectSize);
