import React from "react";
import { Drawer } from "antd";
import "./DrawerStyle.scss";

const DynamicDrawer = (props) => {
	return (
		<Drawer
			open={props.open}
			closable={props.closable ? props.closable : false}
			onCancel={props.close}
			onClose={props?.close}
			footer={null}
			width={props.width}
			title={props.title}
			bodyStyle={{
				height: props.height,
				overflow: props.overflow ?? "scroll",
				...props.style,
			}}
			className={props.customClass ?? "dynamicDrawer"}
			extra={props.extra}
			maskClosable={props.maskClosable}
			placement={props?.placement}
			{...props}
		>
			{props.children && props.children}
		</Drawer>
	);
};

export default DynamicDrawer;
