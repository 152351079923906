import { createSlice, current } from "@reduxjs/toolkit";
import states from "../states";

const downloadReducer = createSlice({
  name: "downloadReducer",
  initialState: {
    ...states.downloadDetails,
  },
  reducers: {
    updateDownloadDetails: (state, action) => {
      let tempState = { ...current(state) };
      let returnableState = {
        ...tempState,
        ...action?.payload,
      };
      return { ...returnableState };
    },
    clearDownloadDetails: (state) => {
      return { isDownloadOpen: false, toBeDownloaded: [] };
    },
  },
});

export const { updateDownloadDetails, clearDownloadDetails } =
  downloadReducer.actions;
export default downloadReducer;
