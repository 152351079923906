import {
  DeleteFilled,
  EditOutlined,
  EditTwoTone,
  EyeInvisibleTwoTone,
  EyeOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Card, Space, Tooltip, Typography } from "antd";
import React from "react";
import "./Cards.scss";
import { getFullDate } from "../../../utils/date.util";
const { Text } = Typography;
const CommentCard = ({
  data,
  index,
  deleteComment,
  isActionable,
  handleToggle,
  handleEdit,
}) => {
  function getTimestamp(timestamp) {
    const createdOn = new Date(timestamp);
    const today = new Date();
    if (createdOn.getMonth() === today.getMonth()) {
      const daysAgo = createdOn.getDate();
      if (today.getDate() - daysAgo === 0) {
        if (createdOn.getHours() === today.getHours()) {
          if (createdOn.getMinutes() === today.getMinutes()) {
            return `${today.getSeconds() - createdOn.getSeconds()} secs ago`;
          } else {
            return `${today.getMinutes() - createdOn.getMinutes()} mins ago`;
          }
        } else {
          return `${today.getHours() - createdOn.getHours()} hours ago`;
        }
      }
      // else if (today.getDate() - daysAgo > 1) {
      // 	return getFullDate(createdOn);
      // }
      else {
        return `${today.getDate() - daysAgo} days ago`;
      }
    } else {
      return getFullDate(createdOn);
    }
  }
  return (
    <Card
      className="comment_cards"
      // title={`Comment ${index + 1}`}
      size="small"
      // extra={<DeleteFilled onClick={() => deleteComment(data?.id)} />}
    >
      {isActionable && (
        <DeleteFilled
          className="delete_icon"
          onClick={() => deleteComment(data?.id)}
        />
      )}

      <Space direction="vertical" className="w-100">
        <Space.Compact>
          <Text
            strong
            style={{ whiteSpace: "nowrap" }}
            align="start"
            block={true}
          >
            Reason:
          </Text>
          <Text>{data?.rejectionReasonMain}</Text>
        </Space.Compact>
        <Space.Compact>
          <Text
            strong
            style={{ whiteSpace: "nowrap" }}
            align="start"
            block={true}
          >
            Specific:
          </Text>
          <Text>{data?.specificReason}</Text>
        </Space.Compact>
        <Space.Compact>
          <Text
            strong
            style={{ whiteSpace: "nowrap" }}
            align="start"
            block={true}
          >
            Comment:
          </Text>
          <Text>{data?.comment ?? data?.error.split(",")[2]}</Text>
        </Space.Compact>
        <hr />
        <div className="flex-space_between">
          {data.hasOwnProperty("toggleOn") && !data.toggleOn ? (
            <EyeInvisibleTwoTone onClick={() => handleToggle(data._id)} />
          ) : (
            <EyeTwoTone onClick={() => handleToggle(data._id)} />
          )}

          {isActionable && (
            <EditTwoTone className="ml-4" onClick={() => handleEdit(data)} />
          )}
          <Space direction="vertical" className="time_box w-100" align="end">
            <Tooltip title={data?.userActionInfo?.userEmail}>
              <Text italic>{data?.userActionInfo?.userName}</Text>
            </Tooltip>

            <Tooltip title={getFullDate(data?.userActionInfo?.at)}>
              <Text italic style={{ textAlign: "end" }}>
                {getTimestamp(data?.userActionInfo?.at)}
              </Text>
            </Tooltip>
          </Space>
        </div>
      </Space>
    </Card>
  );
};

export default CommentCard;
