import { memo, useState } from "react";
import "./Profile.scss";
import { Button, Tag, message } from "antd";
import DynamicModal from "../../commonComponents/DynamicModal/DynamicModal";
import ResetPassword from "./ResetPassword";
import { useResetPassword } from "../../Api";
import useLogout from "../../hooks/useLogout";
import useFourNotOne from "../../hooks/useFourNotOne";

const ProfileFiltersRow = () => {
	const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
	const [resetRequest, setResetRequest] = useState(false);
	const { logout } = useLogout();
	const { fourNotOne } = useFourNotOne();

	const onResetPasswordSuccessUser = (resp) => {
		if (resp?.status === 200) {
			setResetRequest(false);
			message.success("Your password has been reset. Please login again");
			setTimeout(() => {
				logout();
			}, 1000);
		}
	};

	const onResetPasswordErrorUser = (err) => {
		let error = err?.response?.data?.error;
		fourNotOne(err);
		if (error === "Unauthorized.") {
			error = "Please check the old password or try again!";
		}

		message.error(error || "Please check for the old password!");
		setResetRequest(false);
	};

	const { mutate: resetPassword } = useResetPassword(
		onResetPasswordSuccessUser,
		onResetPasswordErrorUser
	);

	const handleSubmitResetPasswordRequest = (obj) => {
		setResetRequest(true);
		resetPassword({
			oldPassword: obj?.oldPassword,
			newPassword: obj?.password,
		});
	};

	const handleCancelResetPasswordRequest = () => {
		setResetRequest(false);
		setOpenResetPasswordModal(false);
	};

	return (
		<div className="filters-row-wrapper">
			<Button
				type="text"
				className="reset-password-button"
				onClick={() => setOpenResetPasswordModal(true)}
			>
				<Tag color="red" className="reset-password-button-tag">
					Reset Password
				</Tag>
			</Button>
			{openResetPasswordModal && (
				<DynamicModal
					open={openResetPasswordModal}
					close={handleCancelResetPasswordRequest}
					closable={true}
					component={
						<ResetPassword
							handleSubmitResetPasswordRequest={
								handleSubmitResetPasswordRequest
							}
							handleCancelResetPasswordRequest={
								handleCancelResetPasswordRequest
							}
							resetRequest={resetRequest}
						/>
					}
					title="Reset Password"
					className=""
				/>
			)}
		</div>
	);
};

export default memo(ProfileFiltersRow);
