import { memo, useCallback, useEffect, useState } from "react";
import "../FiltersRow/FiltersRow.scss";
import { Typography, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { pushBatchesDetails } from "../../../ReduxComponents/Reducers/BatchesReducer";

const NameFilter = () => {
	const {
		batchesReducer: { batchSearchName },
	} = useSelector((state) => state);
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState("");

	const updateFilter = useCallback(
		debounce(() => {
			dispatch(
				pushBatchesDetails({
					batchSearchName: searchText,
					currentPage: 1,
				})
			);
		}, 500),
		[searchText]
	);

	useEffect(() => {
		setSearchText(batchSearchName);
	}, [batchSearchName]);

	const handleNameChange = (e) => {
		setSearchText(e);
	};

	useEffect(() => {
		if (batchSearchName !== searchText) {
			updateFilter();
		}

		return () => updateFilter.cancel();
	}, [updateFilter, searchText]);

	return (
		<>
			<Typography.Text className="file-name-search">
				<Input.Search
					value={searchText}
					onChange={(e) => {
						handleNameChange(e.target.value);
					}}
					className="name-filter"
					placeholder="Batch name"
					allowClear
					autoFocus="off"
				/>
			</Typography.Text>
		</>
	);
};

export default memo(NameFilter);
