import { memo } from "react";
import "./SkusList.scss";
import { Divider, Pagination, Result } from "antd";
import SkusListView from "./SkusListView";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import { pushSkusDetails } from "../../../ReduxComponents/Reducers/SkusReducer";

const SkusList = () => {
  const {
    skusReducer: {
      skusList,
      totalCount,
      currentPage,
      currentPageSize,
      searchName,
      scrollToIndex,
      selectedWidth,
      selectedHeight,
      groupedData,
    },
    userReducer: { resetPasswordFlag },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const showTotal = (total) => `Total files:  ${total} `;
  const onShowSizeChange = (current, pageSize) => {
    if (pageSize !== currentPageSize) {
      dispatch(
        pushSkusDetails({
          currentPage: 1,
          currentPageSize: Number.parseInt(pageSize),
        })
      );
    } else if (current !== currentPage && current >= 1) {
      dispatch(
        pushSkusDetails({
          currentPage: Number.parseInt(current),
        })
      );
    }
  };

  return (
    <div className="skus-list-wrapper">
      {searchName?.length === 0 ? (
        <Result
          title="Nothing here!"
          icon={<InfoCircleOutlined />}
          className=""
          subTitle="Please Search to get the Skus!"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : (
        <>
          <div className="skus-tabular-data-div">
            <SkusListView
              groupedData={groupedData}
              skusListData={skusList}
              scrollToIndex={scrollToIndex}
              selectedWidth={selectedWidth}
              selectedHeight={selectedHeight}
            />
          </div>
          <Divider style={{ margin: 0, padding: 0 }} />
          <div className="pagination-div">
            <Pagination
              size="small"
              total={totalCount}
              showSizeChanger
              showTotal={showTotal}
              defaultCurrent={1}
              onChange={onShowSizeChange}
              responsive={true}
              current={currentPage}
              pageSize={currentPageSize}
              pageSizeOptions={[50, 100, 150, 200]}
              disabled={resetPasswordFlag}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(SkusList);
