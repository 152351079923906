import { Button, Input, Space, Tag } from "antd";
import { memo, useState } from "react";
import "./Users.scss";
import { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";

const AddUserByInvite = ({
	handleInviteUser,
	cancelInvitingUser,
	isAdding,
}) => {
	const [userName, setUserName] = useState("");
	const [userEmail, setUserEmail] = useState("");

	const simpleValidator = useRef(new SimpleReactValidator());
	const [, forceUpdate] = useState();

	const handleInputChange = (e, updater) => {
		updater(e?.target?.value);
	};

	const handleSendInvite = () => {
		const formValid = simpleValidator.current.allValid();
		if (!formValid) {
			simpleValidator.current.showMessages();
			return forceUpdate(1);
		}
		handleInviteUser(userName, userEmail);
	};

	return (
		<div className="invite-user-model">
			<Space direction="vertical" style={{ width: "100%" }} size={40}>
				<Space direction="vertical" style={{ width: "100%" }}>
					<Input
						className="invited-userName"
						value={userName}
						onChange={(e) => handleInputChange(e, setUserName)}
						placeholder="User Name"
						onKeyDown={(e) => {
							if (e?.keyCode === 13) {
								handleSendInvite();
							}
						}}
					/>
					{simpleValidator.current.message(
						"userName",
						userName,
						"required|min:1",
						{
							className: "error_txt",
						}
					)}
					<Input
						className="invited-userEmail"
						value={userEmail}
						onChange={(e) => handleInputChange(e, setUserEmail)}
						placeholder="User Email"
						onKeyDown={(e) => {
							if (e?.keyCode === 13) {
								handleSendInvite();
							}
						}}
					/>
					{simpleValidator.current.message(
						"userEmail",
						userEmail,
						"required|email",
						{
							className: "error_txt",
						}
					)}
				</Space>
				<Space
					direction="horizontal"
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "flex-end",
					}}
					size={5}
				>
					<Button size="small" onClick={cancelInvitingUser}>
						Cancel
					</Button>
					<Button
						type="primary"
						size="small"
						onClick={() => handleSendInvite()}
						loading={isAdding}
					>
						Save
					</Button>
				</Space>
			</Space>
		</div>
	);
};

export default memo(AddUserByInvite);
