import { Collection, Column, Table } from "react-virtualized";
import "./BatchesList.scss";
import AutoSizer from "react-virtualized-auto-sizer";
import "react-virtualized/styles.css";
import { Empty } from "antd";
import { useNavigate } from "react-router";
import FolderThumbnail from "./FolderThumbnail";
import { useEffect, useRef, useState } from "react";

const ListView = ({ batchesListData, selectedBatch, setSelectedBatch }) => {
  const navigate = useNavigate();
  const [NumberofBatchesCells, setNumberofBatchesCells] = useState(8);
  const batchesRef = useRef();

  useEffect(() => {
    function resizeArea() {
      setNumberofBatchesCells(Math.floor(window?.innerWidth / 230));
      batchesRef?.current?.recomputeCellSizesAndPositions();
    }
    window.addEventListener("resize", resizeArea);

    return () => {
      window.removeEventListener("resize", resizeArea);
    };
  }, []);

  useEffect(() => {
    setNumberofBatchesCells(Math.floor(window?.innerWidth / 230));
  }, []);

  const CellRenderer = ({ index, style: style2, key }) => {
    let data = batchesListData[index];

    return (
      <div className="single-thumbnail-folder" key={key}>
        <FolderThumbnail
          data={data}
          key={key}
          isSelected={selectedBatch == data?._id}
          setSelectedBatch={setSelectedBatch}
        />
      </div>
    );
  };

  const cellSizeAndPositionGetter = ({ index }) => {
    const columnCount = NumberofBatchesCells; // Number of columns in the grid
    const rowCount = 4; // Number of rows in the grid
    const columnPadding = 1; // Padding between columns
    const rowPadding = 20; // Padding between rows

    const width = 200;
    const height = 200;

    const columnIndex = index % columnCount;
    const rowIndex = Math.floor(index / columnCount);

    const x = columnIndex * (width + columnPadding);
    const y = rowIndex * (height + rowPadding);

    return {
      height,
      width,
      x,
      y,
    };
  };

  const HandleActionClick = (data) => {
    navigate(`/batches/${data}`);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 250px)",
        display: "flex",
        flexWrap: "wrap",
        overflow: "auto",
        alignContent: "flex-start",
      }}
    >
      {batchesListData?.map((_, index) => {
        return <CellRenderer index={index} key={`batch${index}`} />;
      })}
      {batchesListData?.length == 0 && (
        <Empty
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "Center",
            alignItems: "center",
          }}
        />
      )}
    </div>
    // <div className="collection-view-container">
    //   <AutoSizer>
    //     {({ height, width }) => (
    //       <Collection
    //         width={width}
    //         height={height}
    //         cellCount={batchesListData.length}
    //         CellRenderer={CellRenderer}
    //         cellSizeAndPositionGetter={cellSizeAndPositionGetter}
    //         className={"collection-class"}
    //         verticalOverscanSize={25}
    //         ref={batchesRef}
    //         noContentRenderer={() => (
    //           <Empty
    //             style={{
    //               width: "100%",
    //               height: "100%",
    //               display: "flex",
    //               justifyContent: "Center",
    //               alignItems: "center",
    //             }}
    //           />
    //         )}
    //       />
    //     )}
    //   </AutoSizer>
    // </div>
  );
};

export default ListView;
