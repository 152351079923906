import { memo } from "react";
import "./CanvasActions.scss";
import { Button, Select, Tag } from "antd";
import GridComponent from "../GridComponent/GridComponent";
import { useDispatch, useSelector } from "react-redux";
import { updateFileDetails } from "../../../ReduxComponents/Reducers/FileReducer";

const CanvasActions = ({
  gridData,
  updateGrid,
  setFitToView,
  side,
  fitToView,
}) => {
  const {
    fileReducer: { versions, activeFile, selectedVersion, file },
  } = useSelector((state) => state);
  const dispatch = useDispatch()

  const handleVersion = (id)=>{
	let findVersion = versions.find(elm=> elm?._id == id)
		dispatch(updateFileDetails({activeFile:{...findVersion, index:0}, selectedVersion:findVersion?._id}))

  }
  return (
    <div className="canvasActions-root">
      <div className="action">
        <GridComponent gridData={gridData} updateGrid={updateGrid} />
      </div>
      <div className="action">
        <Button type="text" onClick={() => setFitToView(side)}>
          {fitToView ? "FULL SIZE" : "FIT TO VIEW"}
        </Button>
      </div>
      {side == 'left' && activeFile?.index == 0 && <div className="action version_dropDown">
        <Select options={versions?.map((elm, index)=>{
			return {
				label:`v${elm?.version}`,
				value:elm?._id
			}
		})}
		value={selectedVersion}
		onChange={(d)=> handleVersion(d)}
		size="small"
		style={{width:100}}
		/>
      </div>}

      {side == 'right' && <Tag className="ml-1" bordered={false}>v{file.latestVersion}</Tag>}
    </div>
  );
};

export default memo(CanvasActions);
