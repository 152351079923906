import { memo, useEffect } from "react";
import "./Skus.scss";
import Header from "../../commonComponents/BatchesHeader/header";
import MultipleSearch from "./MultipleSearch";
import queryString from "query-string";
import SkusList from "./SkusList/SkusList";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useSpinner from "../../hooks/useSpinner";
import { pushSkusDetails } from "../../ReduxComponents/Reducers/SkusReducer";
import { message } from "antd";
import spinnerMessages from "../../utils/spinner-messages";
import DynamicModal from "../../commonComponents/DynamicModal/DynamicModal";
import FilePreview from "../Batch/FilePreview/FilePreview";
import { useFetchSkusList } from "../../Api/BatchesApi";
import states from "../../ReduxComponents/states";
import useFourNotOne from "../../hooks/useFourNotOne";
import { useStatusFetcher } from "../../Api";
import useLogout from "../../hooks/useLogout";

const Skus = () => {
  const {
    skusReducer: {
      currentPage,
      currentPageSize,
      searchName,
      skusList,
      statusFiltersOptions,
      selectedFiltersList,
    },
    userReducer: { selectedCompanies, userManagement },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const { dispatchPullItem, dispatchPushItem } = useSpinner();
  const { fourNotOne } = useFourNotOne();
  const { logout } = useLogout();

  const onSuccessSkusApi = async (data) => {
    const finalData = await groupHandler(data?.data?.files, "identity");
    let tempArr = [];
    if (!!finalData) {
      tempArr = Object.keys(finalData).map((n, index) => {
        return {
          groupNo: `Group ${index + 1}`,
          data: finalData[n],
        };
      });
    }
    dispatch(
      pushSkusDetails({
        totalCount: data?.data?.total,
        skusList: [...data?.data?.files],
        groupedData: [...tempArr],
      })
    );
    dispatchPullItem("loadingskusList");
  };

  const onErrorSkusApi = (error) => {
    console.log(error);
    fourNotOne(error);
    message.error(error?.response?.data?.message || "Oops! Please try again");
    dispatchPullItem("loadingskusList");
  };

  function groupHandler(array, key) {
    function sortObjectKeys(obj) {
      if (typeof obj !== "object" || obj === null) {
        return obj;
      }

      if (Array.isArray(obj)) {
        return obj.map(sortObjectKeys);
      }

      return Object.keys(obj)
        .sort()
        .reduce((sortedObj, k) => {
          sortedObj[k] = sortObjectKeys(obj[k]);
          return sortedObj;
        }, {});
    }

    return array.reduce((result, obj) => {
      const sortedObj = sortObjectKeys(obj[key]);
      const keyValue = JSON.stringify(sortedObj);
      (result[keyValue] = result[keyValue] || []).push(obj);
      return result;
    }, {});
  }

  const { refetch: fetchSkus, isLoading: isFetchingSkusDetails } =
    useFetchSkusList(onSuccessSkusApi, onErrorSkusApi, [
      currentPageSize,
      currentPage,
      searchName,
      selectedCompanies,
      userManagement,
      statusFiltersOptions,
    ]);
  const { refetch: getDropDownStatus, isError } = useStatusFetcher(
    onStatusGet,
    onStatusFail
  );

  function onStatusGet(data) {
    dispatch(pushSkusDetails({ statusFilters: data?.filters }));
  }
  function onStatusFail(err) {
    console.log(err);
  }

  useEffect(() => {
    if (searchName.length > 0) {
      dispatchPushItem({
        loadingskusList: { ...spinnerMessages?.loadingskusList },
      });
      fetchSkus();
    }
  }, [currentPageSize, currentPage, searchName, statusFiltersOptions?.length]);

  useEffect(() => {
    getDropDownStatus();
    try {
      let resolutionFlag = localStorage.getItem("showHires");
      let groupOption = JSON.parse(localStorage.getItem("groupBy"));
      let resolutionObj = JSON.parse(resolutionFlag);
      if (
        resolutionFlag &&
        resolutionObj?.sku &&
        !!groupOption &&
        groupOption?.sku &&
        groupOption?.sku != "null"
      ) {
        dispatch(
          pushSkusDetails({
            showHires: resolutionObj.sku,
            groupBy: groupOption?.sku,
          })
        );
      } else if (resolutionFlag && resolutionObj?.sku) {
        dispatch(pushSkusDetails({ showHires: resolutionObj?.sku }));
      } else if (
        !!groupOption &&
        groupOption?.sku &&
        groupOption?.sku != "null"
      ) {
        dispatch(pushSkusDetails({ groupBy: groupOption?.sku }));
      }
    } catch (err) {
      logout();
    }

    return () =>
      dispatch(
        pushSkusDetails({
          ...states?.skusDetail,
        })
      );
  }, []);

  const componentsRenderer = () => {
    const queryParams = queryString.parse(location.search);

    if (!!queryParams?.id) {
      return (
        <DynamicModal
          open={!!queryParams?.id}
          width={"99.4vw"}
          component={<FilePreview />}
          height={"100vh"}
          customClass={"previewModal"}
        />
      );
    } else {
      return (
        <>
          <Header title="Skus" />
          <SkusList />
        </>
      );
    }
  };

  return <div className="batch-wrapper">{componentsRenderer()}</div>;
};

export default memo(Skus);
