import React from 'react'
import DefaultButton from '../../commonComponents/buttons/button'
import { Space } from 'antd'

const ConfirmModal = ({handleAcceptRejectButton,handleIgnore}) => {
  return (
          <div className="text-center">
            <h2>Looks like you have unsaved changes.</h2>
            <h4 className="mt-3">What do you want to do about it?</h4>
            <Space className="mt-1">
              <DefaultButton text="Reject" type="primary" 
              clickHandler={()=>handleAcceptRejectButton("reject")}
               />
              <DefaultButton
                text="Roll Back"
                type="primary"
                clickHandler={() => handleIgnore()}
              />
            </Space>
          </div>
      
  )
}

export default ConfirmModal