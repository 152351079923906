import { useDispatch } from "react-redux";
import useLogout from "./useLogout";
import useSpinner from "./useSpinner";

const useFourNotOne = () => {
  const { logout } = useLogout();
  const { dispatchPullItem, dispatchPushItem, disptachClearAll } = useSpinner();
  const dispatch = useDispatch();
  const fourNotOne = async (err) => {
    console.log(err, "hii");
    if (err?.response?.status === 401) {
      disptachClearAll();
      logout();
    }
  };

  return { fourNotOne };
};

export default useFourNotOne;
