import { Table } from "antd";
import React from "react";
import "./style.scss";

const KeyboardModal = () => {
  const columns = [
    {
      title: "Keyboard Keys",
      dataIndex: "keys",
      key: "keys",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const data = [
    {
      key: "1",
      keys: "Z",
      action: "Zoom In",
    },
    {
      key: "2",
      keys: "shift+Z",
      action: "Zoom Out",
    },
    {
      key: "3",
      keys: "F",
      action: "Fit to view",
    },
    {
      key: "4",
      keys: "O",
      action: "Original(100%) Size",
    },

    {
      key: "10",
      keys: "Y",
      action: "Accept Current Image",
    },
    {
      key: "11",
      keys: "N",
      action: "Reject Current Image",
    },
    {
      key: "12",
      keys: "Right Arrow Key",
      action: "Go to Next Image",
    },
    {
      key: "13",
      keys: "Right Arrow Key",
      action: "Got to Previous Image",
    },
  ];
  return (
    <Table
      className="shortcut_table"
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
    />
  );
};

export default KeyboardModal;
