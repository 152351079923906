import "./BatchesList.scss";
import folderSvg from "../../../assets/images/folder.png";
import { Card, Tooltip, Typography, Button } from "antd";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import useSpinner from "../../../hooks/useSpinner";
import spinnerMessages from "../../../utils/spinner-messages";
import {
	ExclamationOutlined,
	CheckOutlined,
	CloseOutlined,
} from "@ant-design/icons";
import { colorScheme } from "../../../utils/extra-utils";

const FolderThumbnail = ({ data, key, isSelected, setSelectedBatch }) => {
	const navigate = useNavigate();
	const { batchesReducer } = useSelector((state) => state);
	const { dispatchPushItem } = useSpinner();

	const handleCardClick = (data) => {
		dispatchPushItem({
			loadBatch: { ...spinnerMessages.loadBatch },
		});
		navigate(`/batches/${data?._id}`, {
			// state: { batchesReducer },
		});
	};

	const getColorSign = (status) => {
		switch (status) {
			case "pending":
				return { color: "#8CABFF", sign: "exclamation" };
			case "completed":
				return { color: "#8CABFF", sign: "exclamation" };
			default:
				return { color: "#8CABFF", sign: "exclamation" };
		}
	};

	const getSignFromSwitch = (sign) => {
		switch (sign) {
			case "exclamation":
				return (
					<ExclamationOutlined
						style={{
							// width: "100%",
							// height: "100%",
							margin: 0,
							color: "white",
							fontWeight: "bolder",
							verticalAlign: "center",
						}}
						className="flex-center"
					/>
				);
			case "check":
				return (
					<CheckOutlined
						style={{
							// width: "100%",
							// height: "100%",
							margin: 0,
							color: "white",
							fontWeight: "bolder",
							verticalAlign: "center",
						}}
						className="flex-center"
					/>
				);
			default:
				return <></>;
		}
	};

	const getSign = () => {
		const dataStatus = data?.status;
		const { color, sign } = colorScheme[dataStatus];

		return (
			<span
				style={{
					width: "90%",
					height: "90%",
					// backgroundColor: color,
					// display: "block",
					// borderRadius: "50%",
				}}
				className="flex-center"
			>
				<Tooltip title={data?.stage} placement="topLeft">
					{getSignFromSwitch(sign)}
				</Tooltip>
			</span>
		);
	};

	return (
		<>
			<div
				style={{
					width: "200px",
					height: "200px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					border: !!isSelected ? "1px solid black" : "1px solid #ddd",
					borderRadius: "15px",
					margin: "10px",
					position: "relative",
				}}
				className="batch-card"
				onClick={() => {
					console.log("Folder thumbnail", { data });
					setSelectedBatch(data?._id);
				}}
			>
				<img
					alt="example"
					src={folderSvg}
					style={{
						height: "60px",
						width: "100%",
						objectFit: "contain",
					}}
				/>
				<Tooltip title={data?.batchName}>
					<Typography.Text
						className="batchesName"
						style={{
							whiteSpace: "break-spaces",
							overflow: "hidden",
							textOverflow: "ellipsis",
							alignContent: "center",
							textAlign: "center",
							overflow: "hidden",
							display: "--webkit-box",
							WebkitLineClamp: 3,
							WebkitBoxOrient: "vertical",
						}}
					>
						{data?.batchName}
					</Typography.Text>
				</Tooltip>
				<Tooltip title="Creation Date">
					<Typography.Text type="secondary">
						{`${new Date(
							data?.createdAt
						).toLocaleDateString()} ${new Date(
							data?.createdAt
						).toLocaleTimeString()}`}
					</Typography.Text>
				</Tooltip>
				<Button
					type="dashed"
					onClick={() => {
						handleCardClick(data);
					}}
				>
					View Files
				</Button>
				{
					<span
						style={{
							height: "22px",
							width: "22px",
							position: "absolute",
							top: "5px",
							left: "5px",
							backgroundColor:
								// getColorSign(data.status)?.color,
								colorScheme[data?.status] &&
								colorScheme[data.status].color,
							//   display: "block",
							borderRadius: "50%",
						}}
						className="flex-center"
					>
						{getSign()}
					</span>
				}
			</div>
			{/* <Card
        hoverable
        style={{
          width: "200px",
          height: "200px",
        }}
        actions={[<Button>Open</Button>]}
      >
        <Card.Meta
          avatar={
            <img
              alt="example"
              src={folderSvg}
              style={{
                height: "50px",
                width: "100%",
                objectFit: "contain",
              }}
            />
          }
          title={`${new Date(data?.createdAt).toLocaleDateString()} ${new Date(
            data?.createdAt
          ).toLocaleTimeString()}`}
          description={
            <Tooltip title={data?.batchName}>
              <Typography.Text className="batchesName">
                {data?.batchName}
              </Typography.Text>
            </Tooltip>
          }
        />
      </Card> */}
			{/* <div
        className="folder-view-wrapper"
        key={key}
        onClick={() => {
          console.log("Folder thumbnail", { data });
          setSelectedBatch(data?._id);
        }}
      >
        <Card
          hoverable
          style={{
            width: "200px",
            height: "300px",
            border: !!isSelected ? "1px solid black" : "",
          }}
          className="folder-card-main"
        >
          <div
            style={{
              width: "100%",
              height: "60px",
            }}
          >
            <img
              alt="example"
              src={folderSvg}
              style={{
                height: "50px",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "block",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip title={data?.batchName}>
              <Typography.Text className="batchesName">
                {data?.batchName}
              </Typography.Text>
            </Tooltip>
            <Typography.Text className="batchesName">
              {`${new Date(data?.createdAt).toLocaleDateString()} ${new Date(
                data?.createdAt
              ).toLocaleTimeString()}`}
            </Typography.Text>
            <Button
              type="primary"
              onClick={() => {
                handleCardClick(data);
              }}
            >
              Open
            </Button>
          </div>
        </Card>
      </div> */}
		</>
	);
};

export default FolderThumbnail;
