import { memo } from "react";
import "./Stats.scss";
import { useSelector } from "react-redux";
import { Divider, Tag, Typography } from "antd";
import {
	CheckOutlined,
	CloseOutlined,
	ExclamationOutlined,
	FieldNumberOutlined,
	NumberOutlined,
} from "@ant-design/icons";
import { colorScheme } from "../../../utils/extra-utils";

const Stats = () => {
	const {
		batchReducer: { batchStats, totalStatsCount },
	} = useSelector((state) => state);

	const getIcon = (color, value, name, sign, status) => {
		switch (sign) {
			case "exclamation":
				return (
					<ExclamationOutlined
						className="tag-icon"
						style={{ color: "white", backgroundColor: color }}
					/>
				);

			case "check":
				return (
					<CheckOutlined
						className="tag-icon"
						style={{ color: "white", backgroundColor: color }}
					/>
				);
			case "cross":
				return (
					<CloseOutlined
						className="tag-icon"
						style={{ color: "white", backgroundColor: color }}
					/>
				);
			case "number":
				return (
					<NumberOutlined
						className="tag-icon"
						style={{ color: "white", backgroundColor: color }}
					/>
				);
			default:
				return <></>;
		}
	};

	const getTag = (color, value, name, sign, status) => {
		return (
			<Typography.Text className="single-tag">
				<Tag
					icon={getIcon(color, value, name, sign, status)}
					// color={"white"}
					style={{ color: "black", background: "white" }}
				>
					<span>
						{value}{" "}
						{name?.slice(0, 1).toUpperCase() + name.slice(1)}
					</span>
				</Tag>
			</Typography.Text>
		);
	};

	const getBatchFilesStats = () => {
		let tagsArray = [
			{
				stringStatus: "total files",
				count: totalStatsCount,
				status: 0,
				color: "magenta",
				sign: "number",
			},
		];

		let batchStatsCopy = [...batchStats];

		if (batchStats?.length) {
			tagsArray = [...tagsArray, ...batchStatsCopy];
		}
		let clScheme = { ...colorScheme };

		let tagsArrayResultant = tagsArray.map((item) => {
			let returnable = {};
			if (item?.status !== 0) {
				returnable["color"] = clScheme[item?.status]?.color;
				returnable["sign"] = clScheme[item?.status]?.sign;
			}

			returnable = { ...returnable, ...item };
			return returnable;
		});

		return (
			<>
				{tagsArrayResultant?.map((item, index) => {
					return (
						<div className="single-tag-wrapper" key={index}>
							{getTag(
								item?.color,
								item?.count,
								item?.stringStatus,
								item?.sign,
								item?.status
							)}
						</div>
					);
				})}
			</>
		);
	};

	return (
		<div className="stats-wrapper">
			<div className="state-main">
				{batchStats?.length > 0 && getBatchFilesStats()}
			</div>
		</div>
	);
};

export default memo(Stats);
