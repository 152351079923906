import { memo } from "react";
import CommonTable from "../../commonComponents/Table/table";
import { CheckCircleFilled } from "@ant-design/icons";

const UsersList = ({ usersList }) => {
	const columns = [
		{
			title: "Sr No",
			dataIndex: "name",
			key: "name",
			render: (text, _, index) => <a>{index + 1}</a>,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			align: "center",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			align: "center",
		},
		{
			title: "Designation",
			dataIndex: "designation",
			key: "designation",
			align: "center",
		},
	];

	return (
		<div className="users-list-wrapper">
			<CommonTable
				columns={columns}
				dataSource={usersList}
				pagination={false}
				bordered={true}
				rowClassName=""
			/>
		</div>
	);
};

export default memo(UsersList);
