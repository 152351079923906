import { Collection, Column, Table } from "react-virtualized";
import "./SkusList.scss";
import AutoSizer from "react-virtualized-auto-sizer";
import "react-virtualized/styles.css";
import { Empty } from "antd";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import SkusThumbnail from "./SkusThumbnail";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDownloadDetails,
  updateDownloadDetails,
} from "../../../ReduxComponents/Reducers/DownloadReducer";
import { pushSkusDetails } from "../../../ReduxComponents/Reducers/SkusReducer";
import SkuGroupView from "./SkuGroupView";

const SkusListView = ({
  skusListData,
  scrollToIndex,
  selectedWidth,
  selectedHeight,
  groupedData,
}) => {
  const navigate = useNavigate();
  const {
    downloadReducer: { isDownloadOpen, toBeDownloaded },
    skusReducer: { showHires, groupBy },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  // const [NumberofBatchCells, setNumberofBatchCells] = useState(7);
  const [NumberofBatchCells, setNumberofBatchCells] = useState(
    Math.floor(window?.innerWidth / Number(selectedWidth)) - 1 !== 0
      ? Math.floor(window?.innerWidth / Number(selectedWidth)) - 1
      : 1
  );
  const selectedWidthRef = useRef(selectedWidth);
  const selectedHeightRef = useRef(selectedHeight);
  const [dynamicHW, setDynamicHW] = useState({
    height: selectedHeight,
    width: selectedWidth,
  });
  const batchRef = useRef();
  const [imageFlag, setImageFlag] = useState(false);
  // useEffect(() => {
  // 	function resizeArea() {
  // 		setNumberofBatchCells(Math.floor(window?.innerWidth / 250));
  // 		batchRef?.current?.recomputeCellSizesAndPositions();
  // 	}
  // 	window.addEventListener("resize", resizeArea);

  // 	return () => {
  // 		window.removeEventListener("resize", resizeArea);
  // 	};
  // }, []);

  // useEffect(() => {
  // 	setNumberofBatchCells(Math.floor(window?.innerWidth / 250));
  // }, []);

  useEffect(() => {
    function resizeArea() {
      setDynamicHW({
        height: Number(selectedHeightRef.current),
        width: Number(selectedWidthRef?.current),
      });
      setNumberofBatchCells(
        Math.floor(window?.innerWidth / Number(selectedWidthRef?.current)) -
          1 !==
          0
          ? Math.floor(window?.innerWidth / Number(selectedWidthRef?.current)) -
              1
          : 1
      );

      batchRef?.current?.recomputeCellSizesAndPositions();
    }
    window.addEventListener("resize", resizeArea);
    return () => {
      window.removeEventListener("resize", resizeArea);
      dispatch(clearDownloadDetails());
    };
  }, []);

  useEffect(() => {
    setDynamicHW({
      height: Number(selectedHeight),
      width: Number(selectedWidth),
    });
    setNumberofBatchCells(
      Math.floor(window?.innerWidth / Number(selectedWidth)) - 1 !== 0
        ? Math.floor(window?.innerWidth / Number(selectedWidth)) - 1
        : 1
    );
    selectedWidthRef.current = Number(selectedWidth);
    selectedHeightRef.current = Number(selectedHeight);
    batchRef?.current?.recomputeCellSizesAndPositions();
  }, [selectedHeight]);

  useEffect(() => {
    setNumberofBatchCells(
      Math.floor(window?.innerWidth / Number(selectedWidthRef?.current)) - 1 !==
        0
        ? Math.floor(window?.innerWidth / Number(selectedWidthRef?.current)) - 1
        : 1
    );
  }, []);
  useEffect(() => {
    setImageFlag(!imageFlag);
  }, [showHires]);

  const cellRenderer = ({ index, style: style2, key }) => {
    let data = skusListData[index];

    return (
      <div
        className="single-thumbnail-folder"
        style={{
          width: dynamicHW?.width + "px",
          height: dynamicHW?.height + "px",
          ...style2,
        }}
        key={key}
      >
        <SkusThumbnail
          data={data}
          key={key}
          style={style2}
          fileIndex={index}
          dynamicHW={dynamicHW}
          isDownloadOpen={isDownloadOpen}
          handleDownloadList={handleDownloadList}
          selectedForDownload={toBeDownloaded}
          showHires={showHires}
        />
      </div>
    );
  };

  const cellSizeAndPositionGetter = ({ index }) => {
    const columnCount = NumberofBatchCells; // Number of columns in the grid
    const rowCount = 4; // Number of rows in the grid
    const columnPadding = 0; // Padding between columns
    const rowPadding = 0; // Padding between rows

    const width = dynamicHW?.width;
    const height = dynamicHW?.height - 12;

    const columnIndex = columnCount === 1 ? 0 : index % columnCount;
    const rowIndex = Math.floor(index / columnCount);

    const x = columnIndex * (width + columnPadding);
    const y = rowIndex * (height + rowPadding);
    return {
      height,
      width,
      x,
      y,
    };
    // const columnCount = NumberofBatchCells; // Number of columns in the grid
    // const rowCount = 4; // Number of rows in the grid
    // const columnPadding = 1; // Padding between columns
    // const rowPadding = 20; // Padding between rows

    // const width = 225;
    // const height = 260;

    // const columnIndex = index % columnCount;
    // const rowIndex = Math.floor(index / columnCount);

    // const x = columnIndex * (width + columnPadding);
    // const y = rowIndex * (height + rowPadding);
    // return {
    // 	height,
    // 	width,
    // 	x,
    // 	y,
    // };
  };
  const handleDownloadList = (data) => {
    let tempList = [...toBeDownloaded];
    let isInsideIndex = tempList.findIndex(
      (item) => item?._id == data?.latestImage?.imagePath?._id
    );
    if (isInsideIndex > -1) {
      tempList = tempList.filter(
        (el) => el?._id != data?.latestImage?.imagePath?._id
      );
    } else {
      tempList.push(data?.latestImage?.imagePath);
    }

    dispatch(updateDownloadDetails({ toBeDownloaded: tempList }));
  };

  return (
    <div className="collection-view-container">
      {!!groupBy ? (
        <SkuGroupView
          groupedData={groupedData}
          imageFlag={imageFlag}
          batchRef={batchRef}
          dynamicHW={dynamicHW}
          isDownloadOpen={isDownloadOpen}
          handleDownloadList={handleDownloadList}
          toBeDownloaded={toBeDownloaded}
          showHires={showHires}
          NumberofBatchCells={NumberofBatchCells}
          scrollToIndex={scrollToIndex}
        />
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <Collection
              key={imageFlag}
              width={width}
              height={height}
              cellCount={skusListData.length}
              cellRenderer={cellRenderer}
              cellSizeAndPositionGetter={cellSizeAndPositionGetter}
              className={"collection-class"}
              verticalOverscanSize={25}
              ref={batchRef}
              noContentRenderer={() => (
                <Empty
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "Center",
                    alignItems: "center",
                  }}
                />
              )}
              scrollToCell={scrollToIndex}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};

export default SkusListView;
