import React, { useEffect, useState } from "react";
import "./companyListSidebar.scss";
import { Card, Tooltip } from "antd";
// import { PlusCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../ReduxComponents/Reducers/UserReducer";
// import { useState } from "react";
// import { useEffect } from "react";
// import { SetUserManagement } from "../../ReduxComponents/actions/userAction";
import { useNavigate } from "react-router";
import { SearchOutlined } from "@ant-design/icons";
import DynamicModal from "../DynamicModal/DynamicModal";
import SearchCompanyByName from "./SearchCompanyByName";
// import { updateSelectedCompanies } from "../../ReduxComponents/Reducers/UserReducer";
// import DynamicModal from "../../commonComponents/DynamicModal/DynamicModal";
// import CompanyDetailsForm from "../forms/companyDetailsForm";
// import { addressListingAction } from "../../ReduxComponents/actions/companyActions";

const CompanyListSidebar = () => {
	const [selectedCompanyId, setSelectedCompanyId] = useState("");
	// const [openCreateWorkspace, setOpenCreateWorkspace] = useState(false);
	const {
		userReducer: { user, selectedCompanies, userManagement },
	} = useSelector((state) => state);
	const navigate = useNavigate();
	const [searchCompany, setSearchCompany] = useState(false);
	const dispatch = useDispatch();
	let userData = JSON.parse(localStorage.getItem("local_user"));

	const updateCompanySelected = (item) => {
		setSelectedCompanyId(item?._id);
		sessionStorage.setItem("selectedCompanies", JSON.stringify(item));
		dispatch(
			updateUser({
				selectedCompanies: item,
			})
		);
		navigate("/");
	};
	useEffect(() => {
		let checkLocalStorage =
			sessionStorage.getItem("selectedCompanies") &&
			JSON.parse(sessionStorage.getItem("selectedCompanies"));
		if (checkLocalStorage) {
			let findCompany = checkLocalStorage
				? checkLocalStorage
				: userData?.selectedCompanies
				? userData?.selectedCompanies
				: userData?.userManagement?.companies?.length > 0 &&
				  userData?.userManagement?.companies[0];

			if (findCompany) {
				sessionStorage.setItem(
					"selectedCompanies",
					JSON.stringify(findCompany)
				);
			}
			setSelectedCompanyId(findCompany?._id);
			// setSelectedCompanyId(selectedCompanies?._id);
		}
	}, []);

	useEffect(() => {
		if (selectedCompanies?._id) {
			setSelectedCompanyId(selectedCompanies?._id);
			sessionStorage.setItem(
				"selectedCompanies",
				JSON.stringify(selectedCompanies)
			);
		}
	}, [selectedCompanies]);

	const handleSearchCompany = () => {
		setSearchCompany(true);
	};

	return (
		<div className="company_sidebar_container">
			<Tooltip title={"Search Company"}>
				<Card
					hoverable
					key={"random_search"}
					className={"company_cards"}
					onClick={handleSearchCompany}
				>
					<SearchOutlined size={"large"} />
				</Card>
			</Tooltip>
			{userManagement?.companies?.map((item, index) => {
				return (
					<Tooltip title={item?.companyName}>
						<Card
							hoverable
							key={item?.id}
							className={
								item?._id == selectedCompanyId
									? "company_cards_selected "
									: "company_cards"
							}
							onClick={() => updateCompanySelected(item)}
						>
							{item.companyCode?.substring(0, 7)}
							{item.companyCode?.length > 7 ? "..." : ""}
						</Card>
					</Tooltip>
				);
			})}
			{searchCompany && (
				<DynamicModal
					open={searchCompany}
					close={() => setSearchCompany(false)}
					closable={true}
					component={
						<SearchCompanyByName
							handleCloseModal={() => setSearchCompany(false)}
						/>
					}
					title="Search Company"
					className=""
				/>
			)}
		</div>
	);
};

export default CompanyListSidebar;
