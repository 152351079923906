import { Select } from "antd";
import "./MultiSelect.scss";

const MultiSelect = (props) => {
	return (
		<Select
			mode="tags"
			className={props.className}
			placeholder={props.placeholder}
			onChange={props.handleChange}
			options={props.filteroptions}
			placement="bottomRight"
			allowClear={true}
			autoClearSearchValue={true}
			maxTagCount={2}
			maxTagTextLength={10}
			value={props.value}
			{...props}
		/>
	);
};

export default MultiSelect;
