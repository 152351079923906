import { createSlice, current } from "@reduxjs/toolkit";
import states from "../states";

const fileReducer = createSlice({
	name: "fileReducer",
	initialState: {
		...states.fileDetails,
	},
	reducers: {
		updateFileDetails: (state, action) => {
			let tempState = { ...current(state) };
			let returnableState = {
				...tempState,
				...action?.payload,
			};
			return { ...returnableState };
		},
	},
});

export const { updateFileDetails } = fileReducer.actions;
export default fileReducer;
