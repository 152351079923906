import { Spin } from "antd";
import { useSelector } from "react-redux";
import "./globalSpinner.scss";

const GlobalSpinner = ({ children }) => {
	const {
		spinnerReducer: { spinnerObject },
	} = useSelector((state) => state);

	const isSpinnerToShow = () => {
		if (Object.keys(spinnerObject).length === 0) {
			return false;
		}
		if (Object.keys(spinnerObject).length > 0) {
			return true;
		}
	};

	const getLatestMessage = () => {
		if (Object.keys(spinnerObject).length > 0) {
			let valuesArray = Object.keys(spinnerObject);
			let latestMessageKey = valuesArray[0];
			return spinnerObject[latestMessageKey]?.message || "";
		}

		return "";
	};

	return (
		<Spin
			spinning={isSpinnerToShow()}
			tip={
				<div
					style={{
						width: "100%",
						color: "grey",
					}}
				>
					{getLatestMessage()}
				</div>
			}
			className="spinner_background"
		>
			{children}
		</Spin>
	);
};

export default GlobalSpinner;
