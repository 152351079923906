import { memo, useEffect, useState } from "react";
import "./FilesFilterRow.scss";
import { useDispatch, useSelector } from "react-redux";
import { Select, Typography } from "antd";
import { pushBatchDetails } from "../../../ReduxComponents/Reducers/BatchReducer";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import { pushSkusDetails } from "../../../ReduxComponents/Reducers/SkusReducer";

const StatusFilters = () => {
	const location = useLocation();
	const {
		batchReducer: { statusDict, statusFiltersOptions, statusFilters },
		skusReducer: {
			statusFiltersOptions: statusFiltersOptionsSku,
			statusFilters: statusFiltersSku,
		},
	} = useSelector((state) => state);
	const dispatch = useDispatch();
	const [filtersOptions, setFiltersOptions] = useState([]);

	useEffect(() => {
		if (statusFilters?.length > 0 || statusFiltersSku?.length > 0) {
			let newArr = (
				location.pathname.includes("skus")
					? statusFiltersSku
					: statusFilters
			)?.map((elm) => {
				return { label: elm?.key, value: elm?.value };
			});

			setFiltersOptions(newArr);
		}
	}, [statusFilters, statusFiltersSku]);

	const updateFilter = debounce((value) => {
		if (location.pathname.includes("skus")) {
			dispatch(
				pushSkusDetails({
					statusFiltersOptions: [...value],
				})
			);
		} else {
			dispatch(
				pushBatchDetails({
					statusFiltersOptions: [...value],
				})
			);
		}
	}, 300);

	const handleChange = (e) => {
		updateFilter(e);
	};

	return (
		<Typography.Text className="status-filters-wrapper">
			<Select
				mode="multiple"
				className="status-filters-input"
				placeholder="Status"
				onChange={handleChange}
				options={filtersOptions}
				placement="bottomRight"
				allowClear={true}
				autoClearSearchValue={true}
				// maxTagCount={1}
				// maxTagTextLength={10}
				maxTagCount="responsive"
				filterOption={(input, option) =>
					(option?.label ?? "")
						.toLowerCase()
						.includes(input.toLowerCase())
				}
				value={
					location.pathname.includes("skus")
						? statusFiltersOptionsSku
						: statusFiltersOptions
				}
			/>
		</Typography.Text>
	);
};

export default memo(StatusFilters);
