import { Result } from "antd";
import React from "react";
import DefaultButton from "../commonComponents/buttons/button";
import { useNavigate } from "react-router-dom";

const PendingPage = () => {
	const navigate = useNavigate();
	return (
		<div>
			<Result
				status="403"
				title="PENDING APPROVAL"
				subTitle="Looks like your approval is pending. Come back when approved 😃!"
				style={{ padding: "5px" }}
				extra={
					<DefaultButton
						text="Back to Login"
						clickHandler={() => navigate("/login")}
					/>
				}
			/>
		</div>
	);
};

export default PendingPage;
