import React, { useEffect, useState } from "react";
import { Avatar, Dropdown, Menu, Typography, message } from "antd";
import "./header.scss";
import logo from "../../assets/images/logo/fx-logo.png";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../ReduxComponents/Reducers/UserReducer";
import { useDispatch, useSelector } from "react-redux";
import useLogout from "../../hooks/useLogout";
import ProfileFiltersRow from "../../Components/Self/ProfileFiltersRow";
import ResetPassword from "../../Components/Self/ResetPassword";
import DynamicModal from "../DynamicModal/DynamicModal";
import { useResetPassword } from "../../Api";
import useFourNotOne from "../../hooks/useFourNotOne";

const Header = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { logout } = useLogout();
	const [resetRequest, setResetRequest] = useState(false);
	const { fourNotOne } = useFourNotOne();
	const {
		userReducer: { user, resetPasswordFlag, userAvatar },
	} = useSelector((state) => state);

	const onResetPasswordSuccessUser = (resp) => {
		if (resp?.status === 200) {
			setResetRequest(false);
			message.success("Your password has been reset. Please login again");
			setTimeout(() => {
				logout();
			}, 1000);
		}
	};
	const onResetPasswordErrorUser = (err) => {
		let error = err?.response?.data?.error;
		fourNotOne(err);
		if (error === "Unauthorized.") {
			error = "Please check the old password or try again!";
		}

		message.error(error || "Please check for the old password!");
		setResetRequest(false);
	};

	const { mutate: resetPassword } = useResetPassword(
		onResetPasswordSuccessUser,
		onResetPasswordErrorUser
	);
	const itemHandler = ({ key }) => {
		if (key == 0) {
			// localStorage.removeItem("user");
			// localStorage.removeItem("token");
			// dispatch(updateUser({ token: "", isLoggedIn: false }));
			logout();
			// navigate("/login");
		}
		if (key == 1) {
			navigate("/profile");
		} else if (key == 2) {
			dispatch(updateUser({ resetPasswordFlag: true }));
		}
	};

	const menu = (
		<Menu
			className="profileMenu"
			onClick={(e) => itemHandler(e)}
			items={[
				{
					label: <span>Profile</span>,
					key: 1,
				},
				{
					label: <span>Reset Password</span>,
					key: 2,
				},
				{
					label: <span>Logout</span>,
					key: 0,
				},
			]}
		/>
	);
	const handleCancelResetPasswordRequest = () => {
		setResetRequest(false);
		dispatch(updateUser({ resetPasswordFlag: false }));
	};
	const handleSubmitResetPasswordRequest = (obj) => {
		setResetRequest(true);
		resetPassword({
			oldPassword: obj?.oldPassword,
			newPassword: obj?.password,
		});
	};

	return (
		<div className="header_main" id="headerBar">
			<img
				src={logo}
				className="logo_img"
				alt="LOGO"
				// onClick={() => navigate("/dashboard")}
				style={{
					cursor: "pointer",
				}}
			/>
			<div className="avatar_container">
				<Typography.Title level={5} className="mr-4">
					Welcome - {user.name}
				</Typography.Title>
				<Dropdown
					overlay={menu}
					trigger={["click"]}
					placement="bottomLeft"
					arrow
				>
					<Avatar
						size={"large"}
						src={userAvatar ? userAvatar : <UserOutlined />}
						style={{ backgroundColor: "#BFBFBF" }}
					/>
				</Dropdown>
			</div>
			{resetPasswordFlag && (
				<DynamicModal
					open={resetPasswordFlag}
					close={handleCancelResetPasswordRequest}
					closable={true}
					component={
						<ResetPassword
							handleSubmitResetPasswordRequest={
								handleSubmitResetPasswordRequest
							}
							handleCancelResetPasswordRequest={
								handleCancelResetPasswordRequest
							}
							resetRequest={resetRequest}
						/>
					}
					title="Reset Password"
					className=""
				/>
			)}
		</div>
	);
};

export default Header;
